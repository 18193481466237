import React, { useEffect, useState } from 'react'
import { Card, Button, Form } from 'react-bootstrap';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Input, Select, Modal, Typography, Cascader} from 'antd';
import axios from "../auth/Axios";

export const ShopModal = ({updateShopName}) => {
    const [showModal, setShowModal] = useState(false)
    const [shopData, setShopData] = useState({
        shop_name:'',
        shop_url:'',
        shop_type:'',
        api_key:'',
        api_secret:'',
        access_token:'',
    })
    const [menuItems, setMenuItems] = useState([])
    const [shopName, setShopName] = useState(null)
    const [uploadDisabled, setUploadDisabled] = useState(true)
    const [addDisabled, setAddDisabled] = useState(false)
    const [checkImport, setCheckImport] = useState(0)
    const [imported,setImported] = useState(false)
    const { Text } = Typography;

    useEffect(()=> {
        const fetchData = () => {
            axios.get('get-all-shops')
            .then((resp)=> {
                console.log(resp.data.shops)
                const data = resp.data.shops
                if (data.length > 0)  {
                    data.map((shop,idx)=>{
                        setMenuItems((prevState) => [
                          ...prevState,
                          { value: shop['shop_name'], label: shop['shop_name'] },
                        ]);
                    })
                }
            })
            .catch(err=> {
                console.log(err)
            })
        }


        const checkIfImported = () => {
            axios.get('check-if-imported',{
                params: {
                    shop_name: shopName
                  }
            })
            .then(res=> {
                
                if ( res.data.is_error === false) {
                    console.log(res.data)
                    setUploadDisabled(false)
                    setImported(true)
                    setCheckImport(0)
                    updateShopName(shopName)
                }

            })

            .catch (err => {
                let errorMsg = ""

                if (err.response.status === 400) {
                    errorMsg = err.response.data.msg
                }
                else{
                    errorMsg = "Some error occured. Please try again."
                }

                Modal.error({
                    title:"Error Importing.",
                    content:errorMsg,
                })
                setCheckImport(0)
                setImported(true)
                setUploadDisabled(false)
            })
        }

        if (menuItems.length === 0) {
            fetchData();
        }

        let timeoutOne = null;

        if (checkImport > 0) {
            timeoutOne = setTimeout(() => {
                checkIfImported()
            }, 3000);
        }

        if (imported === true ) {
            clearTimeout(timeoutOne)
        }

    },[checkImport])

    
    const onSelect = (shop_name) => {
        setShopName(shop_name)
        setUploadDisabled(false)
        if(shop_name === undefined){
            setShopName(null)
            setUploadDisabled(true)
        }
    }

    const modalFunc = () => {
        setShowModal(true)
    }

    const changeFn = (e) => {
        const {name,value} = e.target
        setShopData(prev =>  {
            return {
                ...prev,
                [name]:value
            }
        })
    }

    // upon clicking on add new shop button
    const addShop = () => {
        let data = {...shopData}
        if (shopData.shop_type === "WooCommerce"){
            delete data['access_token']
        }

        let is_empty = Object.values(data).some(x=> x === null || x === '')
        
        if (is_empty) {
            Modal.info({
                title:"Info",
                content:"Please provide all the required fields.",
            })
            return
        }


        setAddDisabled(true)

        axios.post('add-new-shop',shopData)
        .then(res=> {
            setAddDisabled(false)
            setMenuItems(prev => [...prev,{ value: res.data.shop_name, label: res.data.shop_name }])
            Modal.info({
                title:"Added",
                content:"A new shop has been added.",
                onOk:closeModal
            })
        })
        .catch (err => {
            setAddDisabled(false)
            Modal.info({
                title:"Error",
                content:"Some error occured. Please try again.",
            })
        })
    }

    // upon clicking on import button
    const importShop = () => {

        setUploadDisabled(true)
        setImported(false)
        axios.get('import-shop-products',{
            params: {
                shop_name: shopName
              }
        })
        .then(resp=> {
            setCheckImport(1)
        })
        .catch(err=> {
            setUploadDisabled(false)
            console.log(err)
        })
    }

    const closeModal = () => {
        setShowModal(false)
        setAddDisabled(false)
        setShopData(prev => {
            return {
                shop_name:'',
                shop_url:'',
                shop_type:'',
                api_key:'',
                api_secret:'',
                access_token:'',
            }
        })
    }

    const displayRender = (labels) => labels[labels.length - 1];

    const shopTypes = [
        {
            value: "Shopify",
            label: "Shopify",
        },
        {
            value: "WooCommerce",
            label: "WooCommerce",
            // disabled: true,
        }
    ]    


    const dropdownChangeFn = (e) => {
        let temp = ''
        if (e !== undefined){
            temp = e[0]
        }
        setShopData(prev => {
            return {
                ...prev,
                shop_type:temp
            }
        })
    }

    return (
        <div>
            <Modal title={<Text style={{fontSize:"20px"}}>Add a New Shop</Text>} visible={showModal} onCancel={() => closeModal()} footer={null} width='60vw'>

                    <Form.Group style={{ marginBottom: "2%" }}>
                        <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400, color: "#7B809A", }}>Shop Name</Form.Label> 
                        <Input placeholder="Example:- My Shopify Shop" name="shop_name" value={shopData.shop_name} onChange={changeFn} /> 

                        <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400, color: "#7B809A", marginTop:'2%' }}>Shop Url</Form.Label> 
                        <Input placeholder="Example:- myshopifyshop.com" name="shop_url" value={shopData.shop_url} onChange={changeFn} /> 

                        <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400, color: "#7B809A", marginTop:'2%' }}>Shop Type</Form.Label> 
                        <div>
                        <Cascader
                            size='large' placeholder="Shopify" expandTrigger="hover"
                            options={shopTypes} displayRender={displayRender} onChange={dropdownChangeFn}
                        />
                        </div>

                        <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400, color: "#7B809A", marginTop:'2%'  }}>API Key</Form.Label> 
                        <Input placeholder="Example:- ajdbdjksefghijkslknkjkdbi" name="api_key" value={shopData.api_key} onChange={changeFn} /> 

                        <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400, color: "#7B809A", marginTop:'2%'  }}>API Secret</Form.Label> 
                        <Input placeholder="Example:- jksbdyusgdadxahdjbhbsdhbs" name="api_secret" value={shopData.api_secret} onChange={changeFn} /> 

                        {
                            shopData.shop_type === "Shopify" && (
                                <>
                                    <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400, color: "#7B809A", marginTop:'2%' }}>Access Token ( Not required for WooCommerce )</Form.Label> 
                                    <Input placeholder="Example:- Shopify" name="access_token" value={shopData.access_token} onChange={changeFn} /> 
                                </>
                            )
                        }

                        <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback>
                    </Form.Group>


                    <Button variant="danger" type="submit"  disabled = {addDisabled}
                        onClick={addShop}
                        style={{
                            fontWeight: "700", width: "100px", height: "40px", marginTop: '2%'
                        }}>
                        <PlusOutlined style={{fontSize:"30px", padding:"0rem 0rem 1rem 0rem"}}/>
                    </Button>
            </Modal>


            {/* <Card>
                <Card.Body> */}
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Card.Title style={{ fontWeight: "700", fontSize: "24px", color: "#344767" }}>
                            Ecommerce Scraper
                        </Card.Title>
                        <InfoCircleOutlined style={{ fontSize: "1.5rem", color: "#7B809A" }} />
                    </div>
                    <Card.Subtitle className="mb-2 text-muted" style={{ color: "#7B809A", fontWeight: "400" }}>
                        Import data from your ecommerce store. </Card.Subtitle>

                    <div style={{ marginTop: "2%" }}>
                        <Form.Group style={{ marginBottom: "2%" }}>
                            <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400, color: "#7B809A" }}>Select shop type.</Form.Label> 
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{padding:"0rem 0rem 1.5rem 0rem"}}>
                                <Select
                                    placeholder = "Select a shop"
                                    style={{ width: 150 }}
                                    options={menuItems}
                                    allowClear
                                    onChange = {onSelect}
                                />
                                </div>
                                <div style={{ fontSize: "2rem", margin: "0 1rem", color: "gray", height: "5rem" }}>|</div>
                                <p className='shop_text' onClick={modalFunc}> Add a new shop </p>

                            </div>
                            <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback>
                        </Form.Group>
                        

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                            <Button variant="danger" type="submit"  disabled = {uploadDisabled}
                                onClick={importShop} 
                                style={{
                                    fontWeight: "700", width: "100px", height: "40px"
                                }}>
                                Import
                            </Button>
                        </div>
                    </div>

                {/* </Card.Body>
            </Card> */}
        </div >
    )
}
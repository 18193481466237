import axios from "../auth/Axios";

export const loadProductSimilaritySingleRuns = (pages = 5, offset = 0) => {
    return async dispatch => {
        var runs = []
        let emp = -1
        try {
            for (let i = 1 + offset; i <= pages; i++) {
                const response = await axios.get(`/user/get-single-similarity-runs/${i}`);
                if (response.data.runs.length === 0) {
                    emp = i
                    break;
                }
                runs = runs.concat(response.data.runs);
            }
            if (emp == -1) emp = pages
            dispatch({
                type: "UPDATE_PRODUCT_SIMILARITY_SINGLE_RUNS",
                payload: runs,
                last_page: emp
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PRODUCT_SIMILARITY_PREVIOUS_RUNS_ERROR",
                payload: error
            });
        }
    }
};

export const loadProductSimilarityCSVRuns = (pages = 5, offset = 0) => {
    return async dispatch => {
        var runs = []
        let emp = -1
        try {
            for (let i = 1 + offset; i <= pages; i++) {
                const response = await axios.get(`/user/get-batch-similarity-runs/${i}`);
                if (response.data.csvs.length === 0) {
                    emp = i
                    break;
                }
                runs = runs.concat(response.data.csvs);
            }
            if (emp == -1) emp = pages
            dispatch({
                type: "UPDATE_PRODUCT_SIMILARITY_CSV_RUNS",
                payload: runs,
                last_page: emp
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PRODUCT_SIMILARITY_PREVIOUS_RUNS_ERROR",
                payload: error
            });
        }
    }
}

export function appendProductSimilaritySingleRuns(start = 1, end = 5, previousRuns) {
    return async dispatch => {
        var runs = []
        try {
            for (let i = start; i <= end; i++) {
                const response = await axios.get(`/user/get-single-similarity-runs/${i}`);
                runs = runs.concat(response.data.runs);
            }
            // check if a run already exists in the store by id and append only new runs
            runs = runs.filter(run => !previousRuns.some(r => r.id === run.id));

            dispatch({
                type: "APPEND_PRODUCT_SIMILARITY_SINGLE_RUNS",
                payload: runs
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PRODUCT_SIMILARITY_PREVIOUS_RUNS_ERROR",
                payload: error
            });
        }
    }
};

export const appendProductSimilarityCSVRuns = (start = 1, end = 5, previousRuns) => {
    return async dispatch => {
        var runs = []
        try {
            for (let i = start; i <= end; i++) {
                const response = await axios.get(`/user/get-batch-similarity-runs/${i}`);
                runs = runs.concat(response.data.csvs);
            }
            runs = runs.filter(run => !previousRuns.some(r => r.id === run.id));
            dispatch({
                type: "APPEND_PRODUCT_SIMILARITY_CSV_RUNS",
                payload: runs
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PRODUCT_SIMILARITY_PREVIOUS_RUNS_ERROR",
                payload: error
            });
        }
    }
};
import React, { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import axios from "../auth/Axios";
import { useNavigate } from "react-router";

const UniversalScraoer = () => {
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(`universal-scraper`, {
        search_term: searchTerm,
      })
      .then((res) => {
        setLoading(false);
        navigate(`/universal-scrape-info/${res.data.model_id}`);
      })
      .catch((err) => {
        setLoading(false);
        setError("Some Error Occured. Please Try Again.");
      });
  };

  return (
    <div>
      <Card
        style={{
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title
              style={{ fontWeight: "700", fontSize: "24px", color: "#344767" }}
            >
              Universal Scraper
            </Card.Title>
            {/* <img src={i_logo} style={{ width: "2rem" }} /> */}

            <Tooltip
              placement="top"
              title="Search and download the data from internet."
            >
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="mb-2 text-muted"
            style={{ color: "#7B809A", fontWeight: "400" }}
          >
            Search and download the data from internet.{" "}
          </Card.Subtitle>

          <div style={{ marginTop: "2%" }}>
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Enter the search term.
              </Form.Label>
              <Input
                placeholder="Search term"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
              <Form.Control.Feedback
                type="invalid"
                tooltip={true}
                as={() => <div></div>}
              ></Form.Control.Feedback>
            </Form.Group>

            <div>
              <p style={{ color: "red" }}> {error} </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="danger"
                type="submit"
                disabled={loading || searchTerm === ""}
                onClick={handleSubmit}
                style={{
                  fontWeight: "700",
                  width: "100px",
                  height: "40px",
                  marginTop: "2%",
                }}
              >
                {loading ? "Loading..." : "Search"}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UniversalScraoer;

import React, { useState, useEffect } from "react";
import axios from "../auth/Axios";
import { Table, Input, Modal, Cascader, Tag } from 'antd';
import { Button } from 'react-bootstrap';
import { PlusOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { shopifyTax } from "./ShopifyTaxonomy";

const ExportModal = ({csv, page, updatePage}) => {

    const [loading,setLoading] = useState(true)
    // const [page,setPage] = useState(1)
    const [csvRecords, setCsvRecords] = useState([]);
    const [editId, setEditId] = useState(null)
    const [recentEdit, setRecentEdit] = useState([])
    const [shopType, setShopType] = useState(null)
    const [extraInput, setExtraInput] = useState([])

    let id = csv.id
    let run_type = csv.run_type
    let shop_name = csv.shop_name
    let shop_type = csv.shop_type

    let colors = ['cyan', 'volcano', 'red', 'magenta', 'blue', 'purple', 'green']


    useEffect (()=> {
        const fetchData = () => {
            axios.get(`user/get-csv-records/${page}`, {
                params: {
                    csv_id: id
                }
            })
            .then(res => {
                setShopType(res.data.shop_type)
                if (res.data.runs.length === 0){
                    updatePage(0)
                    setLoading(false)
                }
                else {
                    setCsvRecords((prevState) => [...prevState, ...res.data.runs]);
                    updatePage((prev)=> prev+1)
                }
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
        }

        if (page === 1) {
            setCsvRecords([])
            setExtraInput([])
        }

        if (page > 0){
            fetchData()
        }

    },[page,id,updatePage])

    const changeFunction = (e, key, itemId) => {
        setRecentEdit(prev => {
            return [...prev, [e.target.value,key, itemId]]
        })
        // setRecentEdit([e.target.value,key, itemId])
    }

    const displayRender = (labels) => labels[labels.length - 1];

    const onSelect = (category, productId) => {
        let csvItem = csvRecords.filter(item => item.shop_product_id === productId)
        csvItem = csvItem[0]
        let index = csvRecords.findIndex(item => item.shop_product_id === productId)
        csvItem.resp_data.categories = category

        setCsvRecords(prev => {
            let initial = [...prev]
            initial[index] = csvItem
            return initial
        })
    }

    const updateFunction = (productIid) => {
        // console.log("heree",csvRecords)
        let csvItem = csvRecords.filter(item => item.shop_product_id === productIid)
        csvItem = csvItem[0]
        let index = csvRecords.findIndex(item => item.shop_product_id === productIid)

        if (csvItem.resp_data.tags === undefined) {
            let prodCategories = csvItem.resp_data.categories.split(" > ")
            for( let i = 0; i< recentEdit.length; i++)  {
                prodCategories[recentEdit[i][1]] = recentEdit[i][0]
            }
            // prodCategories[recentEdit[1]] = recentEdit[0]
            
            prodCategories = prodCategories.filter(item => String(item).trim() !== "")
            let result = prodCategories.join(" > ")
            csvItem.resp_data.categories = result

            setCsvRecords(prev => {
                let initial = [...prev]
                initial[index] = csvItem
                return initial
            })
        }

        else {
            let tags = csvItem.resp_data.tags
            for( let i = 0; i< recentEdit.length; i++)  {
                tags[recentEdit[i][1]] = recentEdit[i][0]
            }
            // csvItem.resp_data.tags[recentEdit[1]] = recentEdit[0]
            tags = tags.filter(item => String(item).trim() !== "")
            csvItem.resp_data.tags = tags
            setCsvRecords(prev => {
                let initial = [...prev]
                initial[index] = csvItem
                return initial
            })
        }
        setEditId(null)
        setRecentEdit([])
    }

    const capitalise = (item) => {
        return item.charAt(0).toUpperCase() + item.slice(1)
      }

    const exportFunc = () => {
        Modal.confirm(
            {
                title: "Export To Shop",
                content: `Are you sure you want to export to ${csv.shop_name}? This action is not reversible. Also, the changes made here won't be saved.`,
                cancelText:"NO",
                okText:"YES",
                onOk: () => {
                    const newarr = csvRecords.map((item,idx) => {
                        if (item.resp_data.tags === undefined){
                            return (
                                [item.resp_data.categories, item.shop_product_id]
                                )
                        }
                        else {
                            return (
                                [item.resp_data.tags, item.shop_product_id]
                            )
                        }
                    })
                    
                    axios.post("export-to-shop",{
                        shop_name:shop_name,
                        shop_type: shop_type,
                        run_type: run_type,
                        export_data: newarr
                        })
                    .then(res => {
                        console.log(res.data)
                    })
                    .catch (err => {
                        console.log(err)
                    })
                }
            }
        )
    }

    const columns = [
        {
          title: 'Product Title',
          render: (text, record) => (
            <div>
              <span>
              {record.req_data.data.title.length > 60 ? record.req_data.data.title.substring(0, 60) + "...." : record.req_data.data.title}
                </span>
            </div>
          ),
        },

        {
        title : run_type === "STATIC" ? "Product Tags" : "Product Categories",
        render: (text,record) => {
            if (record.run_type === "STATIC") {
                let itemId = record.id
                let productIid = record.shop_product_id

                return (
                    <div>
                        <span style = {{display:"flex", gap:"1rem" , alignItems:'center'}}>
                        {
                            record.resp_data.tags.map((item,idx) => {
                                return (
                                    <>
                                        {
                                            editId === itemId ?
                                                <Input key = {idx} type="text" defaultValue={item} style={{width:"160px"}}
                                                    onChange = { (e) => {
                                                    changeFunction(e,idx,productIid)
                                                    }
                                                }/>
                                            :
                                            
                                            // <p key = {idx}> {item} </p> 
                                            <Tag key={idx} color={
                                                colors[Math.floor(Math.random() * colors.length - 1)] || 'pink'
                                            }>{capitalise(item)}</Tag>
                                        }
                                    </>
                                )
                            })
                        }

                        {
                            editId === itemId  && (
                                <>
                                    {
                                        extraInput.map((item,index)=> {
                                            let start = record.resp_data.tags.length + index
                                            return (
                                                <>
                                                    <Input key = {start} type="text" style={{width:"160px"}}
                                                        onChange = { (e) => {
                                                        changeFunction(e,start,productIid)
                                                        }}
                                                    />
                                                </>
                                            )
                                        })
                                    }
                                    <Button variant="danger" type="submit"
                                        onClick={() => {
                                            setExtraInput((prev) => {
                                                return [...prev, 1]
                                            })
                                        }}
                                    > <PlusOutlined style={{fontSize:"15px"}}/> </Button>
                                </>
                            )
                        }
                        </span>
                    </div>
                )
            }

            else {
                let categories = record.resp_data.categories.split(" > ")
                let itemId = record.id
                let productIid = record.shop_product_id
                let shopifyCategories = record.resp_data.categories

                return (
                    <div style = {{display:"flex", gap:"1rem", alignItems:'center'}}>
                        <span style = {{display:"flex", gap:"1rem" , alignItems:'center'}}>
                            {
                                shopType === "Shopify"? 
                                <>
                                    <Cascader style = {{width:"600px"}}
                                    size='large' placeholder={shopifyCategories} expandTrigger="hover" defaultValue={shopifyCategories}
                                    options={shopifyTax} displayRender={displayRender} 
                                    dropdownMenuColumnStyle = {{width:"900px"}}
                                    showSearch = {true}
                                    onChange={(val,selectedOptions) => {
                                        let category = selectedOptions[0].label
                                        onSelect(category,productIid)
                                    }}
                                    />
                                </>
                                :
                                <>
                                    {
                                        categories.map((item,idx) => {
                                            // if (key !== categories.length )
                                            return (
                                                <>
                                                    {
                                                        editId === itemId ?
                                                        <>
                                                            <Input key = {idx} type="text" defaultValue={item} 
                                                                onChange = { (e) => {
                                                                changeFunction(e,idx,productIid)
                                                                }}
                                                            />
    
                                                            { 
                                                                idx !== categories.length -1 && (
                                                                <p style={{paddingTop:"1rem"}}>{ ">"} </p>
                                                            )}
                                                        </>
                                                        :
                                                        <> 
                                                            <p key = {idx}> {item} </p> 
                                                            { 
                                                                idx !== categories.length -1 && (
                                                                <p>{ ">"} </p>
                                                            )}
                                                        </>
                                                    }
                                                </>
                                            )
                                        })
                                    }
    
                                    {
                                        editId === itemId  && (
                                            <>
                                                {
                                                    extraInput.map((item,index)=> {
                                                        let start = categories.length + index
                                                        return (
                                                            <>
                                                                <p style={{paddingTop:"1rem"}}>{ ">"} </p>
                                                                <Input key = {start} type="text" 
                                                                    onChange = { (e) => {
                                                                    changeFunction(e,start,productIid)
                                                                    }}
                                                                />
                                                            </>
                                                        )
                                                    })
                                                }
                                                <Button variant="danger" type="submit"
                                                    onClick={() => {
                                                        setExtraInput((prev) => {
                                                            return [...prev, 1]
                                                        })
                                                    }}
                                                > <PlusOutlined style={{fontSize:"15px"}}/> </Button>
                                            </>
                                        )
                                    }
                                </>
                            }
                        </span>
                    </div>
                )
            }
        }
        },
        

          {
            // title: "Update",
            render : (text, record) => {
                let itemId = record.id
                let productIid = record.shop_product_id
                return (
                    <>
                        {
                            (shopType === "WooCommerce"  || record.run_type === "STATIC" )&&(
                                <div>
                                    { 
                                        editId === itemId ?
                                        <Button variant="danger" type="submit"
                                        onClick={() => {
                                            // setEditId(itemId)
                                            setExtraInput([])
                                            updateFunction(productIid)
                                        }}
                                        > <PlusSquareOutlined style={{fontSize:"20px"}}/> </Button>
                                        :
                                        <Button variant="danger" type="submit"
                                        onClick={() => {
                                            // console.log(itemId)
                                            setExtraInput([])
                                            setRecentEdit([])
                                            setEditId(itemId)
                                        }}
                                        > <EditOutlined style={{fontSize:"20px"}} />
                                        </Button>
                                    }
                                </div>
                            )
                        }
                    </>
                )
            }
          }
      ];

    return (
        <div>
            <Table size="middle" loading={loading} columns={columns}  locale={{emptyText: loading? "Processing..." : "No data"}}
                dataSource={csvRecords}
                pagination={{position: ['bottomLeft'], pageSize:5, size:"small", simple:true}}
                // onChange={handlePagination}
            />
            <Button variant="danger" type="submit"  
                style={{
                    fontWeight: "700", width: "100px", height: "40px", marginTop:"3rem"
                }}
                onClick = {exportFunc}
                >
                Export
            </Button>
        </div>
    )
}

export default ExportModal
import React, { useState } from "react";
import "../App.css";
import PumiceLogo from "./PumiceLogo";
import ReactDOM from "react-dom";
// import type MenuTheme from 'antd';
import { Divider, Menu, Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  MailOutlined,
  LogoutOutlined,
  QuestionOutlined,
  GlobalOutlined,
  SettingOutlined,
  DeploymentUnitOutlined,
  BarsOutlined,
  SlackOutlined,
  MenuOutlined,
  ShoppingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import pumice_logo from "../assets/pumice_logo.png";
import axios from "../auth/Axios";
import unauthaxios from "../auth/UnAuthAxios";

function getItem(label, key, icon, children, disabled, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

function NavBar() {
  const navigate = useNavigate();

  const handleClick = (e) => {
    var key = e.key;
    switch (key) {
      case "1":
        navigate("/");
        break;
      case "2":
        navigate("/staticcategorization");
        break;
      case "3":
        navigate("/dynamiccategorization");
        break;
      case "sub2":
        navigate("/productsimilarity");
        break;
      case "sub3":
        navigate("/image-similarity");
        break;
      case "ecom1":
        navigate("/ecommerce-scraper");
        break;

      case "ecom2":
        navigate("/universal-scraper");
        break;

      case "5":
        window.open("https://pumice-docs.vercel.app/", "_blank");
    }
  };

  const items = [
    getItem("Menu", "0", <MenuOutlined />, null),
    getItem("Overview", "1", <MailOutlined />),
    getItem(
      "Product Categorization",
      "sub1",
      <AppstoreOutlined />,
      [
        getItem("Static Categorization", "2", <BarsOutlined />),
        getItem("Dynamic Categorization", "3", <DeploymentUnitOutlined />),
      ],
      false
    ),
    getItem("Product Similarity", "sub2", <SettingOutlined />),
    getItem("Image Similarity", "sub3", <SettingOutlined />),

    getItem(
      "Ecommerce Utilities",
      "sub3",
      <ShoppingOutlined />,
      [
        getItem("Ecommerce Scraper", "ecom1", <DownloadOutlined />),
        getItem("Universal Scraper", "ecom2", <GlobalOutlined />),
      ],
      false
    ),
    getItem(
      "Docs",
      "4",
      null,
      [
        getItem("Help & Support", "5", <QuestionOutlined />),
        getItem("Join our Slack", "6", <SlackOutlined />),
      ],
      false,
      "group"
    ),
  ];
  const rootSubmenuKeys = ["1", "sub1", "sub2"];
  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    // <div style={{ position: "fixed" }}>
    <Menu
      theme="dark"
      // mode='inline'
      onClick={handleClick}
      style={{
        // width: '17vw',
        height: "90vh",
        // marginLeft: '1%',
        // marginTop: '1%',
        fontWeight: "500",
        borderRadius: "12px",
        // position: 'fixed',
        overflowY: "auto",
        overflowX: "hidden",
        // color: "#FFFFFF",
        // background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)"
      }}
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["1"]}
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      items={items}
    />
    // </div>
  );
}

export default NavBar;

import { Tag, Descriptions, Skeleton } from "antd";

const ProductInfo = (props) => {
  console.log(props);
  const target_product = props.productData.target_product_data;
  const embedding_product = props.productData.embedding_product_data;
  const score = props.productData.score;
  return (
    <div>
      <Descriptions
        bordered
        column={2}
        title="Target product"
        style={{ marginBottom: "10px" }}
      >
        <Descriptions.Item label="Title" span={2}>
          {target_product.Title}
        </Descriptions.Item>
        <Descriptions.Item label="Description" span={2}>
          {target_product.Description}
        </Descriptions.Item>
        <Descriptions.Item label="SKU ID" span={2}>
          {target_product.Target_product_SKU}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        bordered
        column={2}
        title="Embedding product"
        style={{ marginBottom: "10px" }}
      >
        <Descriptions.Item label="Title " span={2}>
          {embedding_product.title}
        </Descriptions.Item>
        <Descriptions.Item label="Description " span={2}>
          {embedding_product.description}
        </Descriptions.Item>
        <Descriptions.Item label="SKU ID  " span={2}>
          {embedding_product.sku}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions bordered column={2} title="Similarity">
        <Descriptions.Item label="Similarity match" span={2}>
          {score}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default ProductInfo;

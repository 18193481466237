import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col, Table, Modal, Button, Skeleton } from "antd";
import { Card } from "react-bootstrap";
import axios from "../auth/Axios";
import CSVInfo from "../components/CSVInfo";

const ImageSimilarityRunInfo = () => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [csvRecords, setCsvRecords] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [csvInfo, setCsvInfo] = useState({});
  const [reRender, setReRender] = useState(0);
  const [page, setPage] = useState(1);
  const csvId = params.csvId;
  const [eta, SetEta] = useState({
    eta: "Calculating...",
    rows_completed: "Calculating...",
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      axios
        .get("tasks/get-eta", {
          params: {
            csv_id: csvId,
            from_api: "False",
          },
        })
        .then((response) => {
          const res = response.data.time_left;
          if (res == "-1") {
            SetEta({
              eta: "Calculating...",
              rows_completed: response.data.rows_completed,
            });
          } else if (res == "-11") {
            SetEta({
              eta: "Experiencing high traffic, please try again later.",
              rows_completed: response.data.rows_completed,
            });
          } else {
            SetEta({ eta: res, rows_completed: response.data.rows_completed });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    setIsLoading(true);

    let timeout1 = null;
    if (page === 1) {
      timeout1 = setTimeout(() => {
        setReRender((prev) => prev + 1);
      }, 6000);
    }

    const fetchData = () => {
      axios
        .get(`user/get-image-similarity-csv-records/${page}`, {
          params: {
            csv_id: csvId,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.runs.length === 0) {
            console.log("no data");
          } else {
            if (timeout1 !== null) {
              clearTimeout(timeout1);
            }
            setCsvRecords((prevState) => [...prevState, ...res.data.runs]);
            if (page <= 20) {
              setPage((prev) => prev + 1);
            }
          }
        });
    };

    const fetchCsvInfo = () => {
      axios.get(`user/get-csv/${csvId}`).then((resp) => {
        setCsvInfo(resp.data.csv);
      });
    };

    fetchData();
    if (Object.keys(csvInfo).length === 0) {
      fetchCsvInfo();
    }
    setIsLoading(false);
  }, [csvId, page, reRender]);
  const retryRun = async () => {
    const res = await axios.post("tasks/retry-runs", {
      target_csv_id: csvId,
      run_type: "image_similarity",
    });
    if (res.status == "200") {
      window.alert("Run started again");
    }
  };
  const handleDownload = () => {
    axios
      .get(`user/download-image-similarity-results`, {
        params: {
          csv_id: csvId,
        },
      })
      .then((resp) => {
        //console.log(resp.headers);
        // download the file using javascript
        const url = window.URL.createObjectURL(new Blob([resp.data]));

        const link = document.createElement("a"); //hide this link

        link.href = url;
        link.setAttribute("download", "results_" + csvInfo.original_file_name); //or any other extension
        link.setAttribute("hidden", true);
        document.body.appendChild(link);
        link.click();
      });
  };

  const cancelRun = () => {
    Modal.confirm({
      title: "Cancel Run",
      content: "Are you sure you want to cancel the current run?",
      cancelText: "NO",
      okText: "YES",
      onOk: () => {
        axios.post("tasks/cancel-task", {
          csv_id: csvId,
        });
      },
      onCancel: () => setIsModalVisible(false),
    });
    setReRender((prev) => prev + 1);
  };

  const columns = [
    {
      title: "Image1 URL",
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>
            {record.req_data.image1url.length > 35
              ? record.req_data.image1url.substring(0, 35) + "...."
              : record.req_data.image1url}
          </span>
        </div>
      ),
    },
    {
      title: "Image2 URL",
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>
            {record.req_data.image2url.length > 35
              ? record.req_data.image2url.substring(0, 35) + "...."
              : record.req_data.image2url}
          </span>
        </div>
      ),
    },

    {
      title: "Similarity score",
      render: (text, record) => {
        let score = Math.round(record.resp_data.similarity_score * 100) / 100;

        return (
          <div>
            <span>{score}</span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <Card.Title>Run Info</Card.Title>
                <Card.Subtitle className="mb-4 text-muted">
                  Details about the run.
                </Card.Subtitle>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <CSVInfo info={csvInfo} etaDetails={eta} />
                )}
                <Row justify="end">
                  {csvInfo.status === "PROCESSING" ? (
                    <>
                      <Button
                        style={{
                          backgroundColor: "#E91F63",
                          border: "none",
                          marginTop: "2%",
                          marginRight: "5%",
                        }}
                        onClick={retryRun}
                        type="primary"
                        size="medium"
                      >
                        Retry Run
                      </Button>

                      <Button
                        style={{
                          backgroundColor: "#E91F63",
                          border: "none",
                          marginTop: "2%",
                        }}
                        onClick={cancelRun}
                        type="primary"
                        size="medium"
                      >
                        Cancel Run
                      </Button>
                    </>
                  ) : null}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col span={24}>
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Card.Title>Generated scores</Card.Title>
                  <Button
                    className="custom-hover"
                    variant="outline-danger"
                    style={{
                      // backgroundColor: '#dc3545',
                      fontWeight: "700",
                      color: "#dc3545",
                      border: "1px solid #E91F63",
                    }}
                    onClick={handleDownload}
                    size="medium"
                  >
                    Download Result CSV
                  </Button>
                </div>
                <Card.Subtitle className="mb-4 text-muted">
                  Similarity scores generated for the images <br />
                  <small class="text-muted">
                    Only some results are shown, please download the CSV for a
                    complete list of results.
                  </small>
                </Card.Subtitle>
                {console.log(columns)}
                {console.log(csvRecords)}
                <Table
                  locale={{
                    emptyText:
                      csvInfo.status == "PROCESSING"
                        ? "Processing..."
                        : "No data",
                  }}
                  columns={columns}
                  dataSource={[...csvRecords]}
                  loading={isLoading}
                  scroll={{ x: "200px" }}
                  pagination={{
                    position: ["bottomLeft"],
                    pageSize: 5,
                    size: "small",
                    simple: true,
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ImageSimilarityRunInfo;

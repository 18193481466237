import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col, Table, Modal, Button, Skeleton } from "antd";
import { Card } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "../auth/Axios";
import CSVInfo from "../components/CSVInfo";
import ProductInfo from "../components/ProductInfo";

const RunInfo = (props) => {
  const params = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [csvRecords, setCsvRecords] = useState([]);
  const [info, setInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [csvInfo, setCsvInfo] = useState({});

  const [reRender, setReRender] = useState(0);
  const [page, setPage] = useState(1);
  const [eta, SetEta] = useState({ eta: "Calculating...", rows_completed: 0 });

  const csvId = params.csvId;
  // setCsvInfo(location.state?.csv)

  const handleClick = (productData) => {
    console.log(productData);
    setIsModalVisible(true);
    setInfo(productData);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      axios
        .get("tasks/get-eta", {
          params: {
            csv_id: csvId,
            from_api: "False",
          },
        })
        .then((response) => {
          const res = response.data.time_left;
          console.log(res);
          if (res == "-1") {
            SetEta({
              eta: "Calculating...",
              rows_completed: response.data.rows_completed,
            });
          } else if (res == "-11") {
            SetEta({
              eta: "Experiencing high traffic, please try again later.",
              rows_completed: response.data.rows_completed,
            });
          } else {
            SetEta({ eta: res, rows_completed: response.data.rows_completed });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    let timeout1 = null;
    if (page === 1) {
      timeout1 = setTimeout(() => {
        setReRender((prev) => prev + 1);
      }, 6000);
    }

    setIsLoading(true);
    const fetchData = () => {
      axios
        .get(`user/get-csv-records/${page}`, {
          params: {
            csv_id: csvId,
          },
        })
        .then((res) => {
          if (res.data.runs.length === 0) {
            console.log("no data");
          } else {
            if (timeout1 !== null) {
              clearTimeout(timeout1);
            }

            setCsvRecords((prevState) => [...prevState, ...res.data.runs]);
            if (page <= 20) {
              setPage((prev) => prev + 1);
            }
          }
        });
    };

    const fetchCSVInfo = () => {
      axios.get(`user/get-csv/${csvId}`).then((resp) => {
        setCsvInfo(resp.data.csv);
      });
    };

    fetchData();
    if (Object.keys(csvInfo).length === 0) {
      fetchCSVInfo();
    }

    setIsLoading(false);
  }, [reRender, csvId, page]);

  const handleDownload = () => {
    axios
      .get(`user/download-results`, {
        params: {
          csv_id: csvId,
        },
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a"); //hide this link
        link.href = url;
        link.setAttribute("download", "results_" + csvInfo.original_file_name); //or any other extension
        link.setAttribute("hidden", true);
        document.body.appendChild(link);
        link.click();
      });
  };

  const cancelRun = () => {
    Modal.confirm({
      title: "Cancel Run",
      content: "Are you sure you want to cancel the current run?",
      cancelText: "NO",
      okText: "YES",
      onOk: () => {
        axios.post("tasks/cancel-task", {
          csv_id: csvId,
        });
      },
      onCancel: () => {
        setIsModalVisible(false);
      },
    });
    setReRender((prev) => prev + 1);
  };

  const columns = [
    {
      title: "Time",
      dataIndex: "created_at",
      render: (text, record) => (
        <div>{new Date(text).toString().split("GMT")[0]}</div>
      ),
    },
    {
      title: "Product Title",
      // dataIndex: 'url',
      render: (text, record) => (
        <div>
          <span>
            {record.req_data.data.title.length > 60
              ? record.req_data.data.title.substring(0, 60) + "...."
              : record.req_data.data.title}
          </span>
        </div>
      ),
    },
    {
      title: "Returned Entities",
      render: (text, record) => {
        return (
          <div>
            <span>
              <Button
                className="custom-hover"
                variant="outline-danger"
                style={{
                  fontWeight: "700",
                  color: "#dc3545",
                  border: "1px solid #E91F63",
                }}
                size="small"
                onClick={() =>
                  handleClick({
                    resp_data: record.resp_data,
                    req_data: record.req_data,
                    status_code: record.status_code,
                  })
                }
              >
                View
              </Button>
            </span>
          </div>
        );
      },
    },
  ];

  const onCancel = () => {
    setIsModalVisible(false);
  };
  const retryRun = async () => {
    const res = await axios.post("tasks/retry-runs", {
      target_csv_id: csvId,
    });
    console.log(res);
    if (res.status == "200") {
      window.alert("Run started again");
    }
  };
  return (
    <>
      <div>
        <Modal
          title="Product Info"
          visible={isModalVisible}
          onCancel={onCancel}
          footer={null}
          centered={true}
          width="60vw"
        >
          <ProductInfo loading={false} productData={info} />
        </Modal>
        <Row>
          <Col span={24}>
            <Card
              style={{
                height: "46vh",
                borderRadius: "12px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <Card.Title>Run Info</Card.Title>
                <Card.Subtitle className="mb-4 text-muted">
                  Information about the uploaded CSV.
                </Card.Subtitle>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <CSVInfo info={csvInfo} etaDetails={eta} />
                )}
                <Row justify="end">
                  {(csvInfo.status !== "CANCELLED") &
                  (csvInfo.status !== "COMPLETED") ? (
                    <>
                      <Button
                        style={{
                          backgroundColor: "#E91F63",
                          border: "none",
                          marginTop: "1%",
                          marginRight: "5%",
                        }}
                        onClick={retryRun}
                        type="primary"
                        size="medium"
                      >
                        Retry Run
                      </Button>

                      <Button
                        style={{
                          backgroundColor: "#E91F63",
                          border: "none",
                          marginTop: "1%",
                        }}
                        onClick={cancelRun}
                        type="primary"
                        size="medium"
                      >
                        Cancel Run
                      </Button>
                    </>
                  ) : null}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col span={24}>
            <Card
              style={{
                height: "65vh",
                borderRadius: "12px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Card.Title>Generated Entities</Card.Title>
                  <Button
                    className="custom-hover"
                    variant="outline-danger"
                    style={{
                      fontWeight: "700",
                      color: "#dc3545",
                      border: "1px solid #E91F63",
                    }}
                    onClick={handleDownload}
                    size="medium"
                  >
                    Download Result CSV
                  </Button>
                  {/* <img src={i_logo} style={{ width: "2rem" }} /> */}
                </div>
                <Card.Subtitle className="mb-4 text-muted">
                  Generated categories for the products in the CSV. <br />
                  <small class="text-muted">
                    Only some results are shown, please download the CSV for a
                    complete list of results.
                  </small>
                </Card.Subtitle>
                <Table
                  locale={{
                    emptyText:
                      csvInfo.status === "PROCESSING"
                        ? "Processing..."
                        : "No data",
                  }}
                  columns={columns}
                  dataSource={csvRecords}
                  loading={isLoading}
                  pagination={{
                    position: ["bottomLeft"],
                    pageSize: 5,
                    size: "small",
                    simple: true,
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RunInfo;

var initialState = {
    singleRuns: [],
    csvRuns: [],
    scraped : [],
    universalScraped : [],
    last_page_single: 0,
    last_page_csv: 0,
    last_page_scraped: 0,
    last_page_universal: 0,
}


export const previousRunsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOAD_PREVIOUS_RUNS":
            return action.payload;
        case "UPDATE_SINGLE_RUNS":
            return { ...state, singleRuns: action.payload, last_page_single: action.last_page};
            // return [...state, action.payload];
        case "UPDATE_CSV_RUNS":
            return { ...state, csvRuns: action.payload, last_page_csv: action.last_page};
        case "UPDATE_PREVIOUS_SCRAPED":
            return { ...state, scraped: action.payload, last_page_scraped: action.last_page};
        case "UPDATE_PREVIOUS_UNIVERSAL_SCRAPED":
            return { ...state, universalScraped: action.payload, last_page_universal: action.last_page};
        case "APPEND_SINGLE_RUNS":
            return { ...state, singleRuns: state.singleRuns.concat(action.payload)};
        case "APPEND_CSV_RUNS":
            return { ...state, csvRuns: state.csvRuns.concat(action.payload)};
        case "APPEND_PREVIOUS_SCRAPED":
            return { ...state, scraped: state.scraped.concat(action.payload)};
        case "APPEND_PREVIOUS_UNIVERSAL_SCRAPED":
            return { ...state, universalScraped: state.scraped.concat(action.payload)};
        case "LOAD_PREVIOUS_RUNS_ERROR":
            return action.payload;
        case "LOAD_PREVIOUS_SCRAPED_ERROR":
            return action.payload;
        case "LOAD_PREVIOUS_UNIVERSAL_SCRAPED_ERROR":
            return action.payload;
        default:
        return state;
    }
}
import React, { useEffect, useState } from "react";

import { Row, Col } from 'antd';
import { useDispatch } from "react-redux";
import { updateBreadcrumb } from "../actions/actions";

import UploadArea from '../components/UploadArea';
import SingleRunDynamic from '../components/SingleRunDynamic';
import PreviousRuns from '../components/PreviousRuns';
import Dropzone from "../components/Dropzone";

import axios from '../auth/Axios';

const DynamicCategorization = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateBreadcrumb(['Product Categorization', 'Dynamic Categorization']));
  }, []);

  let [taxonomy_trees, setTaxonomyTrees] = useState([])
  let [models, setModels] = useState([])

    useEffect(() => {
        axios.get('user/get-taxonomy-trees/1').then((resp)=>{

            const filtered_resp = resp.data.treess.filter((tree)=> tree.from_api===false)
            // console.log("tax treee is", filtered_resp)

            filtered_resp.map((tree,idx)=>{
                    var value = tree.id
                    var label = tree.original_file_name
                    // taxonomy_trees.push({value:value, label:label})
                    setTaxonomyTrees((prevState) => [
                      ...prevState,
                      { value: value, label: label },
                    ]);
            })
            // return taxonomy_trees
        })

      axios.get('user/get-model-ids').then((resp)=>{
        resp.data.models.map((model,idx)=>{
          var value = model.id
          var label = model.model_name
          // models.push({value:value, label:label})
          setModels((prevState) => [
            ...prevState,
            { value: value, label: label },
          ]);
        })
      })

    },[])

  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <UploadArea taxonomyTrees={taxonomy_trees} models={models}/>
          {/* <Dropzone /> */}
        </Col>
        <Col span={12}>
          <SingleRunDynamic taxonomyTrees={taxonomy_trees} models={models}/>
        </Col>
      </Row>
      <Row style={{ marginTop: '2%' }}>
        <Col span={24}>
          <PreviousRuns />
        </Col>
      </Row>
    </div>
  );
}

export default DynamicCategorization;
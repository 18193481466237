import React, { useEffect, useState } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { Button } from "react-bootstrap";
import { Row, Col, Modal } from "antd";
import { useDispatch } from "react-redux";
import { updateBreadcrumb } from "../actions/actions";
import axios from "../auth/Axios";

import UploadCSVs from "../components/UploadCSVs";
import SingleSimilarityRun from "../components/SingleSimilarityRun";
import ProductSimilarityPreviousRuns from "../components/ProductSimilarityPreviousRuns";

const ProductSimilarityV2 = () => {
  const dispatch = useDispatch();
  const [embeddingCsvs, setEmbeddingCsvs] = useState([]);
  const [models, setModels] = useState([]);

  useEffect(() => {
    dispatch(updateBreadcrumb(["Product Similarity"]));

    const getSimilarityModels = () => {
      axios
        .get("user/get-similarity-models?for_image=False")
        .then((res) => {
          setModels(res.data.models);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getEmbeddingCsvs = () => {
      axios.get("user/get-embedding-csvs/1").then((resp) => {
        const filtered_resp = resp.data.embedding_csvs.filter(
          (csv) => csv.from_api === false
        );
        filtered_resp.map((csv, idx) => {
          var value = csv.id;
          var label = csv.original_file_name;
          setEmbeddingCsvs((prevState) => [
            ...prevState,
            { value: value, label: label },
          ]);
        });
      });
    };
    getSimilarityModels();
    getEmbeddingCsvs();
  }, []);

  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <UploadCSVs embeddingCSVs={embeddingCsvs} models={models} />
          {/* <Dropzone /> */}
        </Col>
        <Col span={12}>
          <SingleSimilarityRun />
        </Col>
      </Row>
      <Row style={{ marginTop: "2%" }}>
        <Col span={24}>
          <ProductSimilarityPreviousRuns />
        </Col>
      </Row>
    </div>
  );
};

export default ProductSimilarityV2;

// import detailsReducer from "./detailsReducer";
// import campaignReducer from "./campaignReducer";
// import templateReducer from "./templateReducer";
import userReducer from "./userReducer";
import { previousRunsReducer } from "./previousRunsReducer";
import { productSimilarityPreviousRunsReducer } from "./productSimilarityPreviousRunsReducer";
import { imageSimilarityPreviousRunsReducer } from "./imageSimilarityPreviousRunsReducer";
import { breadcrumbReducer } from "./reducers";
import { configureStore } from "@reduxjs/toolkit";

const appReducer = {
  reducer: {
    user: userReducer,
    breadcrumb: breadcrumbReducer,
    previousRuns: previousRunsReducer,
    productSimilarityPreviousRuns: productSimilarityPreviousRunsReducer,
    imageSimilarityPreviousRuns: imageSimilarityPreviousRunsReducer,
  },
};
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default appReducer;

// export const store = configureStore(appReducer);

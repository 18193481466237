import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Modal,
  Button,
  Skeleton,
  ConfigProvider,
  Empty,
} from "antd";
import { Card } from "react-bootstrap";
import axios from "../auth/Axios";
import CSVInfo from "../components/CSVInfo";
import ProductSimilarityCSVInfo from "../components/ProductSimilarityCSVInfo";
import SimilarityDetails from "../components/SimilarityDetails";

const ProductSimilarityRunInfo = () => {
  const params = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [csvRecords, setCsvRecords] = useState([]);
  const [info, setInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [csvInfo, setCsvInfo] = useState(location.state.csv || {})
  const [csvInfo, setCsvInfo] = useState({});
  const [reRender, setReRender] = useState(0);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [col, setCol] = useState([]);
  const [similarityModalVisible, SetSimilarityModalVisible] = useState(false);
  const [similarityInfo, setSimilarityInfo] = useState("");
  const [eta, SetEta] = useState({
    eta: "Calculating...",
    rows_completed: "Calculating...",
  });
  // const [similarItemsNo, setSimilarItemsNo] = useState(0)
  const csvId = params.csvId;
  useEffect(() => {
    const intervalId = setInterval(() => {
      axios
        .get("tasks/get-eta", {
          params: {
            csv_id: csvId,
            from_api: "False",
            run_type: "similarity",
          },
        })
        .then((response) => {
          const res = response.data.time_left;
          console.log(res);
          if (res == "-1") {
            SetEta({
              eta: "Calculating...",
              rows_completed: response.data.rows_completed,
            });
          } else if (res == "-11") {
            SetEta({
              eta: "Experiencing high traffic, please try again later.",
              rows_completed: response.data.rows_completed,
            });
          } else {
            SetEta({ eta: res, rows_completed: response.data.rows_completed });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    setIsLoading(true);

    let timeout1 = null;
    if (page === 1) {
      timeout1 = setTimeout(() => {
        setReRender((prev) => prev + 1);
      }, 6000);
    }

    const fetchData = () => {
      axios
        .get(`user/get-similarity-csv-records/${page}`, {
          params: {
            csv_id: csvId,
            embedding: true,
          },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.runs.length === 0) {
            console.log("no data");
          } else {
            console.log(res.data);
            if (timeout1 !== null) {
              clearTimeout(timeout1);
            }
            const col_local = [];
            const tableData_local = [];
            let maximum_value = -1;
            let minimum_value = 1;

            const embeddingRecord = res.data.embeddingRecords;

            let embeddingObj = {};
            //created a primary object of all embedding records
            for (let i = 0; i < embeddingRecord.length; i++) {
              embeddingObj[embeddingRecord[i]["sku"]] = "NA";
            }

            for (let i of res.data.runs) {
              let tempObj = { ...embeddingObj };
              tempObj["Target_product_SKU"] =
                i.req_data.uploaded_products[0].sku_id;
              tempObj["Description"] =
                i.req_data.uploaded_products[0].description;
              tempObj["Title"] = i.req_data.uploaded_products[0].title;

              for (let j of i.resp_data[0].similar_products) {
                if (j.similarity_score != "NA") {
                  tempObj[j.product.sku_id] = parseFloat(
                    j.similarity_score
                  ).toFixed(2);

                  maximum_value = Math.max(
                    maximum_value,
                    tempObj[j.product.sku_id]
                  );
                  minimum_value = Math.min(
                    minimum_value,
                    tempObj[j.product.sku_id]
                  );
                }
              }

              tableData_local.push(tempObj);
            }

            col_local.push({
              title: "Target product SKU",
              dataIndex: "Target_product_SKU",
              key: "Target_product_SKU",
            });
            //creating different col names (embedding product) titles
            for (let i of embeddingRecord) {
              col_local.push({
                title:
                  i.title.length > 15
                    ? i.title.substring(0, 15) + "..."
                    : i.title,
                dataIndex: i.sku,
                key: i.sku,
                width: 150,
                onCell: (record, rowIndex) => {
                  return {
                    onClick: (ev) => {
                      console.log(i);
                      setSimilarityInfo({
                        //sending data to the modal
                        target_product_data: record,
                        embedding_product_data: i,
                        score: record[i.sku],
                      });
                      SetSimilarityModalVisible(true);
                    },
                  };
                },
                render(text, record) {
                  let b = (100 - 0) * text;
                  let c = b + 0;
                  return {
                    props: {
                      style: {
                        background: "hsl(" + c + ", 100%, 50%)",
                        color: "black",
                      },
                    },
                    children: <div>{text}</div>,
                  };
                },
              });
              // console.log(col_local);
            }

            setCol((prev) => [...prev, ...col_local]);

            setTableData((prev) => [...prev, ...tableData_local]);
            setCsvRecords((prevState) => [...prevState, ...res.data.runs]);
            if (page <= 20) {
              setPage((prev) => prev + 1);
            }
          }
        });
    };

    const fetchCsvInfo = () => {
      axios.get(`user/get-csv/${csvId}`).then((resp) => {
        setCsvInfo(resp.data.csv);
      });
    };

    fetchData();
    if (Object.keys(csvInfo).length === 0) {
      fetchCsvInfo();
    }
    setIsLoading(false);
  }, [csvId, page, reRender]);

  const handleDownload = () => {
    axios
      .get(`user/download-similarity-results`, {
        params: {
          csv_id: csvId,
        },
      })
      .then((resp) => {
        //console.log(resp.headers);
        // download the file using javascript
        const url = window.URL.createObjectURL(new Blob([resp.data]));

        const link = document.createElement("a"); //hide this link

        link.href = url;
        link.setAttribute("download", "results_" + csvInfo.original_file_name); //or any other extension
        link.setAttribute("hidden", true);
        document.body.appendChild(link);
        link.click();
      });
  };

  const retryRun = async () => {
    const res = await axios.post("tasks/retry-runs", {
      target_csv_id: csvId,
      run_type: "similarity",
    });
    if (res.status == "200") {
      window.alert("Run started again");
    }
  };
  const cancelRun = () => {
    Modal.confirm({
      title: "Cancel Run",
      content: "Are you sure you want to cancel the current run?",
      cancelText: "NO",
      okText: "YES",
      onOk: () => {
        axios.post("tasks/cancel-task", {
          csv_id: csvId,
        });
      },
      onCancel: () => setIsModalVisible(false),
    });
    setReRender((prev) => prev + 1);
  };

  const columns = [
    {
      title: "Target Product",
      // dataIndex: 'url',
      render: (text, record) => (
        <div>
          <span>
            {record.req_data.uploaded_products[0].title.length > 35
              ? record.req_data.uploaded_products[0].title.substring(0, 35) +
                "...."
              : record.req_data.uploaded_products[0].title}
          </span>
        </div>
      ),
    },
    {
      title: "Similar Product 1",
      // dataIndex: 'url',
      render: (text, record) => (
        <div>
          <span>
            {record.resp_data[0].product.title.length > 35
              ? record.resp_data[0].product.title.substring(0, 35) + "...."
              : record.resp_data[0].product.title}
          </span>
        </div>
      ),
    },

    {
      title: "Similar Product 2",
      render: (text, record) => {
        let title;
        if (
          record.resp_data[0].similar_products === undefined ||
          record.resp_data[0].similar_products[0] === undefined
        ) {
          title = "-";
        } else {
          title = record.resp_data[0].similar_products[0].product.title;
        }
        // setSimilarItemsNo(record.resp_data[0].similar_products.length)
        if (title.length > 30) {
          title = title.substring(0, 30) + "...";
        }
        return (
          // <div style = {{width:"150px",whiteSpace:"nowrap",overflowX:"auto"}}>
          <div>
            <span>{title}</span>
          </div>
        );
      },
    },

    {
      title: "Similar Product 3",
      render: (text, record) => {
        let title;
        if (
          record.resp_data[0].similar_products === undefined ||
          record.resp_data[0].similar_products[1] === undefined
        ) {
          title = "-";
        } else {
          title = record.resp_data[0].similar_products[1].product.title;
        }
        if (title.length > 30) {
          title = title.substring(0, 30) + "...";
        }
        return (
          <div>
            <span>{title}</span>
          </div>
        );
      },
    },

    {
      title: "Similar Product 4",
      render: (text, record) => {
        let title;
        if (
          record.resp_data[0].similar_products === undefined ||
          record.resp_data[0].similar_products[2] === undefined
        ) {
          title = "-";
        } else {
          title = record.resp_data[0].similar_products[2].product.title;
        }

        if (title.length > 30) {
          title = title.substring(0, 30) + "...";
        }
        return (
          <div>
            <span>{title}</span>
          </div>
        );
      },
    },
  ];
  let locale = {
    emptyText: "No data",
  };

  return (
    <>
      <div>
        <Modal
          title="Product Info"
          visible={isModalVisible}
          centered={true}
          width="60vw"
        >
          <ProductSimilarityCSVInfo loading={false} productData={info} />
        </Modal>
        <Modal
          title="Similarity details"
          visible={similarityModalVisible}
          centered={true}
          width="60vw"
          onCancel={() => SetSimilarityModalVisible(false)}
        >
          <SimilarityDetails productData={similarityInfo} />
        </Modal>
        <Row>
          <Col span={24}>
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <Card.Title>Run Info</Card.Title>
                <Card.Subtitle className="mb-4 text-muted">
                  Details about the run.
                </Card.Subtitle>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <CSVInfo info={csvInfo} etaDetails={eta} />
                )}
                <Row justify="end">
                  {csvInfo.status === "PROCESSING" ? (
                    <>
                      <Button
                        style={{
                          backgroundColor: "#E91F63",
                          border: "none",
                          marginTop: "2%",
                          marginRight: "5%",
                        }}
                        onClick={retryRun}
                        type="primary"
                        size="medium"
                      >
                        Retry Run
                      </Button>

                      <Button
                        style={{
                          backgroundColor: "#E91F63",
                          border: "none",
                          marginTop: "2%",
                        }}
                        onClick={cancelRun}
                        type="primary"
                        size="medium"
                      >
                        Cancel Run
                      </Button>
                    </>
                  ) : null}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "2%" }}>
          <Col span={24}>
            <Card
              style={{
                borderRadius: "12px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Card.Title>Generated Entities</Card.Title>
                  <Button
                    className="custom-hover"
                    variant="outline-danger"
                    style={{
                      // backgroundColor: '#dc3545',
                      fontWeight: "700",
                      color: "#dc3545",
                      border: "1px solid #E91F63",
                    }}
                    onClick={handleDownload}
                    size="medium"
                  >
                    Download Result CSV
                  </Button>
                </div>
                <Card.Subtitle className="mb-4 text-muted">
                  Similar products for the products in the Target CSV found in
                  Embeddings CSV. <br />
                  <small class="text-muted">
                    Only some results are shown, please download the CSV for a
                    complete list of results.
                  </small>
                </Card.Subtitle>
                <Table
                  locale={{
                    emptyText:
                      csvInfo.status == "PROCESSING"
                        ? "Processing..."
                        : "No data",
                  }}
                  columns={columns}
                  dataSource={[...csvRecords]}
                  loading={isLoading}
                  scroll={{ x: "200px" }}
                  pagination={{
                    position: ["bottomLeft"],
                    pageSize: 5,
                    size: "small",
                    simple: true,
                  }}
                />

                {/* <Table columns={columns} dataSource={csvRecords} loading={isLoading?['Loading']:[...csvRecords]} pagination={{ position: ['bottomLeft'], pageSize: 5, size: "small", simple: true }} /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div style={{ marginTop: "20px" }}>
          <Card>
            <Card.Body>
              {!isLoading && (
                <Table
                  dataSource={tableData}
                  columns={col}
                  locale={locale}
                  scroll={{ x: true }}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ProductSimilarityRunInfo;

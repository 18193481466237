import React, { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import {
  InfoCircleOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Cascader, Modal, Select, Tooltip } from "antd";
import axios from "../auth/Axios";
import ProductInfo from "./ProductInfo";
import { useDropzone } from "react-dropzone";
import i_logo from "../assets/information.png";
import { download } from "./utils";

const SingleRunDynamic = (props) => {
  const [productInfo, setProductInfo] = React.useState({
    productTitle: "",
    productCategories: "-",
    productDescription: "",
  });

  const [isValid, setIsValid] = React.useState({
    productTitle: true,
    productCategories: true,
    productDescription: true,
  });

  const [treeUploaded, setTreeUploaded] = useState({
    uploaded: false,
    file_name: "",
    tree_file: null,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState("");
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [selected_items, setSelectedItems] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  const onSelect = (e) => {
    console.log(e);
    setSelectedItems(e);
    setIsUploadDisabled(true);
    if (e == undefined) {
      setIsUploadDisabled(false);
    }
  };

  useEffect(() => {
    setMenuItems([
      {
        value: "tree",
        label: "Trees",
        disabled: [...props.taxonomyTrees].length === 0,
        children: [...props.taxonomyTrees],
      },
      {
        value: "model",
        label: "Models",
        disabled: [...props.models].length === 0,
        children: [...props.models],
      },
    ]);
  }, [props]);

  const displayRender = (labels) => labels[labels.length - 1];

  var obj = {};
  const handleSubmit = (event) => {
    for (let key in productInfo) {
      obj[key] = productInfo[key] !== "" ? true : false;
      setIsValid((prevState) => ({
        ...prevState,
        [key]: productInfo[key] !== "" ? true : false,
      }));
    }
    event.preventDefault();
    if (
      obj["productTitle"] &&
      obj["productCategories"] &&
      obj["productDescription"]
    ) {
      setIsModalVisible(true);
      setLoading(true);
      let formData = new FormData();
      let req_body = productInfo;
      for (let key in req_body) {
        formData.append(key, req_body[key]);
      }
      // console.log(selected_tree, treeUploaded)
      if (selected_items != null) {
        formData.append("selected_items", selected_items);
        // req_body.tree_file = selected_tree.name
      }
      if (treeUploaded.uploaded) {
        formData.append("tree_file", treeUploaded.tree_file);
        console.log("data", formData, treeUploaded);
      }

      axios
        .post(
          "product-categorization/product-categorization-dynamically",
          formData
        )
        .then((res) => {
          // console.log(res);
          setResponse(res.data);
          setLoading(false);
          // setIsModalVisible(true);
        })
        .catch(function (error) {
          console.log(error);
          setIsModalVisible(false);
          setLoading(false);
          Modal.error({
            title: "Error",
            content:
              error.response?.data?.message ||
              "Something went wrong. Please try again.",
          });
        });
    }
  };

  const {
    getRootProps: getRootTreeFileProps,
    getInputProps: getInputTreeFileProps,
    open: openTree,
    isDragActive: isTreeDragActive,
  } = useDropzone({
    noClick: true,
    accept: {
      "text/plain": [".txt"],
    },
    onDrop: (acceptedFile) => {
      // do something with the tree file
      if (acceptedFile[0].path.split(".").pop() === "txt") {
        setTreeUploaded({
          ...treeUploaded,
          uploaded: true,
          file_name: acceptedFile[0].path,
          tree_file: acceptedFile[0],
        });
      } else {
        alert("please upload a txt file");
      }
    },
  });

  const handleTreeReset = () => {
    setTreeUploaded({
      ...treeUploaded,
      uploaded: false,
      file_name: "",
    });
  };

  return (
    <div>
      <Modal
        title="Response"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="60vw"
      >
        {/* {JSON.stringify(response)} */}
        <ProductInfo
          loading={loading}
          productData={{
            resp_data: response.product_categories,
            req_data: {
              data: {
                title: productInfo.productTitle,
                description: productInfo.productDescription,
              },
            },
            status_code: 200,
          }}
        />
      </Modal>
      <Card
        style={{
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          minHeight: "60vh",
        }}
      >
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title
              style={{ fontWeight: "700", fontSize: "24px", color: "#344767" }}
            >
              Single Run
            </Card.Title>
            {/* <img src={i_logo} style={{ width: "2rem" }} /> */}

            <Tooltip
              placement="top"
              title="To generate dynamic categorization for a single item . Enter title, description and provide a model/tree"
            >
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="mb-2 text-muted"
            style={{ color: "#7B809A", fontWeight: "400" }}
          >
            Enter product title and description to get a product category.{" "}
          </Card.Subtitle>

          <div
            {...getRootTreeFileProps()}
            style={{
              marginTop: "3%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "26%",
              backgroundColor: "#F8F9FA",
              border: "1px solid #C7CCD0",
              borderRadius: "12px",
            }}
          >
            {!treeUploaded.uploaded && (
              <div style={{ margin: "4vh 1vw 4vh 1vw" }}>
                <input {...getInputTreeFileProps()} />
                <div
                  style={{
                    marginBottom: "0.5rem",
                    fontWeight: "400",
                    color: "#7B809A",
                  }}
                >
                  Upload your custom taxonomy file in the Google Product
                  Taxonomy format or select a pre-trained model already fit to
                  popular taxonomies from the dropdown. Your past uploaded
                  taxonomies are available in the dropdown as well .
                  <button
                    className="download-button"
                    onClick={() => {
                      download(
                        "https://raw.githubusercontent.com/Sirri69/Test/master/basic_taxonomy.txt",
                        "sample-taxonomy.txt"
                      );
                    }}
                  >
                    Download File Example .
                  </button>
                </div>
                {!isTreeDragActive && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    {/* <Button variant="outline-danger" style={{ marginRight: "1rem" }}>SELECT PRODUCT TAXONOMY</Button> */}
                    {/* <Dropdown overlay={<Menu items={menuItems}/>} placement="bottom">
                                            <Space>
                                                <Button variant="outline" style={{ color: "#7B809A", fontWeight: "400", height: "2.8rem", background: "#FFFFFF", border: "0.7px solid #C7CCD0", borderRadius: "12px" }}>
                                                    Select Product Taxonomy <DownOutlined style={{ color: "#7B809A", fontWeight: "400" }} /> </Button>
                                            </Space>
                                        </Dropdown> */}
                    <Cascader
                      size="large"
                      placeholder="Taxonomy or Model"
                      expandTrigger="hover"
                      options={menuItems}
                      displayRender={displayRender}
                      onChange={onSelect}
                    />
                    <div
                      style={{
                        fontSize: "2rem",
                        margin: "0",
                        margin: "0 1rem",
                        color: "gray",
                        height: "3rem",
                      }}
                    >
                      |
                    </div>
                    <Button
                      variant="outline-danger"
                      style={{
                        fontWeight: "700",
                        margin: "0",
                        height: "2.3rem",
                        borderRadius: "8px",
                        border: "1px solid #E91F63",
                        fontSize: "0.8rem",
                      }}
                      onClick={openTree}
                      disabled={isUploadDisabled}
                    >
                      UPLOAD CUSTOM TAXONOMY FILE
                    </Button>
                  </div>
                )}
              </div>
            )}
            {treeUploaded.uploaded && (
              <div>
                <div
                  style={{
                    margin: "5vh 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FileDoneOutlined
                    style={{ marginRight: "0.5rem", fontSize: "2rem" }}
                  />{" "}
                  {treeUploaded.file_name}{" "}
                  <CloseOutlined
                    onClick={handleTreeReset}
                    style={{
                      marginLeft: "0.5rem",
                      fontWeight: "500",
                      color: "red",
                      fontSize: "0.5rem",
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div style={{ marginTop: "2%" }}>
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Product Title
              </Form.Label>
              <Form.Control
                isInvalid={!isValid.productTitle}
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                type="text"
                placeholder="Pink shoes"
                value={productInfo.productName}
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    productTitle: e.target.value,
                  })
                }
              />
              <Form.Control.Feedback
                type="invalid"
                tooltip={true}
                as={() => <div></div>}
              ></Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group style={{ marginBottom: "4%" }}>
                            <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400 }}>Product Categories</Form.Label>
                            <Form.Control isInvalid={!isValid.productCategories} style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }} type="text" placeholder="Pink shoes" value={productInfo.productCategories} onChange={(e) => setProductInfo({ ...productInfo, productCategories: e.target.value })} />
                            <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback>
                        </Form.Group> */}{" "}
            {/* @Pranav why is this commented*/}{" "}
            {/* Pranav:I dunno, the tooltips messup the alignment. This can be added back easily. Will add this later...*/}
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{
                  fontWeight: 400,
                  color: "#7B809A",
                  marginTop: "0.5vh",
                }}
              >
                Product Description
              </Form.Label>
              <Form.Control
                isInvalid={!isValid.productDescription}
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                as="textarea"
                rows="2"
                placeholder="A pair of pink mules upper material: synthetic cushioned footbed Textured and patterned outsole"
                value={productInfo.productDescription}
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    productDescription: e.target.value,
                  })
                }
              />
              <Form.Control.Feedback
                type="invalid"
                tooltip={true}
                as={() => <div></div>}
              ></Form.Control.Feedback>
            </Form.Group>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="danger"
                type="submit"
                onClick={handleSubmit}
                style={{
                  fontWeight: "700",
                  width: "100px",
                  height: "35px",
                  borderRadius: "8px",
                  marginTop: "1vh",
                }}
              >
                Generate
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SingleRunDynamic;

import { Descriptions } from "antd";


const CSVInfo = (props) => {
    const props_from = props.fromUniversal
    props = props.info;
    // const products = props.data.products.length
    return (
        props_from === undefined ?
        <Descriptions bordered>
            <Descriptions.Item label="Shop Url" span={2}>{props.domain_name}</Descriptions.Item>
            {/* <Descriptions.Item label="Run ID">{csvId}</Descriptions.Item> */}
            <Descriptions.Item label="Status">{props.status_code}</Descriptions.Item>
            <Descriptions.Item label="Run Time" span={2}>{new Date(props.created_at).toString().split('GMT')[0]}</Descriptions.Item>
            <Descriptions.Item label="Products">{props.product_length}</Descriptions.Item>
            <Descriptions.Item label="Shop Platform">Shopify</Descriptions.Item>
        </Descriptions>
        :
        <Descriptions bordered>
            <Descriptions.Item label="Search Term" span={2}>{props.search_term}</Descriptions.Item>
            {/* <Descriptions.Item label="Run ID">{csvId}</Descriptions.Item> */}
            <Descriptions.Item label="Status">{props.status_code}</Descriptions.Item>
            <Descriptions.Item label="Search Time" span={2}>{new Date(props.created_at).toString().split('GMT')[0]}</Descriptions.Item>
            <Descriptions.Item label="Products">{props.product_length}</Descriptions.Item>
        </Descriptions>
    );

}

export default CSVInfo;
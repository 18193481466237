import {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Table, Button, Skeleton, Modal } from "antd";
import {Card} from 'react-bootstrap';
import axios from "../auth/Axios";
import ShopInfo from "../components/ShopInfo";


const UniversalScrapeInfo = (props) => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [searchInfo, setSearchInfo] = useState({
        status_code :'' ,
        search_term : '',
        created_at : '',
        product_length : ''
    })
    const [searchData, setSearchdata] = useState([])
    const [reRender, setReRender] = useState(0)
    const [showImageModal, setShowImageModal] = useState(false)
    const [imageLink, setImageLink] = useState({
        title:"",
        link:""
    })
    
    const model_id = params.model_id;
    // setCsvInfo(location.state?.csv)

    useEffect(()=> {

        const fetchData = () => {
            axios.get(`universal-scraper`, {
                params: {
                    model_id:model_id
                }
            })
            .then((res)=> {
                console.log(res.data)
                console.log(res.data.scraped_data[0][0])
                // setShopInfo(res.data.scraped_data[0])
                setSearchdata(res.data.scraped_data[0][0].data.shopping_results)

                setSearchInfo( (prev) => { return {
                        status_code : res.data.scraped_data[0][0].status_code,
                        search_term : res.data.scraped_data[0][0].search_term,
                        created_at : res.data.scraped_data[0][0].created_at,
                        product_length : res.data.scraped_data[0][0].data.shopping_results.length
                }})
                setIsLoading(false)
            })
            .catch((err)=> {
                console.log("error occured")
                console.log(err)
            })
        }

        fetchData();

        let timeout1 = setTimeout(() => {
            console.log("timeout set")
            setReRender((prev)=> prev+=1)
        }, 6000);

        if(searchData.length !== 0) {
            console.log("timeout cleared")
            clearTimeout(timeout1)
        }

    },[model_id,reRender])


    const handleDownload = () => {

        axios.get(`download-universal-scrapped`, {
            params: {
                model_id:model_id
            }
        })
        .then((resp) => {
            const downloadData = resp.data
            const url = window.URL.createObjectURL(new Blob([downloadData]));
            const link = document.createElement('a'); //hide this link
            link.href = url;
            link.setAttribute('download', 'scraped_' + searchInfo.search_term + '.csv'); //or any other extension
            link.setAttribute('hidden', true);
            document.body.appendChild(link);
            link.click();
        })

        .catch((err)=> {
            alert("some error occured")
            console.log("due to error")
        })
    }

    const handleClick = (img, title) => {
        setImageLink((prev)=> {
            return {
                title:title,
                link:img
            }
        })
        setShowImageModal(true)
    }

    const columns = [
        {
          title: 'Title',
          // dataIndex: 'url',
          render: (text, record) => (
            <div>
              <span>
                 {record.title.length > 60 ? record.title.substring(0, 60) + "...." : record.title}
                </span>
            </div>
          ),
        },

        {
            title: 'Merchant',
            // dataIndex: 'url',
            render: (text, record) => (
              <div>
                <span>
                   {record.merchant}
                  </span>
              </div>
            ),
          },
          
          {
            title: 'Price',
            // dataIndex: 'url',
            render: (text, record) => (
              <div>
                <span>
                   {record.price_raw}
                  </span>
              </div>
            ),
          },

          {
            title: 'Snippet',
            // dataIndex: 'url',
            render: (text, record) => (
              <div>
                <span>
                   {record.snippet.length > 60 ? record.snippet.substring(0, 40) + "...." : record.snippet}
                  </span>
              </div>
            ),
          },
          
          {
            title: 'Link',
            // dataIndex: 'url',
            render: (text, record) => (
              <div>
                <span>
                    <a href={record.link} rel="noreferrer" target="_blank" >Visit</a>
                </span>
              </div>
            ),
          },

          {
            title: 'Image URL',
            // dataIndex: 'url',
            render: (text, record) => (
              <div>
                <span>
                <Button className = "custom-hover" variant="outline-danger" style={{
                    // backgroundColor: '#dc3545',
                    fontWeight: "700",
                    color:"#dc3545",
                    border: '1px solid #E91F63',
                    }} size='small' onClick={() => {
                    handleClick(record.image, record.title.substring(0, 50))
                    }}
                    >View Image</Button>
                  </span>
              </div>
            ),
          },
      ];

    return (
        <>
            <div>
            <Modal title={imageLink.title} open = {showImageModal} onCancel = {()=> {setShowImageModal(false)}} footer={null} centered={true} width='30vw'>
                <center>
                    <div>
                        <img src={imageLink.link} alt="Product" srcSet="" style={{height:"100%" , width:"100%"}}  />
                    </div>
                </center>
            </Modal>

            <Row>
                <Col span={24}>
                <Card style={{height:"35vh", borderRadius:"12px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.2)"}}>
                <Card.Body>
                    <Card.Title>
                        Search Info
                    </Card.Title>
                    <Card.Subtitle className="mb-4 text-muted">
                    Information about the searched term.
                    </Card.Subtitle>
                {isLoading ? <Skeleton/> : <ShopInfo info={searchInfo} fromUniversal={true}/>}
                </Card.Body>
                </Card>
                </Col>
            </Row>
            
            <Row style={{marginTop:"2%"}}>
                <Col span={24}>
                <Card style={{height:"65vh", borderRadius:"12px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.2)"}}>
                <Card.Body>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <Card.Title>
                        Searched Products
                    </Card.Title>
                    {
                        isLoading ?  null :
                        <Button className="custom-hover" variant="outline-danger" style={{
                        fontWeight: "700",
                        color:"#dc3545",
                        border: '1px solid #E91F63',
                        }}
                        onClick={handleDownload}
                        size='medium'>Download Result CSV</Button>
                    }
                </div>
                    <Card.Subtitle className="mb-4 text-muted">
                    Products returned from the provided search term. <br/>
                    <small class="text-muted">Only some products are shown, please download the CSV for a complete list of products.</small>
                    </Card.Subtitle>
                    <Table locale={{emptyText: isLoading? "Processing..." : "No data"}} columns={columns} dataSource={searchData} loading={isLoading} pagination={{position: ['bottomLeft'], pageSize:5, size:"small", simple:true}}/>
                    </Card.Body>
                </Card>
                </Col>
            </Row>
        </div>
        </>
    );
}

export default UniversalScrapeInfo;
import React, { useEffect } from "react";

import { Row, Col } from 'antd';
import { useDispatch } from "react-redux";
import { updateBreadcrumb } from "../actions/actions";
import UniScraper from '../components/UniScraper';
import PreviousUniversalScraped from '../components/PreviousUniversalScraped';

const UniversalScraper = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateBreadcrumb(['Ecommerce Utilities', 'Ecommerce Scraper']));
    }
        , [dispatch]);
    return (
        <div>
            <Row gutter={24}>
                <Col span={12}>
                    <UniScraper />
                </Col>
            </Row>

            <Row style={{ marginTop: '2%' }}>
                <Col span={24}>
                    <PreviousUniversalScraped />
                </Col>
            </Row>
        </div>
    );
}

export default UniversalScraper;
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  InfoCircleOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Modal, Spin, Row, Select, Typography, Tooltip } from "antd";
import csv_logo from "../assets/csv_logo.png";
import axios from "../auth/Axios";
import { download } from "./utils";
import Papa from "papaparse";

function StaticUploadArea({ image_similarity }) {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [columns, setColumns] = useState([]);
  const [defaultVal, setDefaultVal] = useState({
    default1: "",
    default2: "",
  });
  var file = null;
  const [modelDropdown, setModelDropdown] = useState([]);
  const [modelId, setModelId] = useState(null);

  const { Text } = Typography;
  useEffect(() => {
    const getImageSimilarityModel = () => {
      axios
        .get("user/get-similarity-models?for_image=True")
        .then((res) => {
          let modelList = res.data.models;
          modelList = modelList.map((item) => {
            return {
              value: item["model_id"],
              label: item["model_name"],
            };
          });
          setModelDropdown(modelList);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getImageSimilarityModel();
  }, []);
  const handleChange = (key, val) => {
    if (key === "def1") {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default1: val,
        };
      });
    } else {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default2: val,
        };
      });
    }
  };

  const createSelect = (item) => {
    if (item !== "") {
      setColumns((prev) => {
        return [...prev, { value: item, label: item }];
      });
    }

    if (
      defaultVal.default1 === "" &&
      (String(item).includes("image1") || String(item).includes("1 url"))
    ) {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default1: item,
        };
      });
    }

    if (
      defaultVal.default2 === "" &&
      (String(item).includes("image2") || String(item).includes("2 url"))
    ) {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default2: item,
        };
      });
    }
  };

  const parseFile = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const cols = results.meta.fields;
        cols.forEach(createSelect);
      },
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      alert("You can only upload one file at a time.");
    } else if (acceptedFiles[0].path.split(".").pop() !== "csv") {
      alert("You can only upload a csv file.");
    } else {
      setCsvFile(acceptedFiles[0]);
      file = acceptedFiles[0];
      setIsAdded(true);

      parseFile(file);
    }
  }, []);

  const uploadFiles = () => {
    if (csvFile == null) {
      return;
    }

    if (
      csvFile !== null &&
      (defaultVal.default1 === "" || defaultVal.default2 === "")
    ) {
      Modal.info({
        title: "Info",
        content: "Please select both image1 url and image2 url columns.",
      });
      return;
    }

    var formData = new FormData();
    // upload file
    formData.append("file", csvFile);
    formData.append("csv_cols", Object.values(defaultVal));
    if (modelId != null) {
      formData.append("model_id", modelId);
    }

    setIsUploading(true);
    axios
      .post("/tasks/batch-generate-image-similarity", formData)
      .then(function (response) {
        navigate(`/image-similarity-run-info/${response.data.task_id}`);
      })
      .catch(function (error) {
        setIsUploading(false);
        Modal.error({
          title: "Error",
          content:
            error.response?.data?.message ||
            "Something went wrong. Please try again.",
        });
      });
  };
  const {
    getRootProps,
    getInputProps,
    open: openCsv,
    isDragActive,
  } = useDropzone({ noClick: true, onDrop: onDrop });

  const handleCsvReset = (e) => {
    // prevent default
    e.stopPropagation();
    setCsvFile(null);
    setIsAdded(null);
    setColumns([]);
    setDefaultVal({
      default1: "",
      default2: "",
    });
  };

  return (
    <div>
      <Card
        style={{
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* <Card.Header as="h5">Featured</Card.Header> */}
        <Card.Body>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Card.Title
                style={{
                  fontWeight: "700",
                  fontSize: "24px",
                  color: "#344767",
                }}
              >
                Batch Run
              </Card.Title>
              {/* <img src={i_logo} style={{ width: "2rem" }} /> */}
              <Tooltip
                placement="top"
                title={
                  "Use this to generate image similarity score for multiple images . Upload csv file with image1 url and image2 url as columns "
                }
              >
                {" "}
                <InfoCircleOutlined
                  style={{ fontSize: "1.5rem", color: "#7B809A" }}
                />
              </Tooltip>
            </div>
            <Card.Subtitle
              className="mb-2 text-muted"
              style={{ color: "#7B809A", fontWeight: "400" }}
            >
              Generate image similarity score for multiple images
            </Card.Subtitle>
          </div>
          {isUploading ? (
            <>
              <Row
                justify="center"
                style={{
                  paddingTop: "25%",
                }}
              >
                <Spin />
              </Row>
              <Row justify="center">
                We are processing your files, please wait
              </Row>
            </>
          ) : (
            <div
              {...getRootProps()}
              style={{
                marginTop: "4%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "70%",
                backgroundColor: "#F8F9FA",
                border: "1px solid #C7CCD0",
                borderRadius: "12px",
              }}
            >
              <input {...getInputProps()} />

              {isAdded ? (
                <div>
                  <div
                    style={{
                      margin: "4vh 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FileDoneOutlined
                      style={{ fontSize: "2rem", marginRight: "0.5rem" }}
                    />{" "}
                    {csvFile.name}{" "}
                    <CloseOutlined
                      onClick={handleCsvReset}
                      style={{
                        marginLeft: "0.5rem",
                        fontWeight: "500",
                        color: "red",
                        fontSize: "0.5rem",
                      }}
                    />
                  </div>

                  <div
                    className="csv_mapper"
                    style={{ display: "flex", gap: "2rem" }}
                  >
                    <span>
                      <p className="text_border"> Image 1 URL </p>
                      <p className="text_border"> Image 2 URL </p>
                    </span>

                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.8rem",
                      }}
                    >
                      <Select
                        // defaultValue={defaultVal.default1}
                        value={
                          defaultVal.default1 === ""
                            ? undefined
                            : defaultVal.default1
                        }
                        placeholder="Select a Column"
                        style={{ width: 120 }}
                        options={columns}
                        onChange={(val) => handleChange("def1", val)}
                      />
                      <Select
                        value={
                          defaultVal.default2 === ""
                            ? undefined
                            : defaultVal.default2
                        }
                        placeholder="Select a Column"
                        style={{ width: 120 }}
                        options={columns}
                        onChange={(value) => handleChange("def2", value)}
                      />
                    </span>
                    <div className="model-id">
                      <Select
                        placeholder="Model"
                        style={{ width: 160 }}
                        options={modelDropdown}
                        size="large"
                        allowClear={true}
                        onChange={(value) => {
                          setModelId(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <img
                    placeholder="csv_logo"
                    src={csv_logo}
                    style={{ marginTop: "10px" }}
                  />
                  <div
                    style={{
                      color: "#7B809A",
                      padding: " 1rem 2rem 1rem 2rem",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    Drag & drop a CSV to generate image similarity score
                    <br /> CSV must contain image1 url and image2 url columns.
                    <button
                      className="download-button"
                      onClick={() => {
                        download(
                          "https://raw.githubusercontent.com/Sirri69/Test/master/truncated_products2.csv",
                          "sample.csv"
                        );
                      }}
                    >
                      Download Sample.
                    </button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outline-danger"
                      type="submit"
                      onClick={openCsv}
                      style={{
                        border: "1px solid #E91F63",
                        fontWeight: "700",
                        margin: "0rem 0rem 1.5rem 1rem",
                        fontSize: "1rem",
                      }}
                    >
                      Upload
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
          <Button
            variant="danger"
            style={{
              fontWeight: "700",
              margin: "0",
              height: "2.3rem",
              marginTop: "2.5%",
              borderRadius: "8px",
              border: "1px solid #E91F63",
              fontSize: "1rem",
            }}
            onClick={uploadFiles}
          >
            Upload & Generate
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default StaticUploadArea;

var initialState = {
    singleRuns: [],
    csvRuns: [],
    last_page_single: 0,
    last_page_csv: 0,
}

export const productSimilarityPreviousRunsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOAD_PRODUCT_SIMILARITY_PREVIOUS_RUNS":
            return action.payload;
        case "UPDATE_PRODUCT_SIMILARITY_SINGLE_RUNS":
            return { ...state, singleRuns: action.payload, last_page_single: action.last_page };
        case "UPDATE_PRODUCT_SIMILARITY_CSV_RUNS":
            return { ...state, csvRuns: action.payload, last_page_csv: action.last_page };
        case "APPEND_PRODUCT_SIMILARITY_SINGLE_RUNS":
            return { ...state, singleRuns: state.singleRuns.concat(action.payload) };
        case "APPEND_PRODUCT_SIMILARITY_CSV_RUNS":
            return { ...state, csvRuns: state.csvRuns.concat(action.payload) };
        case "LOAD_PRODUCT_SIMILARITY_PREVIOUS_RUNS_ERROR":
            return action.payload;
        default:
            return state;
    }
}
import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { Row, Col, Modal} from 'antd';
import { Card } from "react-bootstrap";
import { Gauge } from '@ant-design/plots';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom'

import axios from "../auth/Axios";

const UsageQuota = () => {

  const user = useSelector(state => state.user)

  const [usage, setUsage] = useState({});
  const [quota, setQuota] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    axios.get("/user/get-credits").then((res) => {
      setUsage(res.data.credits)
      setQuota(res.data)
    }
    )
  }
    , []);

  const config = {
    percent: 1 - (usage.left / usage.bought),
    range: {
      color: '#30BF78',
    },
    indicator: null,
    axis: {
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      title: {
        offsetY: -50,
        style: {
          fontSize: '24px',
          color: '#4B535E',
          display: 'hidden',
        },
        customHtml: () => (<><div style={{ fontSize: '75%', marginBottom: '8%', marginTop: '12%' }}>{parseFloat(usage.spent).toFixed(2)}/{usage.bought}</div> <div style={{ fontSize: '80%', marginTop: '-12%', color: '#7B809A' }}> requests </div></>),
      },
    }
  };

  const createCreditSession = () => {

    // if (user.plan == "FREE"){
    //   setIsModalVisible(true)
    // }
    // else{
      axios.get("/payment/get-session").then((res)=>{
        window.location.href = res.data.session_url;
    })
  // }
  }

  const createCheckout = (plan="STARTER") => {
    axios.get("/payment/get-checkout-session", {params: {plan:plan}}).then((res)=>{
      console.log(res)
      window.location.href = res.data.session_url;
    })
  }

  return (
    <div>
      {/* {JSON.stringify(usage)} {(usage.left/usage.bought)*100} */}
      <div>
        <Modal title="Free plan only has access to 50 credits/month. Please subscribe to one of the paid plans to buy more credits." visible={isModalVisible} footer={null}
        centered={true}
        closable={true}
        destroyOnClose={true}
        onCancel={()=>setIsModalVisible(false)}
        width="65vw"
        maskStyle={{
          backgroundColor: 'rgba(15,15,15,0.85)',
          backdropFilter: "blur(2px)"
        }}>
          <div style={{paddintTop:'2%'}}>
            <Row wrap={false}>
              <Col span={6} style={{textAlign:'center', borderRight:'2px solid gray'}}>
                <h4>Features</h4>
                <p style={{fontWeight:'400', color:'#344767', lineHeight:'250%'}}>
                Setup Fee<br/>
                Monthly Fixed Cost<br/>
                Fine-Tuned Model <br/>
                Multiple Fine-Tuned Models<br/>
                Industry High Accuracy<br/>
                Store Taxonomy Trees<br/>
                Use Trees or Category Lists<br/>
                Access To All 3 Models<br/>
                Access To Google Taxonomy<br/>
                Extra customization<br/>
                Default Credits Per Month<br/>
                Additional Credits<br/>
                </p>
              </Col>
              <Col span={6} style={{textAlign:'center', borderRight:'2px solid gray'}}>
                <h4>Starter</h4>
                <p style={{fontWeight:'600', color:'#344767', lineHeight:'250%'}}>
                $0<br/>
                Starting at $197<br/>
                ❌ <br/>
                ❌<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ❌<br/>
                1350<br/>
                $.12 per<br/>
                </p>
                <Button variant="outline-danger" type="submit" style={{
                    fontSize:'90%',
                    fontWeight: "700", width: "120px", height: "40px"}}
                    onClick={()=>{createCheckout()}}
                    >
                    Pay Now
                </Button>
              </Col>
              <Col span={6} style={{textAlign:'center', borderRight:'2px solid gray'}}>
                <h4>Fine-Tuned</h4>
                <p style={{fontWeight:'600', color:'#344767', lineHeight:'250%'}}>
                $3000<br/>
                Starting at $437<br/>
                ✔<br/>
                ❌<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ❌<br/>
                3250<br/>
                $.12 per<br/>
                </p>
                <Button variant="outline-danger" type="submit" style={{
                    fontSize:'80%',
                    fontWeight: "700", width: "120px", height: "40px"}}
                    onClick = { () => {
                      window.open('https://www.pumice.ai/contact-us','_blank');
                    }}
                    >
                    Schedule a Demo
                </Button>
              </Col>
              <Col span={6} style={{textAlign:'center'}}>
                <h4>Enterprise</h4>
                <p style={{fontWeight:'600', color:'#344767', lineHeight:'250%'}}>
                $8000<br/>
                Starting at $497<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                ✔<br/>
                Custom<br/>
                Custom<br/>
                </p>
                <Button variant="outline-danger" type="submit" style={{
                    fontSize:'80%',
                    fontWeight: "700", width: "120px", height: "40px"}}
                    onClick = { () => {
                      window.open('https://www.pumice.ai/contact-us','_blank');
                    }}
                    >
                    Schedule a Demo
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        </div>
      <Card style={{display:'block', borderRadius: "12px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
        <Card.Body>
          <Card.Title style={{ color: "#344767", fontWeight: "700", paddingBottom: '2%' }}>
            Usage Quota
          </Card.Title>
          {/* <Card.Subtitle className="mb-4 text-muted"> Some Subtitle </Card.Subtitle> */}
          <div>
            <Row>
              <Col span={8}>
                {/* not render gauge if screen width is less than 865px */}
                {window.innerWidth > 865 ?
                  <Gauge style={{ height: '70%', width: '100%' }} {...config} />
                  : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div style={{ fontSize: '85%', marginBottom: '8%', marginTop: '12%' }}>{parseFloat(usage.spent).toFixed(2)}/{usage.bought}</div>
                      <div style={{ fontSize: '80%', marginTop: '-12%', color: '#7B809A' }}> requests </div>
                    </div>
                  )}
                <Button variant="outline-danger" style={{
                  marginLeft: window.innerWidth > 865 ? '0.6vw' : '1vw',
                  border: "1px solid #E91F63",
                  fontWeight: "700", fontSize: "0.7rem", marginTop: "1rem"
                }}
                  onClick={() => createCreditSession()}>
                  Buy Credits/Upgrade Account
                </Button>
              </Col>
              <Col span={16} style={{
                // align vertically
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <div>
                  <div>
                    <span
                      style={
                        {
                          fontWeight: "600", fontSize: "14px", color: "#344767", marginRight: '20%'
                        }}>
                      CSVs Uploaded:</span> {quota.csvs_uploaded}
                  </div>
                  <hr
                    style={{
                      width: '110%',
                      border: '1px solid #C7CCD0',
                    }}
                  />
                  {/* <br/> */}
                  <div>
                    <span
                      style={{ fontWeight: "600", fontSize: "14px", color: "#344767" }}>
                      Products Categorized:</span> {quota.products_categorized}
                  </div>
                  <hr
                    style={{
                      width: '110%',
                      border: '1px solid #C7CCD0',
                    }}
                  />
                  <div>
                    <span
                      style={{ fontWeight: "600", fontSize: "14px", color: "#344767" }}>
                      Similarity Runs:</span> {quota.similarity_runs}
                  </div>

                </div>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default UsageQuota;
import axios from "../auth/Axios";

export const loadImageSimilaritySingleRuns = (pages = 5, offset = 0) => {
  return async (dispatch) => {
    var runs = [];
    let emp = -1;
    try {
      for (let i = 1 + offset; i <= pages; i++) {
        const response = await axios.get(
          `/user/get-image-similarity-runs/${i}`
        );

        if (response.data.result.length === 0) {
          emp = i;
          break;
        }
        runs = runs.concat(response.data.result);
      }
      if (emp == -1) emp = pages;
      dispatch({
        type: "UPDATE_IMAGE_SIMILARITY_SINGLE_RUNS",
        payload: runs,
        last_page: emp,
      });
    } catch (error) {
      dispatch({
        type: "LOAD_IMAGESIMILARITY_PREVIOUS_RUNS_ERROR",
        payload: error,
      });
    }
  };
};

export const loadImageSimilarityCSVRuns = (pages = 5, offset = 0) => {
  return async (dispatch) => {
    var runs = [];
    let emp = -1;
    try {
      for (let i = 1 + offset; i <= pages; i++) {
        const response = await axios.get(
          `/user/get-image-similarity-runs/${i}?batch=True`
        );
        if (response.data.result.length === 0) {
          emp = i;
          break;
        }
        runs = runs.concat(response.data.result);
      }
      if (emp == -1) emp = pages;
      dispatch({
        type: "UPDATE_IMAGE_SIMILARITY_CSV_RUNS",
        payload: runs,
        last_page: emp,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "LOAD_IMAGE_SIMILARITY_PREVIOUS_RUNS_ERROR",
        payload: error,
      });
    }
  };
};

export function appendImageSimilaritySingleRuns(
  start = 1,
  end = 5,
  previousRuns
) {
  return async (dispatch) => {
    var runs = [];
    try {
      for (let i = start; i <= end; i++) {
        const response = await axios.get(
          `/user/get-image-similarity-runs/${i}`
        );
        runs = runs.concat(response.data.result);
      }
      // check if a run already exists in the store by id and append only new runs
      runs = runs.filter((run) => !previousRuns.some((r) => r.id === run.id));

      dispatch({
        type: "APPEND_IMAGE_SIMILARITY_SINGLE_RUNS",
        payload: runs,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "LOAD_IMAGE_SIMILARITY_PREVIOUS_RUNS_ERROR",
        payload: error,
      });
    }
  };
}

export const appendImageSimilarityCSVRuns = (
  start = 1,
  end = 5,
  previousRuns
) => {
  return async (dispatch) => {
    var runs = [];
    try {
      for (let i = start; i <= end; i++) {
        const response = await axios.get(
          `/user/get-image-similarity-runs/${i}?batch=True`
        );
        runs = runs.concat(response.data.result);
      }
      runs = runs.filter((run) => !previousRuns.some((r) => r.id === run.id));
      dispatch({
        type: "APPEND_IMAGE_SIMILARITY_CSV_RUNS",
        payload: runs,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "LOAD_IMAGE_SIMILARITY_PREVIOUS_RUNS_ERROR",
        payload: error,
      });
    }
  };
};

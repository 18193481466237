import axios from "../auth/Axios";
import { useSelector } from "react-redux";

export const loadSingleRuns = (pages = 5, offset = 0) => {
    return async dispatch => {
        var runs = []
        let emp = -1
        try {
            for (let i = 1 + offset; i <= pages; i++) {
                const response = await axios.get(`/user/get-previous-single-runs/${i}`);
                if (response.data.runs.length === 0) {
                    emp = i
                    break;
                }
                runs = runs.concat(response.data.runs);
            }
            if (emp == -1) emp = pages
            dispatch({
                type: "UPDATE_SINGLE_RUNS",
                payload: runs,
                last_page: emp
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PREVIOUS_RUNS_ERROR",
                payload: error
            });
        }
    }
};


export const loadCSVRuns = (pages = 5, offset = 0) => {
    return async dispatch => {
        var runs = []
        let emp = -1
        try {
            for (let i = 1 + offset; i <= pages; i++) {
                const response = await axios.get(`/user/get-csvs/${i}`);
                if (response.data.csvs.length === 0) {
                    emp = i
                    break;
                }
                runs = runs.concat(response.data.csvs);
            }
            if (emp == -1) emp = pages
            dispatch({
                type: "UPDATE_CSV_RUNS",
                payload: runs,
                last_page: emp
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PREVIOUS_RUNS_ERROR",
                payload: error
            });
        }
    }
}


export function appendSingleRuns(start = 1, end = 5, previousRuns) {
    return async dispatch => {
        var runs = []
        try {
            for (let i = start; i <= end; i++) {
                const response = await axios.get(`/user/get-previous-single-runs/${i}`);
                runs = runs.concat(response.data.runs);
            }
            // check if a run already exists in the store by id and append only new runs
            runs = runs.filter(run => !previousRuns.some(r => r.id === run.id));

            dispatch({
                type: "APPEND_SINGLE_RUNS",
                payload: runs
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PREVIOUS_RUNS_ERROR",
                payload: error
            });
        }
    }
};


export const appendCSVRuns = (start = 1, end = 5, previousRuns) => {
    return async dispatch => {
        var runs = []
        try {
            for (let i = start; i <= end; i++) {
                const response = await axios.get(`/user/get-csvs/${i}`);
                runs = runs.concat(response.data.csvs);
            }
            runs = runs.filter(run => !previousRuns.some(r => r.id === run.id));
            dispatch({
                type: "APPEND_CSV_RUNS",
                payload: runs
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PREVIOUS_RUNS_ERROR",
                payload: error
            });
        }
    }
};


export const loadPreviousScrapped = (pages = 3, offset = 0) => {
    return async dispatch => {
        let runs = []
        let emp = -1
        try {
            for (let i = 1 + offset; i <= pages; i++) {
                const response = await axios.get(`get-all-scrapped`, {
                    params: {
                        page: i
                    }});
                if (response.data.scrapped_data.length === 0) {
                    emp = i
                    break;
                }
                runs = runs.concat(response.data.scrapped_data);
                console.log("runs areee", runs)
            }
            if (emp == -1) emp = pages
            dispatch({
                type: "UPDATE_PREVIOUS_SCRAPED",
                payload: runs,
                last_page: emp
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PREVIOUS_SCRAPED_ERROR",
                payload: error
            });
        }

    }
}


export const appendPreviousScraped = (start = 1, end = 3, previousScraped) => {
    return async dispatch => {
        let runs = []
        try {
            for (let i = start; i <= end; i++) {
                const response = await axios.get(`get-all-scrapped`, {
                    params: {
                        page: i
                    }});;
                runs = runs.concat(response.data.scrapped_data);
            }
            // check if a run already exists in the store by id and append only new runs
            runs = runs.filter(run => !previousScraped.some(r => r.id === run.id));

            dispatch({
                type: "APPEND_PREVIOUS_SCRAPED",
                payload: runs
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PREVIOUS_SCRAPED_ERROR",
                payload: error
            });
        }
    }
}

export const loadPreviousUniversalScrapped = (pages = 3, offset = 0) => {
    return async dispatch => {
        let runs = []
        let emp = -1
        try {
            for (let i = 1 + offset; i <= pages; i++) {
                const response = await axios.get(`universal-scraper`, {
                    params: {
                        page: i
                    }});
                if (response.data.scraped_data.length === 0) {
                    emp = i
                    break;
                }
                runs = runs.concat(response.data.scraped_data);
            }
            if (emp == -1) emp = pages
            dispatch({
                type: "UPDATE_PREVIOUS_UNIVERSAL_SCRAPED",
                payload: runs,
                last_page: emp
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PREVIOUS_UNIVERSAL_SCRAPED_ERROR",
                payload: error
            });
        }

    }
}


export const appendPreviousUniversalScraped = (start = 1, end = 3, previousUniversalScraped) => {
    return async dispatch => {
        let runs = []
        try {
            for (let i = start; i <= end; i++) {
                const response = await axios.get(`universal-scraper`, {
                    params: {
                        page: i
                    }});;
                runs = runs.concat(response.data.scraped_data);
            }
            // check if a run already exists in the store by id and append only new runs
            runs = runs.filter(run => !previousUniversalScraped.some(r => r.id === run.id));

            dispatch({
                type: "APPEND_PREVIOUS_UNIVERSAL_SCRAPED",
                payload: runs
            });
        }

        catch (error) {
            console.log(error);
            dispatch({
                type: "LOAD_PREVIOUS_UNIVERSAL_SCRAPED_ERROR",
                payload: error
            });
        }
    }
}



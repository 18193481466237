import axios from "../auth/Axios";

export const download = async(url,filename) => {
    await axios.get('user/download-sample-files', { params: {
        url:url
    }})
      
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a'); //hide this link
    
        link.href = url;
        link.setAttribute('download',filename); //or any other extension
        link.setAttribute('hidden', true);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => console.log(err));
    }
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  InfoCircleOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Modal, Spin, Row, Select, Typography, Tooltip } from "antd";
import csv_logo from "../assets/csv_logo.png";
import axios from "../auth/Axios";
import { download } from "./utils";
import Papa from "papaparse";
import { ShopModal } from "./ShopModal";

function StaticUploadArea({ image_similarity }) {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [shopName, setShopName] = useState(null);
  const [defaultVal, setDefaultVal] = useState({
    default1: "",
    default2: "",
  });
  var file = null;

  const { Text } = Typography;

  const handleChange = (key, val) => {
    if (key === "def1") {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default1: val,
        };
      });
    } else {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default2: val,
        };
      });
    }
  };

  const createSelect = (item) => {
    if (item !== "") {
      setColumns((prev) => {
        return [...prev, { value: item, label: item }];
      });
    }

    if (
      defaultVal.default1 === "" &&
      (String(item).includes("title") || String(item).includes("Title"))
    ) {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default1: item,
        };
      });
    }

    if (
      defaultVal.default2 === "" &&
      (String(item).includes("Descr") || String(item).includes("descr"))
    ) {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default2: item,
        };
      });
    }
  };

  const parseFile = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const cols = results.meta.fields;
        cols.forEach(createSelect);
      },
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles[0].path.split(".").pop());
    if (acceptedFiles.length > 1) {
      alert("You can only upload one file at a time.");
    } else if (acceptedFiles[0].path.split(".").pop() !== "csv") {
      alert("You can only upload a csv file.");
    } else {
      setCsvFile(acceptedFiles[0]);
      file = acceptedFiles[0];
      setIsAdded(true);

      parseFile(file);
    }
  }, []);

  const uploadFiles = () => {
    if (csvFile == null && shopName == null) {
      return;
    }

    if (
      csvFile !== null &&
      (defaultVal.default1 === "" || defaultVal.default2 === "")
    ) {
      Modal.info({
        title: "Info",
        content: "Please select both Title and Description columns.",
      });
      return;
    }

    var formData = new FormData();
    // upload file
    if (shopName === null) {
      formData.append("file", csvFile);
      formData.append("csv_cols", Object.values(defaultVal));
    } else {
      formData.append("shopName", shopName);
    }
    setIsUploading(true);
    axios
      .post("/tasks/batch-generate-product-categories-static", formData)
      .then(function (response) {
        navigate(`/run-info/${response.data.task_id}`);
      })
      .catch(function (error) {
        setIsUploading(false);
        Modal.error({
          title: "Error",
          content:
            error.response?.data?.message ||
            "Something went wrong. Please try again.",
        });
      });
  };
  // getRootProps: getRootTreeFileProps, getInputProps: getInputTreeFileProps, open: openTree, isDragActive: isTreeDragActive
  const {
    getRootProps,
    getInputProps,
    open: openCsv,
    isDragActive,
  } = useDropzone({ noClick: true, onDrop: onDrop });

  const handleCsvReset = (e) => {
    // prevent default
    e.stopPropagation();
    setCsvFile(null);
    setIsAdded(null);
    setColumns([]);
    setDefaultVal({
      default1: "",
      default2: "",
    });
  };

  const addShopModal = () => {
    setShowModal(true);
  };

  const updateShopName = (name) => {
    setShopName(name);
    setShowModal(false);
  };

  const handleShopReset = () => {
    setShopName(null);
  };

  return (
    <div>
      {showModal && (
        <Modal
          title={<Text style={{ fontSize: "20px" }}>Import From Shop</Text>}
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
          width="60vw"
        >
          <ShopModal updateShopName={updateShopName} />
        </Modal>
      )}
      <Card
        style={{
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* <Card.Header as="h5">Featured</Card.Header> */}
        <Card.Body>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Card.Title
                style={{
                  fontWeight: "700",
                  fontSize: "24px",
                  color: "#344767",
                }}
              >
                Batch Run
              </Card.Title>
              {/* <img src={i_logo} style={{ width: "2rem" }} /> */}
              <Tooltip
                placement="top"
                title={
                  "Use this to generate static categorization for multiple products . Upload csv file with title and description as columns "
                }
              >
                {" "}
                <InfoCircleOutlined
                  style={{ fontSize: "1.5rem", color: "#7B809A" }}
                />
              </Tooltip>
            </div>
            <Card.Subtitle
              className="mb-2 text-muted"
              style={{ color: "#7B809A", fontWeight: "400" }}
            >
              Generate tags & product categories for multiple items.
            </Card.Subtitle>
          </div>
          {isUploading ? (
            <>
              <Row
                justify="center"
                style={{
                  paddingTop: "25%",
                }}
              >
                <Spin />
              </Row>
              <Row justify="center">
                We are processing your files, please wait
              </Row>
            </>
          ) : (
            <div
              {...getRootProps()}
              style={{
                marginTop: "4%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "70%",
                backgroundColor: "#F8F9FA",
                border: "1px solid #C7CCD0",
                borderRadius: "12px",
              }}
            >
              <input {...getInputProps()} />
              {isAdded ? (
                <div>
                  <div
                    style={{
                      margin: "4vh 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FileDoneOutlined
                      style={{ fontSize: "2rem", marginRight: "0.5rem" }}
                    />{" "}
                    {csvFile.name}{" "}
                    <CloseOutlined
                      onClick={handleCsvReset}
                      style={{
                        marginLeft: "0.5rem",
                        fontWeight: "500",
                        color: "red",
                        fontSize: "0.5rem",
                      }}
                    />
                  </div>

                  <div
                    className="csv_mapper"
                    style={{ display: "flex", gap: "2rem" }}
                  >
                    <span>
                      <p className="text_border"> Product Title </p>
                      <p className="text_border"> Product Description </p>
                    </span>

                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.8rem",
                      }}
                    >
                      <Select
                        // defaultValue={defaultVal.default1}
                        value={
                          defaultVal.default1 === ""
                            ? undefined
                            : defaultVal.default1
                        }
                        placeholder="Select a Column"
                        style={{ width: 120 }}
                        options={columns}
                        onChange={(val) => handleChange("def1", val)}
                      />
                      <Select
                        value={
                          defaultVal.default2 === ""
                            ? undefined
                            : defaultVal.default2
                        }
                        placeholder="Select a Column"
                        style={{ width: 120 }}
                        options={columns}
                        onChange={(value) => handleChange("def2", value)}
                      />
                    </span>
                  </div>
                </div>
              ) : shopName !== null ? (
                <div
                  style={{
                    margin: "6vh 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FileDoneOutlined
                    style={{ fontSize: "2rem", marginRight: "0.5rem" }}
                  />{" "}
                  {shopName}{" "}
                  <CloseOutlined
                    onClick={handleShopReset}
                    style={{
                      marginLeft: "0.5rem",
                      fontWeight: "500",
                      color: "red",
                      fontSize: "0.5rem",
                    }}
                  />
                </div>
              ) : (
                <>
                  <img
                    placeholder="csv_logo"
                    src={csv_logo}
                    style={{ marginTop: "10px" }}
                  />
                  <div
                    style={{
                      color: "#7B809A",
                      padding: " 1rem 2rem 1rem 2rem",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    Drag & drop a CSV to generate the product categories and
                    tags.
                    <br /> CSV must contain 'title' and 'description' columns.
                    <br /> You can also import products directly from your
                    Shopify store.
                    <button
                      className="download-button"
                      onClick={() => {
                        download(
                          "https://raw.githubusercontent.com/Sirri69/Test/master/truncated_products2.csv",
                          "sample.csv"
                        );
                      }}
                    >
                      Download Sample.
                    </button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outline-danger"
                      type="submit"
                      onClick={openCsv}
                      style={{
                        border: "1px solid #E91F63",
                        fontWeight: "700",
                        margin: "0rem 0rem 1.5rem 1rem",
                        fontSize: "1rem",
                      }}
                    >
                      Upload
                    </Button>
                    <div
                      style={{
                        fontSize: "2rem",
                        margin: "0 1rem",
                        color: "gray",
                        height: "5rem",
                      }}
                    >
                      |
                    </div>
                    <p className="shop_text" onClick={addShopModal}>
                      Import Products From Shopify
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
          <Button
            variant="danger"
            style={{
              fontWeight: "700",
              margin: "0",
              height: "2.3rem",
              marginTop: "2.5%",
              borderRadius: "8px",
              border: "1px solid #E91F63",
              fontSize: "1rem",
            }}
            onClick={uploadFiles}
          >
            Upload & Generate
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default StaticUploadArea;

import React, { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import axios from "../auth/Axios";
import i_logo from "../assets/information.png";
import ImageInfo from "../components/ImageInfo";
const SingleRun = (props) => {
  const [image1Url, setImage1Url] = React.useState("");
  const [image2Url, setImage2Url] = React.useState("");
  const [valid, setValid] = useState({ image1: true, image2: true });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    setValid((prev) => ({ ...prev, image1: image1Url == "" ? false : true }));
    setValid((prev) => ({ ...prev, image2: image2Url == "" ? false : true }));

    if (image1Url != "" && image2Url != "") {
      setIsModalVisible(true);
      setLoading(true);
      axios
        .post("product-categorization/get-image-similarity", {
          image1_url: image1Url,
          image2_url: image2Url,
        })
        .then((res) => {
          if (res.status === 200) {
            setResponse(res.data);
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsModalVisible(false);
          setLoading(false);
          Modal.error({
            title: "Error",
            content:
              error.response?.data?.message ||
              "Something went wrong. Please try again.",
          });
        });
    }
  };
  const onCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        title="Response"
        visible={isModalVisible}
        onCancel={onCancel}
        footer={null}
        width="60vw"
        centered={true}
      >
        <ImageInfo
          loading={loading}
          productData={{
            resp_data: { similarity_score: response.image_similarity_score },
            req_data: {
              image1url: image1Url,
              image2url: image2Url,
            },
            status_code: 200,
          }}
        />
      </Modal>
      <Card
        style={{
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title
              style={{ fontWeight: "700", fontSize: "24px", color: "#344767" }}
            >
              Single Run
            </Card.Title>
            {/* <img src={i_logo} style={{ width: "2rem" }} /> */}
            <Tooltip
              placement="top"
              title={
                "Enter Image1 url and Image2 url to generate similarity score"
              }
            >
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="mb-2 text-muted"
            style={{ color: "#7B809A", fontWeight: "400" }}
          >
            Enter Image1 url and Image2 url to generate similarity score
          </Card.Subtitle>

          <div style={{ marginTop: "2%" }}>
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Image1 url
              </Form.Label>
              <Form.Control
                isInvalid={!valid.image1}
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                type="text"
                placeholder="https://image1.com"
                value={image1Url}
                onChange={(e) => setImage1Url(e.target.value)}
              />
              <Form.Control.Feedback
                type="invalid"
                tooltip={true}
                as={() => <div></div>}
              ></Form.Control.Feedback>
            </Form.Group>
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Image2 url
              </Form.Label>
              <Form.Control
                isInvalid={!valid.image2}
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                placeholder="https://image2.com"
                value={image2Url}
                onChange={(e) => setImage2Url(e.target.value)}
              />
              <Form.Control.Feedback
                type="invalid"
                tooltip={true}
                as={() => <div></div>}
              ></Form.Control.Feedback>
            </Form.Group>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="danger"
                type="submit"
                onClick={handleSubmit}
                style={{
                  fontWeight: "700",
                  width: "100px",
                  height: "40px",
                  marginTop: "2%",
                }}
              >
                Generate
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SingleRun;

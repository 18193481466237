import { Tag, Descriptions, Skeleton, Image } from "antd";

const ProductInfo = (props) => {
  const reqData = props.productData.req_data;
  const respData = props.productData.resp_data;

  if (props.loading) {
    return (
      <div>
        <Skeleton active />
        <br />
        <Skeleton active />
      </div>
    );
  }

  if (props.productData.status_code != 200) {
    return <div>Some error occured</div>;
  }

  return (
    <div>
      <Descriptions
        title={<div>Image similarity Info</div>}
        bordered
        column={2}
      >
        <Descriptions.Item label="Image 1" span={2}>
          <Image src={reqData.image1url} width={150} height={150} />
        </Descriptions.Item>
        <Descriptions.Item label="Image 1 URL" span={2}>
          <a target="_blank">{reqData.image1url}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Image 2" span={2}>
          <Image src={reqData.image2url} width={150} height={150} />
        </Descriptions.Item>
        <Descriptions.Item label="Image 2 URL" span={2}>
          <a target="_blank">{reqData.image2url}</a>
        </Descriptions.Item>

        <Descriptions.Item label="Similarity Score" span={1}>
          {respData.similarity_score}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default ProductInfo;

import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Card, Button } from "react-bootstrap";
import { Modal, Spin, Row, Cascader, Select, Typography, Tooltip } from "antd";
import {
  InfoCircleOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import csv_logo from "../assets/csv_logo.png";
import axios from "../auth/Axios";
import { useNavigate } from "react-router";
import { download } from "./utils";
import Papa from "papaparse";
import { ShopModal } from "./ShopModal";

function UploadArea(props) {
  // console.log("Props:", props)

  const navigate = useNavigate();

  const [treeUploaded, setTreeUploaded] = useState({
    uploaded: false,
    file_name: "",
    tree_file: null,
  });
  const [csvUploaded, setCsvUploaded] = useState({
    uploaded: false,
    file_name: "",
    csv_file: null,
  });

  const [isUploading, setIsUploading] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [selected_items, setSelectedItems] = useState(null);
  const [columns, setColumns] = useState([]);
  const [defaultVal, setDefaultVal] = useState({
    default1: "",
    default2: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [shopName, setShopName] = useState(null);
  const { Text } = Typography;

  const handleChange = (key, val) => {
    if (key === "def1") {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default1: val,
        };
      });
    } else {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default2: val,
        };
      });
    }
  };

  const createSelect = (item) => {
    if (item !== "") {
      setColumns((prev) => {
        return [...prev, { value: item, label: item }];
      });
    }

    if (
      defaultVal.default1 === "" &&
      (String(item).includes("title") || String(item).includes("Title"))
    ) {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default1: item,
        };
      });
    }

    if (
      defaultVal.default2 === "" &&
      (String(item).includes("Descr") || String(item).includes("descr"))
    ) {
      setDefaultVal((prev) => {
        return {
          ...prev,
          default2: item,
        };
      });
    }
  };

  const parseFile = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        console.log(results.meta.fields);
        const cols = results.meta.fields;
        cols.forEach(createSelect);
      },
    });
  };

  const onSelect = (e) => {
    console.log(e);
    setSelectedItems(e);
    setIsUploadDisabled(true);
    if (e == undefined) {
      setIsUploadDisabled(false);
    }
  };

  useEffect(() => {
    setMenuItems([
      {
        value: "tree",
        label: "Trees",
        disabled: [...props.taxonomyTrees].length === 0,
        children: [...props.taxonomyTrees],
      },
      {
        value: "model",
        label: "Models",
        disabled: [...props.models].length === 0,
        children: [...props.models],
      },
    ]);
  }, [props]);

  var uploadFiles = () => {
    if (
      shopName === null &&
      (defaultVal.default1 === "" || defaultVal.default2 === "")
    ) {
      Modal.info({
        title: "Info",
        content: "Please provide Title and Description columns.",
      });
      return;
    }

    if (
      (treeUploaded.uploaded || selected_items != null) &&
      (csvUploaded.uploaded || shopName !== null)
    ) {
      var formData = new FormData();
      if (treeUploaded.uploaded) {
        formData.append("tree_file", treeUploaded.tree_file);
      }
      if (selected_items != null) {
        formData.append("selected_items", selected_items);
        // console.log(selected_items)/
      }

      if (shopName === null) {
        formData.append("csv_file", csvUploaded.csv_file);
        formData.append("csv_cols", Object.values(defaultVal));
      } else {
        formData.append("shopName", shopName);
      }

      setIsUploading(true);
      axios
        .post("/tasks/batch-generate-product-categories-dynamic", formData)
        .then((resp) => {
          navigate(`/run-info/${resp.data.task_id}`);
        })
        .catch((error) => {
          setIsUploading(false);
          Modal.error({
            title: "Error",
            content:
              error.response?.data?.message ||
              "Something went wrong. Please try again.",
          });
        });
    } else {
      Modal.info({
        title: "Info",
        content: "Please upload both of the files!",
      });
    }
  };

  // -------------------- Different root and input props for both taxonomy and CSV file-------------------------------------
  const {
    getRootProps: getRootCsvFileProps,
    getInputProps: getInputCsvFileProps,
    open: openCsv,
    isDragActive: isCsvDragActive,
  } = useDropzone({
    noClick: true,
    accept: {
      "text/csv": [".csv"],
    },
    onDrop: (acceptedFile) => {
      // do something with the CSV file
      if (acceptedFile[0].path.split(".").pop() === "csv") {
        setCsvUploaded({
          ...csvUploaded,
          uploaded: true,
          file_name: acceptedFile[0].path,
          csv_file: acceptedFile[0],
        });
        parseFile(acceptedFile[0]);
      } else {
        alert("please upload a csv file");
      }
    },
  });

  const {
    getRootProps: getRootTreeFileProps,
    getInputProps: getInputTreeFileProps,
    open: openTree,
    isDragActive: isTreeDragActive,
  } = useDropzone({
    noClick: true,
    accept: {
      "text/plain": [".txt"],
    },
    onDrop: (acceptedFile) => {
      // do something with the tree file
      if (acceptedFile[0].path.split(".").pop() === "txt") {
        setTreeUploaded({
          ...treeUploaded,
          uploaded: true,
          file_name: acceptedFile[0].path,
          tree_file: acceptedFile[0],
        });
      } else {
        alert("please upload a txt file");
      }
    },
  });

  const handleCsvReset = () => {
    setCsvUploaded({
      ...csvUploaded,
      uploaded: false,
      file_name: "",
    });

    setColumns([]);
    setDefaultVal({
      default1: "",
      default2: "",
    });
  };
  const handleTreeReset = () => {
    setTreeUploaded({
      ...treeUploaded,
      uploaded: false,
      file_name: "",
    });
  };

  const displayRender = (labels) => labels[labels.length - 1];

  const addShopModal = () => {
    setShowModal(true);
  };

  const updateShopName = (name) => {
    setShopName(name);
    setShowModal(false);
  };

  const handleShopReset = () => {
    setShopName(null);
  };

  return (
    <div>
      {showModal && (
        <Modal
          title={<Text style={{ fontSize: "20px" }}>Import From Shop</Text>}
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
          width="60vw"
        >
          <ShopModal updateShopName={updateShopName} />
        </Modal>
      )}

      <Card
        style={{
          minHeight: "60vh",
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* <Card.Header as="h5">Featured</Card.Header> */}
        <Card.Body>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Card.Title
                style={{
                  fontWeight: "700",
                  fontSize: "24px",
                  color: "#344767",
                }}
              >
                Batch Run
              </Card.Title>
              {/* <img src={i_logo} style={{ width: "2rem" }} /> */}
              <Tooltip
                placement="top"
                title="Use this to generate dynamic categorization for multiple products . Upload csv file with title and description as columns"
              >
                {" "}
                <InfoCircleOutlined
                  style={{ fontSize: "1.5rem", color: "#7B809A" }}
                />
              </Tooltip>
            </div>
            <Card.Subtitle
              className="mb-2 text-muted"
              style={{ color: "#7B809A", fontWeight: "400" }}
            >
              Categorize multiple product records to a taxonomy or categories
              list
            </Card.Subtitle>
          </div>
          {isUploading ? (
            <>
              <Row
                justify="center"
                style={{
                  paddingTop: "25%",
                }}
              >
                <Spin />
              </Row>
              <Row justify="center">
                We are processing your files, please wait
              </Row>
            </>
          ) : (
            <div
              {...getRootTreeFileProps()}
              style={{
                marginTop: "3%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "26%",
                backgroundColor: "#F8F9FA",
                border: "1px solid #C7CCD0",
                borderRadius: "12px",
              }}
            >
              {!treeUploaded.uploaded && (
                <div style={{ margin: "4vh 1vw 4vh 1vw" }}>
                  <input {...getInputTreeFileProps()} />
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      fontWeight: "400",
                      color: "#7B809A",
                    }}
                  >
                    Upload your custom taxonomy file in the Google Product
                    Taxonomy format or select a pre-trained model already fit to
                    popular taxonomies from the dropdown. Your past uploaded
                    taxonomies are available in the dropdown as well .
                    <button
                      className="download-button"
                      onClick={() => {
                        download(
                          "https://raw.githubusercontent.com/Sirri69/Test/master/basic_taxonomy.txt",
                          "sample-taxonomy.txt"
                        );
                      }}
                    >
                      Download File Example
                    </button>
                    .
                  </div>
                  {!isTreeDragActive && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {/* <Button variant="outline-danger" style={{ marginRight: "1rem" }}>SELECT PRODUCT TAXONOMY</Button> */}
                      {/* <Dropdown overlay={<Menu items={menuItems}/>} placement="bottom">
                                            <Space>
                                                <Button variant="outline" style={{ color: "#7B809A", fontWeight: "400", height: "2.8rem", background: "#FFFFFF", border: "0.7px solid #C7CCD0", borderRadius: "12px" }}>
                                                    Select Product Taxonomy <DownOutlined style={{ color: "#7B809A", fontWeight: "400" }} /> </Button>
                                            </Space>
                                        </Dropdown> */}
                      {/* <Select size='large' placeholder="Select product taxonomy" onChange={onSelect}>
                                            {
                                                menuItems.map((item, idx)=>{
                                                    return <Select.Option value={item.value}>{item.label}</Select.Option>
                                                })
                                            }
                                        </Select> */}
                      <Cascader
                        size="large"
                        placeholder="Taxonomy or Model"
                        expandTrigger="hover"
                        options={menuItems}
                        displayRender={displayRender}
                        onChange={onSelect}
                      />

                      <div
                        style={{
                          fontSize: "2rem",
                          margin: "0",
                          margin: "0 1rem",
                          color: "gray",
                          height: "3rem",
                        }}
                      >
                        |
                      </div>
                      <Button
                        variant="outline-danger"
                        style={{
                          fontWeight: "700",
                          margin: "0",
                          height: "2.3rem",
                          borderRadius: "8px",
                          border: "1px solid #E91F63",
                          fontSize: "0.8rem",
                        }}
                        onClick={openTree}
                        disabled={isUploadDisabled}
                      >
                        UPLOAD CUSTOM TAXONOMY FILE
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {treeUploaded.uploaded && (
                <div>
                  <div
                    style={{
                      margin: "5vh 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FileDoneOutlined
                      style={{ marginRight: "0.5rem", fontSize: "2rem" }}
                    />{" "}
                    {treeUploaded.file_name}{" "}
                    <CloseOutlined
                      onClick={handleTreeReset}
                      style={{
                        marginLeft: "0.5rem",
                        fontWeight: "500",
                        color: "red",
                        fontSize: "0.5rem",
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {isUploading ? null : (
            <>
              <div
                {...getRootCsvFileProps()}
                style={{
                  marginTop: "3%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "45%",
                  backgroundColor: "#F8F9FA",
                  border: "1px solid #C7CCD0",
                  borderRadius: "12px",
                }}
              >
                {!csvUploaded.uploaded && shopName === null && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input {...getInputCsvFileProps()} />
                    <img
                      placeholder="csv_logo"
                      src={csv_logo}
                      style={{ marginTop: "10px" }}
                    />
                    <div
                      style={{
                        color: "#7B809A",
                        padding: "1rem",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      Drag & drop your CSV of product records to categorize them
                      to a taxonomy or categories list. CSV must contain 'title'
                      and 'description' columns .
                      <br />
                      You can also import products directly from your Shopify
                      store
                      <button
                        className="download-button"
                        onClick={() => {
                          download(
                            "https://raw.githubusercontent.com/Sirri69/Test/master/truncated_products2.csv",
                            "sample.csv"
                          );
                        }}
                      >
                        Download File Example.
                      </button>{" "}
                    </div>

                    {!isCsvDragActive && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="outline-danger"
                          type="submit"
                          onClick={openCsv}
                          style={{
                            border: "1px solid #E91F63",
                            fontWeight: "700",
                            margin: "0rem 0rem 1.5rem 1rem",
                            fontSize: "1rem",
                          }}
                        >
                          Upload
                        </Button>
                        <div
                          style={{
                            fontSize: "2rem",
                            margin: "0 1rem",
                            color: "gray",
                            height: "5rem",
                          }}
                        >
                          |
                        </div>
                        <p className="shop_text" onClick={addShopModal}>
                          Import Products From Shopify
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {csvUploaded.uploaded && (
                  <div>
                    <div
                      style={{
                        margin: "4vh 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <FileDoneOutlined
                        style={{ fontSize: "2rem", marginRight: "0.5rem" }}
                      />{" "}
                      {csvUploaded.file_name}
                      <CloseOutlined
                        onClick={handleCsvReset}
                        style={{
                          marginLeft: "0.5rem",
                          fontWeight: "500",
                          color: "red",
                          fontSize: "0.5rem",
                        }}
                      />
                    </div>

                    <div
                      className="csv_mapper"
                      style={{ display: "flex", gap: "2rem" }}
                    >
                      <span>
                        <p className="text_border"> Product Title </p>
                        <p className="text_border"> Product Description </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.8rem",
                        }}
                      >
                        <Select
                          // defaultValue={defaultVal.default1}
                          value={
                            defaultVal.default1 === ""
                              ? undefined
                              : defaultVal.default1
                          }
                          placeholder="Select a Column"
                          style={{ width: 120 }}
                          options={columns}
                          onChange={(val) => handleChange("def1", val)}
                        />
                        <Select
                          value={
                            defaultVal.default2 === ""
                              ? undefined
                              : defaultVal.default2
                          }
                          placeholder="Select a Column"
                          style={{ width: 120 }}
                          options={columns}
                          onChange={(value) => handleChange("def2", value)}
                        />
                      </span>
                    </div>
                  </div>
                )}

                {shopName !== null && (
                  <div
                    style={{
                      margin: "6vh 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FileDoneOutlined
                      style={{ fontSize: "2rem", marginRight: "0.5rem" }}
                    />{" "}
                    {shopName}{" "}
                    <CloseOutlined
                      onClick={handleShopReset}
                      style={{
                        marginLeft: "0.5rem",
                        fontWeight: "500",
                        color: "red",
                        fontSize: "0.5rem",
                      }}
                    />
                  </div>
                )}
              </div>
              <Button
                variant="danger"
                style={{
                  fontWeight: "700",
                  margin: "0",
                  height: "2.3rem",
                  marginTop: "2.5%",
                  borderRadius: "8px",
                  border: "1px solid #E91F63",
                  fontSize: "1rem",
                }}
                onClick={uploadFiles}
              >
                Upload & Generate
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default UploadArea;

import { Descriptions, Skeleton } from 'antd';

const ProductSimilarityCSVInfo = (props) => {
    const respData = props.productData.resp_data;

    if (props.loading) {
        return (
            <div>
                <Skeleton active />
                <br />
                <Skeleton active />
            </div>
        )
    }

    if (props.productData.status_code != 200) {
        return (
            <div>Some error occured</div>
        )
    }

    return (
        <div>
            <Descriptions title={
                <div>
                    Product Info
                </div>
            } bordered column={2}>
                <Descriptions.Item label="Product Title" span={2} >{respData.product.title}</Descriptions.Item>
                <Descriptions.Item label="SKU_ID" span={2} >{respData.product.sku_id}</Descriptions.Item>
                <Descriptions.Item label="Product Description" span={2}>{respData.product.description}</Descriptions.Item>
            </Descriptions>
            <hr />
            <Descriptions title={
                <div>
                    Results
                </div>
            }
                bordered>
                {respData.similarity_score != null ?
                    <Descriptions.Item label="Similarity Score">{Math.round(respData.similarity_score * 100) / 100}</Descriptions.Item>
                    : null}
            </Descriptions>
        </div>
    )
}


export default ProductSimilarityCSVInfo;
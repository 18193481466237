import React from "react";
import { Row, Col, Input, Form } from 'antd';

import axios from '../auth/Axios';
import unauthaxios from '../auth/UnAuthAxios';

import trees from '../assets/trees.png'
import widthlogo from '../assets/width-logo.png'
import { useNavigate } from "react-router";

const SignUP = () => {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successful, setSuccessful] = React.useState(false);

    const navigate = useNavigate();

    const handleLogIn = () => {
        navigate('/login');
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        console.log(e);


        if (firstName === ''){
            setErrorMessage('First Name is required')
            return
        }
        
        if (lastName === ''){
            setErrorMessage('Last Name is required')
            return
        }

        setErrorMessage('')

        if (successful) {
            alert('already signed up!');
            return;
        }

        unauthaxios.post('/user/create-account', {
            username: email,
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName
        }).then(res => {
            if (res.status === 200) {
                console.log(res);
                // navigate('/login');
                setSuccessful({
                    successful: true
                })
                window.location = '/login';
            }
        }).catch(function (error) {

            if (error.response.status === 400){
                setErrorMessage("Email address is already taken!")
            }
            else{
                setErrorMessage("Please fill all the details correctly and try again!")
            }
        });

    }


    return (
        <div>
            <Row align="middle">
                <Col span={12}>
                    <img src={trees} alt="trees" style={{ height: '100vh' }} />
                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>
                    <div>
                        <h2>Sign Up</h2>
                        <span className="text-muted">Enter your username, email and password to sign up</span>
                    </div>
                    <form>
                        <div style={{ paddingTop: '6%' }}>
                            <Input onKeyPress={handleKeyPress} type="text" placeholder="First Name" 
                            style={{ width: '40%', border: '1px solid #C7CCD0', borderRadius: '8px' }} 
                            value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            <br />
                            <Input type="text" placeholder="Last Name" style={{ width: '40%', border: '1px solid #C7CCD0', borderRadius: '8px', marginTop: '1%' }} value={lastName} onChange={(e) => setLastName(e.target.value)} required={true}/>
                            <br />
                            <Input type="email" placeholder="Email" style={{ width: '40%', border: '1px solid #C7CCD0', borderRadius: '8px', marginTop: '1%' }} value={email} onChange={(e) => setEmail(e.target.value)} />
                            <br />
                            <Input type="password" placeholder="Password" style={{ width: '40%', border: '1px solid #C7CCD0', borderRadius: '8px', marginTop: '1%' }} value={password} onChange={(e) => setPassword(e.target.value)} />
                            <br />
                            {errorMessage &&
                                <div style={{ margin: "0", color: "#E91F63", fontWeight: "400", fontSize: "0.8rem" }}>
                                    {errorMessage}
                                </div>
                            }
                            {successful &&
                                <div style={{ margin: "0", color: "green", fontWeight: "400", fontSize: "0.8rem" }}>
                                    Signed up successfully!
                                </div>
                            }
                            <button className="btn btn-danger" style={{
                                marginTop: '3%',
                                padding: '0.5% 7%',
                                borderRadius: '12px',
                                fontSize: '0.8rem',
                                background: "linear-gradient(180deg, #E93B77 0%, #DA1F63 100%)"
                            }}
                                onClick={handleSubmit}>
                                SIGN UP</button>
                        </div>
                    </form>

                    <br />
                    <span className="text-muted">Already have an account?
                        <span onClick={handleLogIn} style={{ color: "#DA1F63", fontWeight: 'bold', 
                        //add hand cursor
                        cursor: 'pointer' }}> Log In</span>
                    </span>

                    <div className="text-muted" style={{ paddingTop: '7%' }}>
                        <img src={widthlogo} alt="width logo" style={{ width: '15%' }} /> <br />
                        © Width LLC All Rights Reserved
                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default SignUP;

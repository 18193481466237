import React, { useEffect, useState } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { Button, Card, Form } from "react-bootstrap";
import { Row, Col, Modal, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { updateBreadcrumb } from "../actions/actions";
import axios from "../auth/Axios";
import {
  InfoCircleOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

const SingleSimilarityRun = () => {
  const { confirm } = Modal;
  const [score, setScore] = React.useState({
    compared: false,
    similarity_score: 0,
  });
  const [productInfo, setProductInfo] = React.useState({
    A: {
      title: "",
      description: "",
    },
    B: {
      title: "",
      description: "",
    },
  });

  const handleCompare = () => {
    const request = {
      product_1: {
        sku_id: "Test",
        title: productInfo.A.title,
        description: productInfo.A.description,
      },
      product_2: {
        sku_id: "Test",
        title: productInfo.B.title,
        description: productInfo.B.description,
      },
    };
    axios
      .post("product-categorization/get-product-similarity", request)
      .then(function (response) {
        setScore({
          compared: true,
          similarity_score: response.data.similarity_score,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal
        title="Product Similarity"
        visible={score.compared}
        footer={null}
        onCancel={() => setScore({ compared: false, similarity_score: 0 })}
      >
        <p>
          Similarity Score between {productInfo.A.title} and{" "}
          {productInfo.B.title}: {score.similarity_score.toFixed(2)}
        </p>
      </Modal>
      <Card
        style={{
          minHeight: "85vh",
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title
              style={{ fontWeight: "700", fontSize: "24px", color: "#344767" }}
            >
              Single Run
            </Card.Title>
            {/* <img src={i_logo} style={{ width: "2rem" }} /> */}

            <Tooltip
              placement="top"
              title="Enter product title and description to get a similarity score."
            >
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="mb-2 text-muted"
            style={{ color: "#7B809A", fontWeight: "400" }}
          >
            Enter product title and description to get a similarity score.{" "}
          </Card.Subtitle>

          <div style={{ marginTop: "3rem" }}>
            <h6 style={{ color: "#344767" }}>Product 1</h6>
            <Form.Group style={{ marginBottom: "1.5%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Title
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    A: {
                      ...productInfo.A,
                      title: e.target.value,
                    },
                  })
                }
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                type="text"
                placeholder="Pink shoes"
              />
              {/* value={productInfo.productName} onChange={(e) => setProductInfo({ ...productInfo, productTitle: e.target.value })} /> */}
              {/* <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback> */}
            </Form.Group>
            {/* <Form.Group style={{ marginBottom: "4%" }}>
                            <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400 }}>Product Categories</Form.Label>
                            <Form.Control isInvalid={!isValid.productCategories} style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }} type="text" placeholder="Pink shoes" value={productInfo.productCategories} onChange={(e) => setProductInfo({ ...productInfo, productCategories: e.target.value })} />
                            <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback>
                        </Form.Group> */}{" "}
            {/* @Pranav why is this commented*/}
            <Form.Group style={{ marginTop: "7%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Description
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    A: {
                      ...productInfo.A,
                      description: e.target.value,
                    },
                  })
                }
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                as="textarea"
                rows="2"
                placeholder="A pair of pink mules upper material: synthetic cushioned footbed Textured and patterned outsole"
              />
              {/* value={productInfo.productDescription} onChange={(e) => setProductInfo({ ...productInfo, productDescription: e.target.value })} /> */}
              {/* <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback> */}
            </Form.Group>
          </div>

          {/* <hr style={{width:'12%'}}/> */}

          <div style={{ marginTop: "13%" }}>
            <h6 style={{ color: "#344767" }}>Product 2</h6>
            <Form.Group style={{ marginBottom: "7%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Title
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    B: {
                      ...productInfo.B,
                      title: e.target.value,
                    },
                  })
                }
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                type="text"
                placeholder="Pink shoes"
              />
              {/* value={productInfo.productName} onChange={(e) => setProductInfo({ ...productInfo, productTitle: e.target.value })} /> */}
              {/* <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback> */}
            </Form.Group>
            {/* <Form.Group style={{ marginBottom: "4%" }}>
                            <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400 }}>Product Categories</Form.Label>
                            <Form.Control isInvalid={!isValid.productCategories} style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }} type="text" placeholder="Pink shoes" value={productInfo.productCategories} onChange={(e) => setProductInfo({ ...productInfo, productCategories: e.target.value })} />
                            <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback>
                        </Form.Group> */}{" "}
            {/* @Pranav why is this commented*/}
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Description
              </Form.Label>
              <Form.Control
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    B: {
                      ...productInfo.B,
                      description: e.target.value,
                    },
                  })
                }
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                as="textarea"
                rows="2"
                placeholder="A Pair of Women Fashion Green Pointed Toe Flat-Slim Casual Ballerinas With Heel Height 0.5 Inches. Ankle Loop with Regular Ankle Height."
              />
              {/* value={productInfo.productDescription} onChange={(e) => setProductInfo({ ...productInfo, productDescription: e.target.value })} /> */}
              {/* <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback> */}
            </Form.Group>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="danger"
              type="submit"
              onClick={handleCompare}
              style={{
                fontWeight: "700",
                width: "100px",
                height: "40px",
                marginTop: "1vh",
              }}
            >
              Generate
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SingleSimilarityRun;

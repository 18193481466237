import { Tag, Descriptions, Skeleton } from 'antd';

const ProductInfo = ({info}) => {
    var colors = ['cyan', 'volcano', 'red', 'magenta', 'blue', 'purple', 'green']

    if (info.loading) {
        return (
            <div>
                <Skeleton active />
                <br />
                <Skeleton active />
            </div>
        )
    }

    if (info.status_code !== 200) {
        return (
            <div>Some error occured</div>
        )
    }

    const parser = new DOMParser()
    const virtualDoc = parser.parseFromString(info.body, 'text/html')
    const body = virtualDoc.getElementsByTagName('body')[0].innerText
    console.log(body==='')
    return (
        <div>
            <Descriptions title={
                <div>
                    Product Info
                </div>
            } bordered column={2}>
                <Descriptions.Item label="Product Title" span={2}>{info.title === '' || info.title === null ? 'NA' : info.title}</Descriptions.Item>
                <Descriptions.Item label="Product Type" span={2}>{info.product_type === '' || info.product_type === null ? 'NA' : info.product_type}</Descriptions.Item>
                <Descriptions.Item label="Product Description" span={2}> {body ==='' || body === null?'NA':body } </Descriptions.Item>

                {
                    info.tags.length === 0 ? 
                <Descriptions.Item label="Product Tags" span={2}> NA </Descriptions.Item>
                    :
                    <Descriptions.Item label="Product Tags" span={2}>{info.tags.map((category, index) => {
                        if (category === "") { return null; }
                        return <Tag key={index} color={
                            colors[Math.floor(Math.random() * colors.length - 1)] || 'pink'
                        }>{category}</Tag>
                    }
                    )}</Descriptions.Item>
                }

            </Descriptions>
            <hr />
        </div>
    )
}


export default ProductInfo;
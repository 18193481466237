import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Card, Tabs, Tab } from "react-bootstrap";
import { Table, Button, Modal, Badge, Tooltip } from "antd";
import ProductSimilarityInfo from "./ProductSimilarityInfo";

import {
  loadProductSimilaritySingleRuns,
  loadProductSimilarityCSVRuns,
  appendProductSimilarityCSVRuns,
  appendProductSimilaritySingleRuns,
} from "../actions/productSimilarityPreviousRunActions";

import { InfoCircleOutlined } from "@ant-design/icons";

const ProductSimilarityPreviousRuns = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [info, setInfo] = useState({});
  const [selectedTab, setSelectedTab] = useState("csvruns");

  const [loading, setLoading] = useState({ csvRuns: false, singleRuns: false });

  const dispatch = useDispatch();
  const previousRuns = useSelector(
    (state) => state.productSimilarityPreviousRuns
  );
  const navigate = useNavigate();

  const handleClick = (productData) => {
    setIsModalVisible(true);
    setInfo(productData);
  };

  const handleCSVClick = (csv) => {
    navigate(`/product-similarity-run-info/${csv.id}`, { state: { csv: csv } });
  };

  const handlePagination = (pagination, filters, sorter, extra) => {
    if (selectedTab === "singleruns") {
      dispatch(
        appendProductSimilaritySingleRuns(
          previousRuns.last_page_single - 1,
          previousRuns.last_page_single + 1,
          previousRuns.singleRuns
        )
      );
    } else {
      dispatch(
        appendProductSimilarityCSVRuns(
          previousRuns.last_page_csv - 1,
          previousRuns.last_page_csv + 1,
          previousRuns.csvRuns
        )
      );
    }
  };

  const columns_single_runs = [
    {
      title: "Time",
      dataIndex: "created_at",
      responsive: ["lg"],
      ellipsis: true,
      render: (text, record) => (
        <div>{new Date(text).toString().split("GMT")[0]}</div>
      ),
    },
    {
      title: "Product 1 Title",
      dataIndex: ["req_data", "product_1", "title"],
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>
            {text == null
              ? "N/A"
              : text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
          </span>
        </div>
      ),
    },
    {
      title: "Product 2 Title",
      dataIndex: ["req_data", "product_2", "title"],
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>
            {text == null
              ? "N/A"
              : text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
          </span>
        </div>
      ),
    },
    {
      title: "Product 1 Description",
      dataIndex: ["req_data", "product_1", "description"],
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>
            {text == null
              ? "N/A"
              : text.length > 20
              ? text.substring(0, 20) + "...."
              : text}
          </span>
        </div>
      ),
    },
    {
      title: "Product 2 Description",
      dataIndex: ["req_data", "product_1", "description"],
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>
            {text == null
              ? "N/A"
              : text.length > 20
              ? text.substring(0, 20) + "...."
              : text}
          </span>
        </div>
      ),
    },
    {
      title: "Similarity Score",
      dataIndex: ["resp_data", "similarity_score"],
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>{text == null ? "N/A" : Math.round(text * 100) / 100}</span>
        </div>
      ),
    },
  ];

  const columns_csv_runs = [
    {
      title: "Time",
      dataIndex: "created_at",
      responsive: ["lg"],
      ellipsis: true,
      render: (text, record) => (
        <div>{new Date(text).toString().split("GMT")[0]}</div>
      ),
    },
    {
      title: "Target CSV Name",
      dataIndex: "original_file_name",
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>{text.length > 20 ? text.substring(0, 20) + "..." : text}</span>
        </div>
      ),
    },
    {
      title: "Embeddings CSV Name",
      dataIndex: "embeddings_csv",
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>
            {text?.length > 20 ? text.substring(0, 20) + "..." : text}
          </span>
        </div>
      ),
    },
    {
      title: "Run Type",
      dataIndex: "run_type",
      render: (text, record) => (
        <div>
          <span>
            {text == null
              ? "N/A"
              : text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}

            {record.from_api ? " | Triggered via API" : null}
          </span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["lg"],
      render: (text, record) => {
        return (
          <Badge
            status={
              record.status == "PROCESSING"
                ? "processing"
                : record.status == "COMPLETED"
                ? "success"
                : record.status == "FAILED"
                ? "error"
                : "warning"
            }
            text={record.status}
          />
        );
      },
    },
    {
      title: "Run Info",
      // dataIndex: 'info',
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            <span>
              <Button
                className="custom-hover"
                variant="outline-danger"
                style={{
                  fontWeight: "700",
                  color: "#dc3545",
                  border: "1px solid #E91F63",
                }}
                size="small"
                onClick={() => handleCSVClick(record)}
              >
                View CSV
              </Button>
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (previousRuns.singleRuns.length === 0) {
      setLoading({ ...loading, singleRuns: true });
      dispatch(loadProductSimilaritySingleRuns()).then(() => {
        setLoading({ ...loading, singleRuns: false });
      });
    }
    if (previousRuns.csvRuns.length === 0) {
      setLoading({ ...loading, csvRuns: true });
      dispatch(loadProductSimilarityCSVRuns()).then(() => {
        setLoading({ ...loading, csvRuns: false });
      });
    }
  }, []);

  return (
    <div>
      <Modal
        title="Products Similarity"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="60vw"
      >
        <ProductSimilarityInfo loading={false} productData={info} />
      </Modal>
      <Card className="table-card" style={{ height: "100%" }}>
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title>Previous Runs</Card.Title>
            <Tooltip
              placement="top"
              title="History of all your previous product similarity runs."
            >
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="text-muted"
            style={{
              color: "#7B809A",
              fontWeight: "400",
              paddingBottom: "1.7%",
            }}
          >
            History of all your previous product similarity runs.
          </Card.Subtitle>
          <Tabs
            style={{ paddingBottom: "1%" }}
            defaultActiveKey={selectedTab}
            onSelect={(e) => {
              setSelectedTab(e);
            }}
          >
            <Tab eventKey="singleruns" title="Single Runs">
              <Table
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      handleClick({
                        resp_data: record.resp_data,
                        req_data: record.req_data,
                        status_code: record.status_code,
                      });
                    },
                  };
                }}
                size="middle"
                loading={loading.singleRuns}
                columns={columns_single_runs}
                dataSource={previousRuns.singleRuns}
                pagination={{
                  position: ["bottomLeft"],
                  pageSize: 5,
                  size: "small",
                  simple: true,
                }}
                onChange={handlePagination}
              />
            </Tab>
            <Tab eventKey="csvruns" title="CSV Runs">
              <Table
                size="middle"
                loading={loading.csvRuns}
                columns={columns_csv_runs}
                dataSource={previousRuns.csvRuns}
                pagination={{
                  position: ["bottomLeft"],
                  pageSize: 5,
                  size: "small",
                  simple: true,
                }}
                onChange={handlePagination}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProductSimilarityPreviousRuns;

import React, { useState, useEffect } from "react";
import { Row, Col, Input } from 'antd';
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { useSelector } from "react-redux";

const APIKey = () => {

    const apiKey = useSelector(state => state.user.api_key);

    const handleCopy = (key) => {
        navigator.clipboard.writeText(key).then(function () {
            console.log('copying to clipboard was successful!');
          }, function (err) {
            console.error('Async: Could not copy api key: ', err);
          });
    }

    return (
        <div>
            <Card style={{ borderRadius: "12px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
                <Card.Body>
                    <Card.Title style={{ color: "#344767", fontWeight: "700", }}>
                        API Key
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted" style={{ fontWeight: "400", color: "#7B809A" }}>
                        Add your API key to the request headers to access the API
                    </Card.Subtitle>
                    <Row style={{ paddingTop: '2%' }}>
                        <Col span={24}>
                            Api key <Input style={{ width: '53%' }} value={apiKey} />
                            {/* <span style={{backgroundColor:'#a09b9bc9', padding:'2px', borderRadius:'4px'}}>{isHidden ? hidden_api_key : apiKey}</span> */}
                            <Button variant="outline-danger" style={{
                                border: "1px solid #E91F63",
                                fontWeight: "700", fontSize: "0.75rem", marginLeft: "0.5rem"
                            }} onClick={()=> {handleCopy(apiKey)}}>
                                Copy
                            </Button>
                        </Col>
                    </Row>
                    {/* <Button variant="outline-danger"
                    onClick={()=>navigator.clipboard.writeText(apiKey)}
                    style={{
                        border: "1px solid #E91F63",
                        fontWeight: "700", fontSize: "0.7rem", marginTop: "1rem"
                    }}>
                        Copy API Key
          </Button> */}
                </Card.Body>
            </Card>
        </div>
    )
}

export default APIKey;
import {useState, useEffect} from "react";
import { useParams, useLocation } from "react-router-dom";
import { Row, Col, Table, Modal, Button, Skeleton } from "antd";
import {Card} from 'react-bootstrap';
import {InfoCircleOutlined} from '@ant-design/icons';
import axios from "../auth/Axios";
import ShopInfo from "../components/ShopInfo";
import ShopProductInfo from "../components/ShopProductInfo";


const RunInfo = (props) => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [info, setInfo] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [shopInfo, setShopInfo] = useState({
                status_code :'' ,
                domain_name : '',
                created_at : '',
                product_length : ''
    })
    const [shopData, setShopData] = useState([])
    const [reRender, setReRender] = useState(0)
    
    const domainUrl = params.domainUrl;
    // setCsvInfo(location.state?.csv)

    const handleClick = (productData) => {
      console.log(productData);
      setIsModalVisible(true);
      setInfo(productData);
    }


    useEffect(()=> {

        const fetchData = () => {
            axios.get(`get-scrapped-products`, {
                params: {
                    domain_url:domainUrl
                }
            })
            .then((res)=> {
                console.log(res.data.scraped_data[0])
                setShopInfo(res.data.scraped_data[0])
                setShopData(res.data.scraped_data[0].data.products)

                setShopInfo( (prev) => { return {
                        status_code : res.data.scraped_data[0].status_code,
                        domain_name : res.data.scraped_data[0].domain_name,
                        created_at : res.data.scraped_data[0].created_at,
                        product_length : res.data.scraped_data[0].data.products.length
                }})
                setIsLoading(false)
            })
            .catch((err)=> {
                console.log("error occured")
                console.log(err)
            })
        }

        fetchData();

        let timeout1 = setTimeout(() => {
            console.log("timeout set")
            setReRender((prev)=> prev+=1)
        }, 6000);

        if(shopData.length !== 0) {
            console.log("timeout cleared")
            clearTimeout(timeout1)
        }

    },[domainUrl,reRender])


    const onCancel = () => {
        setIsModalVisible(false)
    }

    const handleDownload = () => {

        axios.get(`download-scrapped`, {
            params: {
                domain_url: domainUrl
            }
        })
        .then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a'); //hide this link
            link.href = url;
            link.setAttribute('download', 'scraped_' + domainUrl + '.csv'); //or any other extension
            link.setAttribute('hidden', true);
            document.body.appendChild(link);
            link.click();

        })

        .catch((err)=> {
            alert("some error occured")
            console.log("due to error")
        })

    }

    const columns = [
        {
          title: 'Time',
          dataIndex: 'created_at',
          render: (text, record) => (
            <div>
              {new Date(text).toString().split('GMT')[0]}
            </div>
          )
        },
        {
          title: 'Product Title',
          // dataIndex: 'url',
          render: (text, record) => (
            <div>
              <span>
                 {record.title.length > 60 ? record.title.substring(0, 60) + "...." : record.title}
                </span>
            </div>
          ),
        },
        {
          title: 'Scraped Entities',
          render: (text, record) =>(
              <div>
                <span><Button className="custom-hover" variant = "outline-danger" style={{
              fontWeight: "700",
              color:"#dc3545",
              border: '1px solid #E91F63',
            }} size='small' 
            onClick={()=> handleClick({"title":text.title, "body":text.body_html, "status_code":200, "product_type":text.product_type, "tags":text.tags})}
            >View</Button></span>
              </div>
            )
        },
      ];

    return (
        <>
        <div>
        <Modal visible={isModalVisible} onCancel={onCancel} centered={true} footer={null} width='60vw'>
            <ShopProductInfo info = {info} />
        </Modal>
        <Row>
            <Col span={24}>
            <Card style={{height:"35vh", borderRadius:"12px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.2)"}}>
            <Card.Body>
                <Card.Title>
                    Shop Info
                </Card.Title>
                <Card.Subtitle className="mb-4 text-muted">
                  Information about the selected shop.
                </Card.Subtitle>
              {isLoading ? <Skeleton/> : <ShopInfo info={shopInfo}/>}
            </Card.Body>
            </Card>
            </Col>
        </Row>
        
        <Row style={{marginTop:"2%"}}>
            <Col span={24}>
            <Card style={{height:"65vh", borderRadius:"12px", boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.2)"}}>
            <Card.Body>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <Card.Title>
                      Scraped Products
                  </Card.Title>
                  {
                    isLoading ?  null :
                    <Button className="custom-hover" variant="outline-danger" style={{
                      fontWeight: "700",
                      color:"#dc3545",
                      border: '1px solid #E91F63',
                      }}
                      onClick={handleDownload}
                     size='medium'>Download Result CSV</Button>
                  }
              </div>
                <Card.Subtitle className="mb-4 text-muted">
                  Products scraped from the provided Url. <br/>
                  <small class="text-muted">Only some products are shown, please download the CSV for a complete list of products.</small>
                </Card.Subtitle>
                <Table locale={{emptyText: isLoading? "Processing..." : "No data"}} columns={columns} dataSource={shopData} loading={isLoading} pagination={{position: ['bottomLeft'], pageSize:5, size:"small", simple:true}}/>
                </Card.Body>
            </Card>
            </Col>
        </Row>
        </div>
        </>
    );
}

export default RunInfo;
import { Descriptions, Badge } from "antd";

const CSVInfo = (props) => {
  const etaData = props.etaDetails;
  console.log(props);
  props = props.info;
  console.log(props);

  return (
    <Descriptions bordered>
      <Descriptions.Item label="File Name" span={2}>
        {props.original_file_name}
      </Descriptions.Item>
      {/* <Descriptions.Item label="Run ID">{csvId}</Descriptions.Item> */}
      <Descriptions.Item label="Run Status">
        <Badge
          status={
            props.status == "PROCESSING"
              ? "processing"
              : props.status == "COMPLETED"
              ? "success"
              : props.status == "FAILED"
              ? "error"
              : "warning"
          }
          text={props.status}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Run Time" span={2}>
        {new Date(props.created_at).toString().split("GMT")[0]}
      </Descriptions.Item>
      <Descriptions.Item label="Rows">{props.info?.rows}</Descriptions.Item>
      {props.status == "PROCESSING" && (
        <>
          <Descriptions.Item label="ETA" span={2}>
            {etaData.eta}
          </Descriptions.Item>
          <Descriptions.Item label="Rows completed" span={2}>
            {etaData.rows_completed}
          </Descriptions.Item>
        </>
      )}

      {props.embeddings_csv && (
        <Descriptions.Item label="Embeddings CSV">
          {props.embeddings_csv}
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default CSVInfo;

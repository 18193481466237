const initialState = {
    userInfo: {},
    recentRuns: {},
    userDetailsLoading: true,
    userEmail: null,
    expiryDate: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "USER_LOGIN":
            return {
                ...state,
                userInfo: action.payload,
                userDetailsLoading: false
            };
        case "SET_USER":
            return {
                ...action.payload,
            }

        default:
            return state;
    }
}

export default userReducer;
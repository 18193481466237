import React from "react";
import { Row, Col, Input } from 'antd';

import axios from '../auth/Axios';
import unauthaxios from '../auth/UnAuthAxios';

import trees from '../assets/trees.png'
import widthlogo from '../assets/width-logo.png'
import { useNavigate } from "react-router";

const Login = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [failedToValidate, setFailedToValidate] = React.useState(false);
    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate('/signup');
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        console.log(e);

        unauthaxios.post('/auth/token', {
            email: email,
            password: password,
        }).then(res => {
            if (res.status === 200) {
                localStorage.setItem('accessToken', res.data.access);
                localStorage.setItem('refreshToken', res.data.refresh);
                setFailedToValidate(false);
                // navigate('/');
                window.location.reload();
            }
        }).catch(function (error) {
            console.log(error);
            setFailedToValidate(true);
        });

    }


    return (
        <div>
            <Row align="middle">
                <Col span={12}>
                    <img src={trees} alt="trees" style={{ height: '100vh' }} />
                </Col>
                <Col span={12} style={{ textAlign: 'center' }}>
                    <div>
                        <h2>Sign In</h2>
                        <span className="text-muted">Enter your email and password to login</span>
                    </div>
                    <form>
                        <div style={{ paddingTop: '6%' }}>
                            <Input
                                type="email"
                                placeholder="Email"
                                style={{ width: '40%', border: '1px solid #C7CCD0', borderRadius: '8px' }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <br />
                            <Input
                                type="password"
                                placeholder="Password" style={{ width: '40%', border: '1px solid #C7CCD0', borderRadius: '8px', marginTop: '1%' }}
                                value={password} onChange={(e) => setPassword(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            {failedToValidate == true ? 
                                <div style={{ margin: "0", color: "#E91F63", fontWeight: "400", fontSize: "0.8rem" }}>
                                    Incorrect email or password. Please try again!
                                </div>
                                : null
                            }
                            <br />
                            {/* {!failedToValidate && <br />} */}
                            <button className="btn btn-danger"
                                type="submit"
                                style={{
                                    marginTop: '3%',
                                    padding: '0.5% 7%',
                                    borderRadius: '12px',
                                    fontSize: '0.8rem',
                                    background: "linear-gradient(180deg, #E93B77 0%, #DA1F63 100%)"
                                }}
                                onClick={handleSubmit}>
                                SIGN IN</button>
                        </div>
                    </form>

                    <br />
                    <span className="text-muted">Don't have an account? {" "}
                        <span
                            style={{ color: "#DA1F63", fontWeight: 'bold', cursor: "pointer" }}
                            onClick={handleSignUp}
                        >
                            Sign up
                        </span>
                    </span>

                    <div className="text-muted" style={{ paddingTop: '7%' }}>
                        <img src={widthlogo} alt="width logo" style={{ width: '15%' }} /> <br />
                        © Width LLC All Rights Reserved
                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default Login;

import React from "react";

import { Row, Col } from "antd";

import ImageSimilarityUpload from "../components/ImageSimilarityUpload";
import ImageSimilaritySingleRun from "../components/ImageSimilaritySingleRun";
import ImageSimilarityPreviousRuns from "../components/ImageSimilarityPreviousRuns";
const ImageSimilarity = () => {
  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <ImageSimilarityUpload />
        </Col>
        <Col span={12}>
          <ImageSimilaritySingleRun />
        </Col>
      </Row>
      <Row style={{ marginTop: "2%" }}>
        <Col span={24}>
          <ImageSimilarityPreviousRuns />
        </Col>
      </Row>
    </div>
  );
};

export default ImageSimilarity;

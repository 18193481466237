import React, { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Input, Cascader, Tooltip } from "antd";
import axios from "../auth/Axios";
import { useNavigate } from "react-router";

const Scraper = () => {
  const [error, setError] = useState("");
  const [ecomUrl, setEcomUrl] = useState("");
  const [shopType, setShopType] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const getDomainName = (url) => {
    let domain = new URL(url);
    domain = domain.hostname.replace("www.", "");
    return domain;
  };

  const handleSubmit = () => {
    if (isValidUrl(ecomUrl) === false) {
      setError("Please enter a valid url");
      return;
    }

    setLoading(true);
    setError("");

    const domain_url = getDomainName(ecomUrl);

    axios
      .get(`scrape-ecommerce`, {
        params: {
          url: ecomUrl,
          domain_url: domain_url,
          shop_type: shopType,
        },
      })
      .then((resp) => {
        setError("");
        setLoading(false);
        // setIsModalVisible(true)
        navigate(`/scrape-info/${domain_url}`);
      })

      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          setError(`The provided site is not a ${shopType} website.`);
        } else {
          setError("Some error occured. Please try again.");
        }
        setLoading(false);
      });
  };

  const displayRender = (labels) => labels[labels.length - 1];

  const onSelect = (e) => {
    if (e === undefined) {
      setShopType("");
    } else {
      setShopType(e[0]);
    }
  };

  const menuItems = [
    {
      value: "Shopify",
      label: "Shopify",
      // children: [...props.taxonomyTrees]
    },
    {
      value: "Wix",
      label: "WIX (Coming Soon)",
      disabled: true,
    },
    {
      value: "WooCommerce",
      label: "WooCommerce (Coming Soon)",
      disabled: true,
    },
  ];

  return (
    <div>
      <Card
        style={{
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title
              style={{ fontWeight: "700", fontSize: "24px", color: "#344767" }}
            >
              Ecommerce Scraper
            </Card.Title>
            {/* <img src={i_logo} style={{ width: "2rem" }} /> */}

            <Tooltip
              placement="top"
              title="Scrape and download the data from an Ecommerce website"
            >
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="mb-2 text-muted"
            style={{ color: "#7B809A", fontWeight: "400" }}
          >
            Scrape and download the data from an Ecommerce website.{" "}
          </Card.Subtitle>

          <div style={{ marginTop: "2%" }}>
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Enter shop URL.
              </Form.Label>
              <Input
                placeholder="Example:- https://shopify-website.com"
                value={ecomUrl}
                onChange={(e) => {
                  setEcomUrl(e.target.value);
                }}
              />

              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A", marginTop: "3%" }}
              >
                Select shop type.
              </Form.Label>
              <div>
                <Cascader
                  size="large"
                  placeholder="Shopify"
                  expandTrigger="hover"
                  options={menuItems}
                  displayRender={displayRender}
                  onChange={onSelect}
                />
              </div>

              <Form.Control.Feedback
                type="invalid"
                tooltip={true}
                as={() => <div></div>}
              ></Form.Control.Feedback>
            </Form.Group>

            <div>
              <p style={{ color: "red" }}> {error} </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="danger"
                type="submit"
                disabled={loading || ecomUrl === "" || shopType === ""}
                onClick={handleSubmit}
                style={{
                  fontWeight: "700",
                  width: "100px",
                  height: "40px",
                  marginTop: "2%",
                }}
              >
                {loading ? "Loading..." : "Scrape"}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Scraper;

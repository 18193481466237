import { Tag, Descriptions, Skeleton } from 'antd';

const ProductInfo = (props) => {
    const reqData = props.productData.req_data.data;
    const respData = props.productData.resp_data;
    var colors = ['cyan', 'volcano', 'red', 'magenta', 'blue', 'purple', 'green']

    if (props.loading) {
        return (
            <div>
                <Skeleton active />
                <br />
                <Skeleton active />
            </div>
        )
    }

    if (props.productData.status_code != 200) {
        return (
            <div>Some error occured</div>
        )
    }

    let categories = Array.isArray(respData.categories) ? respData.categories.join(', ') : respData.categories


    return (
        <div>
            <Descriptions title={
                <div>
                    Product Info
                </div>
            } bordered column={2}>
                <Descriptions.Item label="Product Title" span={2}>{reqData.title}</Descriptions.Item>
                <Descriptions.Item label="Product Description" span={2}>{reqData.description}</Descriptions.Item>
                <Descriptions.Item label="Product Brand">{reqData.brand}</Descriptions.Item>
                <Descriptions.Item label="Product Price">{reqData.price ? '$' + reqData.price : '--'}</Descriptions.Item>
                <Descriptions.Item label="Product Volume">{reqData.volume}</Descriptions.Item>
                <Descriptions.Item label="Product SKU ID">{reqData.sku_id}</Descriptions.Item>
                <Descriptions.Item label="Product GTIN">{reqData.gtin}</Descriptions.Item>
                {/* <Descriptions.Item label="Product Properties">{reqData.properties}</Descriptions.Item> */}
            </Descriptions>
            <hr />
            <Descriptions title={
                <div>
                    Generated Categories
                </div>
            }
                bordered>
                <Descriptions.Item label="Product Categories" span={2}>{categories}</Descriptions.Item>
                <br />
                {respData.tags !=null ? 
                <Descriptions.Item label="Product Tags">{respData.tags.map((category, index) => {
                    if (category === "") { return null; }
                    return <Tag key={index} color={
                        colors[Math.floor(Math.random() * colors.length - 1)] || 'pink'
                    }>{category}</Tag>
                }
                )}</Descriptions.Item>
            : null}
            </Descriptions>
            {/* {JSON.stringify(props.info)} */}
        </div>
    )
}


export default ProductInfo;
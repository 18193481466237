import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Table, Button, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPreviousUniversalScrapped,
  appendPreviousUniversalScraped,
} from "../actions/previousRunsActions";

const PreviousUniversalScraped = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const previousRuns = useSelector((state) => state.previousRuns);
  const previousScraped = previousRuns.universalScraped;

  useEffect(() => {
    if (previousRuns.scraped.length === 0) {
      setLoading(true);
      dispatch(loadPreviousUniversalScrapped()).then(() => {
        setLoading(false);
      });
    }
  }, []);

  const handlePagination = (page, pagesize) => {
    dispatch(
      appendPreviousUniversalScraped(
        previousRuns.last_page_universal - 1,
        previousRuns.last_page_universal + 1,
        previousRuns.universalScraped
      )
    );
  };

  const columns_prev_scraped = [
    {
      title: "Time",
      dataIndex: "created_at",
      responsive: ["lg"],
      ellipsis: true,
      render: (text, record) => (
        <div>{new Date(text).toString().split("GMT")[0]}</div>
      ),
    },

    {
      title: "Search Term",
      // dataIndex: 'shop_type',
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            <span>{record.search_term}</span>
          </div>
        );
      },
    },
    {
      title: "Returned Products",
      // dataIndex: 'info',
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            <span>
              <Button
                className="custom-hover"
                variant="outline-danger"
                style={{
                  // backgroundColor: '#dc3545',
                  fontWeight: "700",
                  color: "#dc3545",
                  border: "1px solid #E91F63",
                }}
                size="small"
                onClick={() => navigate(`/universal-scrape-info/${text.id}`)}
              >
                View
              </Button>
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Card className="table-card" style={{ height: "100%" }}>
        {/* <Card.Header as="h5">Featured</Card.Header> */}
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title>Previous Searched Items</Card.Title>

            <Tooltip
              placement="top"
              title="History of all your previous searched items."
            >
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="text-muted"
            style={{
              color: "#7B809A",
              fontWeight: "400",
              paddingBottom: "1.7%",
            }}
          >
            History of all your previous searched items.
          </Card.Subtitle>
          <Table
            size="middle"
            loading={loading}
            columns={columns_prev_scraped}
            dataSource={previousScraped}
            pagination={{
              position: ["bottomLeft"],
              pageSize: 5,
              size: "small",
              simple: true,
            }}
            onChange={handlePagination}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default PreviousUniversalScraped;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { Card, Tabs, Tab } from "react-bootstrap";
import { Table, Button, Modal, Badge, Tooltip } from "antd";
import ProductInfo from "./ProductInfo";

import {
  loadSingleRuns,
  loadCSVRuns,
  appendCSVRuns,
  appendSingleRuns,
} from "../actions/previousRunsActions";

import { InfoCircleOutlined } from "@ant-design/icons";
import ExportModal from "./ExportModal";

const PreviousRuns = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [info, setInfo] = useState({});
  const [selectedTab, setSelectedTab] = useState("csvruns");
  const [loading, setLoading] = useState({ csvRuns: false, singleRuns: false });
  const [showExportModal, setShowExportModal] = useState(false);
  const [csv, setCsv] = useState(null);
  const [page, setPage] = useState(1);
  // const [rows, setRows] = useState([]);

  let location = useLocation();
  let currentRoute = location.pathname;

  const dispatch = useDispatch();
  const previousRuns = useSelector((state) => state.previousRuns);

  const prevStaticSingleRuns = previousRuns.singleRuns.filter(
    (run) => run.run_type === "STATIC"
  );
  const prevDynamicSingleRuns = previousRuns.singleRuns.filter(
    (run) => run.run_type === "DYNAMIC"
  );

  const prevStaticCsvRuns = previousRuns.csvRuns.filter(
    (run) => run.run_type === "STATIC"
  );
  const prevDynamicCsvRuns = previousRuns.csvRuns.filter(
    (run) => run.run_type === "DYNAMIC"
  );

  let runType =
    currentRoute === "/"
      ? null
      : currentRoute === "/staticcategorization"
      ? "static categorization"
      : "dynamic categorization";

  // setCurrentRoute(location.pathname)

  const navigate = useNavigate();

  const handleClick = (productData) => {
    setIsModalVisible(true);
    setInfo(productData);
  };

  const handleCSVClick = (csv) => {
    if (csv.run_type === "SIMILARITY") {
      navigate(`/product-similarity-run-info/${csv.id}`, {
        state: { csv: csv },
      });
    } else {
      navigate(`/run-info/${csv.id}`, { state: { csv: csv } });
    }
  };

  const handlePagination = (pagination, filters, sorter, extra) => {
    if (selectedTab === "singleruns") {
      dispatch(
        appendSingleRuns(
          previousRuns.last_page_single - 1,
          previousRuns.last_page_single + 1,
          previousRuns.singleRuns
        )
      );
    } else {
      // console.log(previousRuns.csvRuns.last_page_csv, previousRuns.last_page_csv, previousRuns)
      dispatch(
        appendCSVRuns(
          previousRuns.last_page_csv - 1,
          previousRuns.last_page_csv + 1,
          previousRuns.csvRuns
        )
      );
    }
  };

  const exportToShop = (record) => {
    if (record.id === csv.id) {
      setShowExportModal(true);
    }
  };

  const updatePage = (page) => {
    setPage(page);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
    setPage(1);
  };

  const columns_single_runs = [
    {
      title: "Time",
      dataIndex: "created_at",
      responsive: ["lg"],
      ellipsis: true,
      render: (text, record) => (
        <div>{new Date(text).toString().split("GMT")[0]}</div>
      ),
    },
    {
      title: "Run Type",
      dataIndex: "run_type",
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>
            {text == null
              ? "N/A"
              : text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
          </span>
        </div>
      ),
    },
    {
      title: "Returned Entities",
      render: (text, record) => {
        return (
          <div>
            <span>
              <Button
                className="custom-hover"
                variant="outline-danger"
                style={{
                  fontWeight: "700",
                  color: "#dc3545",
                  border: "1px solid #E91F63",
                }}
                size="small"
                onClick={() =>
                  handleClick({
                    resp_data: record.resp_data,
                    req_data: record.req_data,
                    status_code: record.status_code,
                  })
                }
              >
                View
              </Button>
            </span>
          </div>
        );
      },
    },
  ];

  const columns_csv_runs = [
    {
      title: "Time",
      dataIndex: "created_at",
      responsive: ["lg"],
      ellipsis: true,
      render: (text, record) => (
        <div>{new Date(text).toString().split("GMT")[0]}</div>
      ),
    },
    {
      title: "Filename",
      dataIndex: "original_file_name",
      ellipsis: true,
      render: (text, record) => (
        <div>
          <span>{text.length > 20 ? text.substring(0, 20) + "..." : text}</span>
        </div>
      ),
    },
    {
      title: "Run Type",
      dataIndex: "run_type",
      render: (text, record) => (
        <div>
          <span>
            {text == null
              ? "N/A"
              : text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}

            {record.from_api ? " | Triggered via API" : null}
          </span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["lg"],
      render: (text, record) => {
        return (
          <Badge
            status={
              record.status === "PROCESSING"
                ? "processing"
                : record.status === "COMPLETED"
                ? "success"
                : record.status === "FAILED"
                ? "error"
                : "warning"
            }
            text={record.status}
          />
        );
      },
    },
    {
      title: "Run Info",
      // dataIndex: 'info',
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            <span>
              <Button
                className="custom-hover"
                variant="outline-danger"
                style={{
                  // backgroundColor: '#dc3545',
                  fontWeight: "700",
                  color: "#dc3545",
                  border: "1px solid #E91F63",
                }}
                size="small"
                onClick={() => handleCSVClick(record)}
              >
                View CSV
              </Button>
            </span>
          </div>
        );
      },
    },

    {
      title: "Export To Shop",
      // dataIndex: 'info',
      ellipsis: true,
      render: (text, record) => {
        return (
          <div>
            <span>
              <Button
                disabled={!record.imported_from_shop}
                className={record.imported_from_shop ? "custom-hover" : null}
                variant="outline-danger"
                style={{
                  // backgroundColor: '#dc3545',
                  fontWeight: "700",
                  color: "#dc3545",
                  border: "1px solid #E91F63",
                }}
                size="small"
                onClick={() => {
                  setCsv(record);
                  exportToShop(record);
                }}
              >
                Export
              </Button>
            </span>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (previousRuns.singleRuns.length === 0) {
      setLoading({ ...loading, singleRuns: true });
      dispatch(loadSingleRuns()).then(() => {
        setLoading({ ...loading, singleRuns: false });
      });
    }
    if (previousRuns.csvRuns.length === 0) {
      setLoading({ ...loading, csvRuns: true });
      dispatch(loadCSVRuns()).then(() => {
        setLoading({ ...loading, csvRuns: false });
      });
    }
  }, []);

  return (
    <div>
      <Modal
        title="Product Info"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width="60vw"
      >
        <ProductInfo loading={false} productData={info} />
      </Modal>

      <Modal
        title="Export Products"
        visible={showExportModal}
        onCancel={() => closeExportModal()}
        footer={null}
        width="80vw"
      >
        <ExportModal csv={csv} page={page} updatePage={updatePage} />
      </Modal>

      <Card className="table-card" style={{ height: "100%" }}>
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title>Previous Runs</Card.Title>
            <Tooltip
              position="top"
              title={
                runType == null
                  ? "History of all your previous product runs."
                  : ` History of all your previous product ${runType} categorization runs`
              }
            >
              {" "}
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="text-muted"
            style={{
              color: "#7B809A",
              fontWeight: "400",
              paddingBottom: "1.7%",
            }}
          >
            History of all your previous product {runType} runs.
          </Card.Subtitle>
          <Tabs
            style={{ paddingBottom: "1%" }}
            defaultActiveKey={selectedTab}
            onSelect={(e) => {
              setSelectedTab(e);
            }}
          >
            <Tab eventKey="singleruns" title="Single Runs">
              <Table
                size="middle"
                loading={loading.singleRuns}
                columns={columns_single_runs}
                dataSource={
                  currentRoute === "/"
                    ? previousRuns.singleRuns
                    : currentRoute === "/staticcategorization"
                    ? prevStaticSingleRuns
                    : prevDynamicSingleRuns
                }
                pagination={{
                  position: ["bottomLeft"],
                  pageSize: 5,
                  size: "small",
                  simple: true,
                }}
                onChange={handlePagination}
              />
            </Tab>
            <Tab eventKey="csvruns" title="CSV Runs">
              <Table
                size="middle"
                loading={loading.csvRuns}
                columns={columns_csv_runs}
                dataSource={
                  currentRoute === "/"
                    ? previousRuns.csvRuns
                    : currentRoute === "/staticcategorization"
                    ? prevStaticCsvRuns
                    : prevDynamicCsvRuns
                }
                pagination={{
                  position: ["bottomLeft"],
                  pageSize: 5,
                  size: "small",
                  simple: true,
                }}
                onChange={handlePagination}
              />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PreviousRuns;

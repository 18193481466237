import React, { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import axios from "../auth/Axios";
import i_logo from "../assets/information.png";
import ProductInfo from "./ProductInfo";

const SingleRun = (props) => {
  const [productInfo, setProductInfo] = React.useState({
    productTitle: "",
    productCategories: "asdad",
    productDescription: "",
  });

  const [isValid, setIsValid] = React.useState({
    productTitle: true,
    productCategories: true,
    productDescription: true,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState("");

  var obj = {};
  const handleSubmit = (event) => {
    for (let key in productInfo) {
      obj[key] = productInfo[key] !== "" ? true : false;
      setIsValid((prevState) => ({
        ...prevState,
        [key]: productInfo[key] !== "" ? true : false,
      }));
    }
    event.preventDefault();
    if (
      obj["productTitle"] &&
      obj["productCategories"] &&
      obj["productDescription"]
    ) {
      setIsModalVisible(true);
      setLoading(true);
      axios
        .post(
          "product-categorization/product-categorization-statistically",
          productInfo
        )
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            // console.log(res);
            setResponse(res.data);
            setLoading(false);
            // setIsModalVisible(true);
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsModalVisible(false);
          setLoading(false);
          Modal.error({
            title: "Error",
            content:
              error.response?.data?.message ||
              "Something went wrong. Please try again.",
          });
        });
    }
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        title="Response"
        visible={isModalVisible}
        onCancel={onCancel}
        footer={null}
        width="60vw"
      >
        {/* {JSON.stringify(response)} */}
        <ProductInfo
          loading={loading}
          productData={{
            resp_data: response.product_categories,
            req_data: {
              data: {
                title: productInfo.productTitle,
                description: productInfo.productDescription,
              },
            },
            status_code: 200,
          }}
        />
      </Modal>
      <Card
        style={{
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Card.Title
              style={{ fontWeight: "700", fontSize: "24px", color: "#344767" }}
            >
              Single Run
            </Card.Title>
            {/* <img src={i_logo} style={{ width: "2rem" }} /> */}
            <Tooltip
              placement="top"
              title={
                "To generate static categorization for a single item . Enter title and description"
              }
            >
              <InfoCircleOutlined
                style={{ fontSize: "1.5rem", color: "#7B809A" }}
              />
            </Tooltip>
          </div>
          <Card.Subtitle
            className="mb-2 text-muted"
            style={{ color: "#7B809A", fontWeight: "400" }}
          >
            Enter product title and description to get a product category.{" "}
          </Card.Subtitle>

          <div style={{ marginTop: "2%" }}>
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Product Title
              </Form.Label>
              <Form.Control
                isInvalid={!isValid.productTitle}
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                type="text"
                placeholder="Pink shoes"
                value={productInfo.productName}
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    productTitle: e.target.value,
                  })
                }
              />
              <Form.Control.Feedback
                type="invalid"
                tooltip={true}
                as={() => <div></div>}
              ></Form.Control.Feedback>
            </Form.Group>
            {/* <Form.Group style={{ marginBottom: "4%" }}>
                            <Form.Label className="mb-2 text-muted" style={{ fontWeight: 400 }}>Product Categories</Form.Label>
                            <Form.Control isInvalid={!isValid.productCategories} style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }} type="text" placeholder="Pink shoes" value={productInfo.productCategories} onChange={(e) => setProductInfo({ ...productInfo, productCategories: e.target.value })} />
                            <Form.Control.Feedback type="invalid" tooltip={true} as={() => (<div></div>)}></Form.Control.Feedback>
                        </Form.Group> */}{" "}
            {/* @Pranav why is this commented*/}
            <Form.Group style={{ marginBottom: "4%" }}>
              <Form.Label
                className="mb-2 text-muted"
                style={{ fontWeight: 400, color: "#7B809A" }}
              >
                Product Description
              </Form.Label>
              <Form.Control
                isInvalid={!isValid.productDescription}
                style={{ borderTop: 0, borderRight: 0, borderLeft: 0 }}
                as="textarea"
                rows="2"
                placeholder="A pair of pink mules upper material: synthetic cushioned footbed Textured and patterned outsole"
                value={productInfo.productDescription}
                onChange={(e) =>
                  setProductInfo({
                    ...productInfo,
                    productDescription: e.target.value,
                  })
                }
              />
              <Form.Control.Feedback
                type="invalid"
                tooltip={true}
                as={() => <div></div>}
              ></Form.Control.Feedback>
            </Form.Group>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="danger"
                type="submit"
                onClick={handleSubmit}
                style={{
                  fontWeight: "700",
                  width: "100px",
                  height: "40px",
                  marginTop: "2%",
                }}
              >
                Generate
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SingleRun;

import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { Row, Modal, Tooltip } from "antd";
import axios from "../auth/Axios";
import {
  InfoCircleOutlined,
  CloseOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router";
import { Spin, Select } from "antd";
import { download } from "./utils";
import Papa from "papaparse";
import csv_logo from "../assets/csv_logo.png";

const UploadCSVs = (props) => {
  const navigate = useNavigate();

  const [csv1Uploaded, setCsv1Uploaded] = useState({
    uploaded: false,
    file_name: "",
    csv_file: null,
  });
  const [csv2Uploaded, setCsv2Uploaded] = useState({
    uploaded: false,
    file_name: "",
    csv_file: null,
  });
  const [isUploading, setIsUploading] = useState(false);

  const [menuItems, setMenuItems] = useState([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState(false);
  const [selected_items, setSelectedItems] = useState(null);
  const [columns1, setColumns1] = useState([]);
  const [columns2, setColumns2] = useState([]);
  const [defaultVal1, setDefaultVal1] = useState({
    default1: "",
    default2: "",
    default3: "",
  });
  const [defaultVal2, setDefaultVal2] = useState({
    default1: "",
    default2: "",
    default3: "",
  });
  const [modelDropodown, setModelDropdown] = useState([]);
  const [modelId, setModelId] = useState(null);
  const [similarityType, SetSimilarityType] = useState("product");
  useEffect(() => {
    setMenuItems(props.embeddingCSVs);

    let modelList = props.models;
    modelList = modelList.map((item) => {
      return {
        value: item["model_id"],
        label: item["model_name"],
      };
    });
    setModelDropdown(modelList);
    SetSimilarityType(props.similarity_type);
  }, [props]);

  const handleChange = (key, val, csvNo) => {
    if (csvNo === 1) {
      if (key === "def1") {
        setDefaultVal1((prev) => {
          return {
            ...prev,
            default1: val,
          };
        });
      }

      if (key === "def2") {
        setDefaultVal1((prev) => {
          return {
            ...prev,
            default2: val,
          };
        });
      }

      if (key === "def3") {
        setDefaultVal1((prev) => {
          return {
            ...prev,
            default3: val,
          };
        });
      }
    } else {
      if (key === "def1") {
        setDefaultVal2((prev) => {
          return {
            ...prev,
            default1: val,
          };
        });
      }

      if (key === "def2") {
        setDefaultVal2((prev) => {
          return {
            ...prev,
            default2: val,
          };
        });
      }

      if (key === "def3") {
        setDefaultVal2((prev) => {
          return {
            ...prev,
            default3: val,
          };
        });
      }
    }
  };

  const createSelect1 = (item) => {
    if (item !== "") {
      setColumns1((prev) => {
        return [...prev, { value: item, label: item }];
      });
    }
    if (
      defaultVal1.default1 === "" &&
      (String(item).includes("Title") || String(item).includes("title"))
    ) {
      setDefaultVal1((prev) => {
        return {
          ...prev,
          default1: item,
        };
      });
    }
    if (
      defaultVal1.default2 === "" &&
      (String(item).includes("Descr") || String(item).includes("descr"))
    ) {
      setDefaultVal1((prev) => {
        return {
          ...prev,
          default2: item,
        };
      });
    }
    if (
      defaultVal1.default3 === "" &&
      (String(item).includes("Sku") || String(item).includes("sku"))
    ) {
      setDefaultVal1((prev) => {
        return {
          ...prev,
          default3: item,
        };
      });
    }
  };

  const createSelect2 = (item) => {
    if (item !== "") {
      setColumns2((prev) => {
        return [...prev, { value: item, label: item }];
      });
    }

    if (
      defaultVal2.default1 === "" &&
      (String(item).includes("Title") || String(item).includes("title"))
    ) {
      setDefaultVal2((prev) => {
        return {
          ...prev,
          default1: item,
        };
      });
    }
    if (
      defaultVal2.default2 === "" &&
      (String(item).includes("Descr") || String(item).includes("descr"))
    ) {
      setDefaultVal2((prev) => {
        return {
          ...prev,
          default2: item,
        };
      });
    }
    if (
      defaultVal2.default3 === "" &&
      (String(item).includes("Sku") || String(item).includes("sku"))
    ) {
      setDefaultVal2((prev) => {
        return {
          ...prev,
          default3: item,
        };
      });
    }
  };

  const parseFile = (file, CsvNo) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const cols = results.meta.fields;

        if (CsvNo === 1) {
          cols.forEach(createSelect1);
        } else {
          cols.forEach(createSelect2);
        }
      },
    });
  };

  var uploadFiles = () => {
    if (
      defaultVal1.default1 === "" ||
      defaultVal1.default2 === "" ||
      defaultVal1.default3 === ""
    ) {
      Modal.info({
        title: "Info",
        content:
          "Please provide Title, Description and Sku columns of Target Csv.",
      });
      return;
    }

    if (
      selected_items === null &&
      (defaultVal2.default1 === "" ||
        defaultVal2.default2 === "" ||
        defaultVal2.default3 === "")
    ) {
      Modal.info({
        title: "Info",
        content:
          "Please provide Title, Description and Sku columns of Embedding Csv.",
      });
      return;
    }

    if (
      csv1Uploaded.uploaded &&
      (csv2Uploaded.uploaded || selected_items !== null)
    ) {
      var formData = new FormData();

      if (csv2Uploaded) {
        formData.append("embeddings_csv", csv2Uploaded.csv_file);
      }

      if (selected_items != null) {
        formData.append("selected_items", selected_items);
      }

      formData.append("csv", csv1Uploaded.csv_file);
      formData.append("csv1_cols", Object.values(defaultVal1));
      formData.append("csv2_cols", Object.values(defaultVal2));
      formData.append("model_id", modelId);
      setIsUploading(true);
      axios
        .post("tasks/batch-generate-product-similarity", formData)
        .then((resp) => {
          navigate(`/product-similarity-run-info/${resp.data.csv.id}`, {
            state: { csv: resp.data.csv },
          });
        })
        .catch((error) => {
          if (error.message === "") {
            uploadFiles();
            return;
          } else {
            console.log(error);
            setIsUploading(false);
            setSelectedItems(null);
            Modal.error({
              title: "Error",
              content:
                error.response?.data?.message ||
                "Something went wrong. Please try again.",
            });
          }
        });
    } else {
      Modal.info({
        title: "Info",
        content: "Please upload both of the files!",
      });
    }
  };

  // -------------------- Different root and input props for both taxonomy and CSV file-------------------------------------
  const {
    getRootProps: getRootCsv1Props,
    getInputProps: getInputCsv1Props,
    open: openCsv1,
    isDragActive: isCsv1DragActive,
  } = useDropzone({
    noClick: true,
    accept: {
      "text/csv": [".csv"],
    },
    onDrop: (acceptedFile) => {
      // do something with the CSV file
      if (acceptedFile[0].path.split(".").pop() === "csv") {
        setCsv1Uploaded({
          ...csv1Uploaded,
          uploaded: true,
          file_name: acceptedFile[0].path,
          csv_file: acceptedFile[0],
        });
        parseFile(acceptedFile[0], 1);
      } else {
        alert("please upload a csv file");
      }
    },
  });

  const {
    getRootProps: getRootCsv2Props,
    getInputProps: getInputCsv2Props,
    open: openCsv2,
    isDragActive: isCsv2DragActive,
  } = useDropzone({
    noClick: true,
    accept: {
      "text/csv": [".csv"],
    },
    onDrop: (acceptedFile) => {
      // do something with the CSV file
      if (acceptedFile[0].path.split(".").pop() === "csv") {
        setCsv2Uploaded({
          ...csv2Uploaded,
          uploaded: true,
          file_name: acceptedFile[0].path,
          csv_file: acceptedFile[0],
        });
        parseFile(acceptedFile[0], 2);
      } else {
        alert("please upload a csv file");
      }
    },
  });

  const handleCsv1Reset = () => {
    setCsv1Uploaded({
      ...csv1Uploaded,
      uploaded: false,
      file_name: "",
    });
    setColumns1([]);
    setDefaultVal1({
      default1: "",
      default2: "",
      default3: "",
    });
  };
  const handleCsv2Reset = () => {
    setCsv2Uploaded({
      ...csv2Uploaded,
      uploaded: false,
      file_name: "",
    });
    setColumns2([]);
    setDefaultVal2({
      default1: "",
      default2: "",
      default3: "",
    });
  };

  const onSelect = (csv2) => {
    setSelectedItems(csv2);
    setIsUploadDisabled(true);
    if (csv2 === undefined) {
      setIsUploadDisabled(false);
      setSelectedItems(null);
    }
  };

  return (
    <div>
      <Card
        style={{
          minHeight: "85vh",
          borderRadius: "12px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card.Body>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Card.Title
                style={{
                  fontWeight: "700",
                  fontSize: "24px",
                  color: "#344767",
                }}
              >
                Batch Run
              </Card.Title>
              {/* <img src={i_logo} style={{ width: "2rem" }} /> */}
              <Tooltip
                placement="top"
                title="Generate and compare similarities across multiple lists of products."
              >
                <InfoCircleOutlined
                  style={{ fontSize: "1.5rem", color: "#7B809A" }}
                />
              </Tooltip>
            </div>
            <Card.Subtitle
              className="mb-2 text-muted"
              style={{ color: "#7B809A", fontWeight: "400" }}
            >
              Generate and compare similarities across multiple lists of
              products.
            </Card.Subtitle>
          </div>
          {isUploading ? (
            <>
              <Row
                justify="center"
                style={{
                  paddingTop: "25%",
                }}
              >
                <Spin />
              </Row>
              <Row justify="center">
                We are processing your files, please wait
              </Row>
            </>
          ) : (
            <>
              <h6 style={{ color: "#344767", marginTop: "2rem" }}>
                Target CSV
              </h6>
              <p style={{ color: "#6c757d" }}>
                {" "}
                Products in the CSV will be returned with their similar
                counterparts in the Embeddings CSV.
                <button
                  className="download-button"
                  onClick={() => {
                    download(
                      "https://raw.githubusercontent.com/Kaushal-Dhungel/Pumice-CSVs/main/prod-similarity-target.csv",
                      "prod-similarity-target.csv"
                    );
                  }}
                >
                  Download Sample.
                </button>{" "}
              </p>
              <div
                {...getRootCsv1Props()}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#F8F9FA",
                  border: "1px solid #C7CCD0",
                  borderRadius: "12px",
                  height: "26%",
                }}
              >
                {!csv1Uploaded.uploaded && (
                  <div
                    style={{
                      margin: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input {...getInputCsv1Props()} />
                    <img placeholder="csv_logo" src={csv_logo} />
                    <div
                      style={{
                        marginBottom: "0.5rem",
                        padding: "1rem",
                        fontWeight: "400",
                        color: "#7B809A",
                        textAlign: "center",
                      }}
                    >
                      Drag & drop a CSV for comparision.
                      <br /> CSV must contain 'title', 'description' and 'sku'
                      columns.
                    </div>
                    {!isCsv1DragActive && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="outline-danger"
                          onClick={openCsv1}
                          style={{
                            fontWeight: "700",
                            margin: "0",
                            height: "2.3rem",
                            borderRadius: "8px",
                            border: "1px solid #E91F63",
                            fontSize: "0.8rem",
                          }}
                        >
                          UPLOAD
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {csv1Uploaded.uploaded && (
                  <div>
                    <div
                      style={{
                        margin: "3vh 1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <FileDoneOutlined
                        style={{ marginRight: "0.5rem", fontSize: "2rem" }}
                      />{" "}
                      {csv1Uploaded.file_name}{" "}
                      <CloseOutlined
                        onClick={handleCsv1Reset}
                        style={{
                          marginLeft: "0.5rem",
                          fontWeight: "500",
                          color: "red",
                          fontSize: "0.5rem",
                        }}
                      />
                    </div>

                    <div
                      className="csv_mapper"
                      style={{ display: "flex", gap: "2rem" }}
                    >
                      <span>
                        <p className="text_border"> Product Title </p>
                        <p className="text_border"> Product Description </p>
                        <p className="text_border"> Sku </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.8rem",
                        }}
                      >
                        <Select
                          value={
                            defaultVal1.default1 === ""
                              ? undefined
                              : defaultVal1.default1
                          }
                          placeholder="Select a Column"
                          style={{ width: 120 }}
                          options={columns1}
                          onChange={(value) => handleChange("def1", value, 1)}
                        />
                        <Select
                          value={
                            defaultVal1.default2 === ""
                              ? undefined
                              : defaultVal1.default2
                          }
                          placeholder="Select a Column"
                          style={{ width: 120 }}
                          options={columns1}
                          onChange={(value) => handleChange("def2", value, 1)}
                        />
                        <Select
                          value={
                            defaultVal1.default3 === ""
                              ? undefined
                              : defaultVal1.default3
                          }
                          placeholder="Select a Column"
                          style={{ width: 120 }}
                          options={columns1}
                          onChange={(value) => handleChange("def3", value, 1)}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <h6 style={{ color: "#344767", marginTop: "2rem" }}>
                Embeddings CSV
              </h6>
              <p style={{ color: "#6c757d" }}>
                {" "}
                Products from the Target CSV will be compared against this.
                <button
                  className="download-button"
                  onClick={() => {
                    download(
                      "https://raw.githubusercontent.com/Kaushal-Dhungel/Pumice-CSVs/main/prod-similarity-embedding.csv",
                      "prod-similarity-embedding.csv"
                    );
                  }}
                >
                  Download Sample.
                </button>
              </p>
              <div
                {...getRootCsv2Props()}
                style={{
                  marginTop: "1%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#F8F9FA",
                  border: "1px solid #C7CCD0",
                  borderRadius: "12px",
                  height: "26%",
                }}
              >
                {!csv2Uploaded.uploaded && (
                  <div
                    style={{
                      margin: "1rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input {...getInputCsv2Props()} />
                    <img placeholder="csv_logo" src={csv_logo} />
                    <div
                      style={{
                        color: "#7B809A",
                        padding: "1rem",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      Select an existing Embedding OR upload your own.
                      <br /> CSV must contain 'title', 'description' and 'sku'
                      columns.
                    </div>

                    {!isCsv2DragActive && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <Select
                          placeholder="Embedding CSVs"
                          style={{ width: 160 }}
                          options={menuItems}
                          // size='large'
                          allowClear
                          onChange={onSelect}
                        />
                        <div
                          style={{
                            fontSize: "2rem",
                            margin: "0 1rem",
                            color: "gray",
                            height: "3rem",
                          }}
                        >
                          |
                        </div>

                        <Button
                          variant="outline-danger"
                          type="submit"
                          onClick={openCsv2}
                          disabled={isUploadDisabled}
                          style={{
                            border: "1px solid #E91F63",
                            fontWeight: "700",
                            marginLeft: "1rem",
                            fontSize: "1rem",
                          }}
                        >
                          Upload
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {csv2Uploaded.uploaded && (
                  <div>
                    <div
                      style={{
                        margin: " 3vh 1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <FileDoneOutlined
                        style={{ fontSize: "2rem", marginRight: "0.5rem" }}
                      />{" "}
                      {csv2Uploaded.file_name}{" "}
                      <CloseOutlined
                        onClick={handleCsv2Reset}
                        style={{
                          marginLeft: "0.5rem",
                          fontWeight: "500",
                          color: "red",
                          fontSize: "0.5rem",
                        }}
                      />
                    </div>

                    <div
                      className="csv_mapper"
                      style={{ display: "flex", gap: "2rem" }}
                    >
                      <span>
                        <p className="text_border"> Product Title </p>
                        <p className="text_border"> Product Description </p>
                        <p className="text_border"> Sku </p>
                      </span>

                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.8rem",
                        }}
                      >
                        <Select
                          value={
                            defaultVal2.default1 === ""
                              ? undefined
                              : defaultVal2.default1
                          }
                          placeholder="Select a Column"
                          style={{ width: 120 }}
                          options={columns2}
                          onChange={(value) => handleChange("def1", value, 2)}
                        />
                        <Select
                          value={
                            defaultVal2.default2 === ""
                              ? undefined
                              : defaultVal2.default2
                          }
                          placeholder="Select a Column"
                          style={{ width: 120 }}
                          options={columns2}
                          onChange={(value) => handleChange("def2", value, 2)}
                        />
                        <Select
                          value={
                            defaultVal2.default3 === ""
                              ? undefined
                              : defaultVal2.default3
                          }
                          placeholder="Select a Column"
                          style={{ width: 120 }}
                          options={columns2}
                          onChange={(value) => handleChange("def3", value, 2)}
                        />
                      </span>
                    </div>
                  </div>
                )}
                <div
                  className="model-id"
                  style={{
                    margin: "-8px 140px 10px 0",
                  }}
                >
                  <Select
                    placeholder="Model"
                    style={{ width: 160 }}
                    options={modelDropodown}
                    // size='large'
                    allowClear={true}
                    onChange={(value) => {
                      setModelId(value);
                    }}
                  />
                </div>
              </div>
              <Button
                variant="danger"
                style={{
                  fontWeight: "700",
                  margin: "0",
                  height: "2.3rem",
                  marginTop: "2%",
                  borderRadius: "8px",
                  border: "1px solid #E91F63",
                  fontSize: "1rem",
                }}
                onClick={uploadFiles}
              >
                Upload & Generate
              </Button>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UploadCSVs;

import "./App.css";
import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Link,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  DownOutlined,
  SmileOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Space, Modal } from "antd";
import { Layout, Breadcrumb, Row, Col, Avatar } from "antd";
import { setUser as setUser_ } from "./actions/userAction";
import { Button } from "react-bootstrap";

import DynamicCategorization from "./pages/DynamicCategorization";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/LogIn";
import NavBar from "./components/Navbar";
import widthlogo from "./assets/width-logo.png";
import ProductSimilarity from "./pages/ProductSimilarity";
import SignUP from "./pages/SignUp";
import StaticCategorization from "./pages/StaticCategorization";
import ProductSimilarityV2 from "./pages/ProductSimilarityV2";
import ProductSimilarityRunInfo from "./pages/ProductSimilarityRunInfo";
import EcommerceScraper from "./pages/EcommerceScraper";
import UniversalScraper from "./pages/UniversalScraper";
import ScrapeInfo from "./pages/ScrapeInfo";
import UniversalScrapeInfo from "./pages/UniversalScrapeInfo";
import ImageSimilarity from "./pages/ImageSimilarity";
import RunInfo from "./pages/RunInfo";
import axios from "./auth/Axios";
import ImageSimilarityRunInfo from "./pages/ImageSimilarityRunInfo";

function App() {
  const [user, setUser] = useState({});
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  const { Header, Footer, Sider, Content } = Layout;
  const [breadcrumb, setBreadcrumb] = useState(["Overview"]);
  const dispatch = useDispatch();
  const breadcrumb_ = useSelector((state) => state.breadcrumb);
  useEffect(() => {
    setBreadcrumb(breadcrumb_);
  }, [breadcrumb_]);

  var loggedIn = localStorage.getItem("accessToken") ? true : false;

  useEffect(() => {
    if (loggedIn && window.location.pathname === "/login") {
      navigate("/");
    }
    axios.get("/user/get-user").then((res) => {
      setUser(res.data);
      dispatch(setUser_(res.data));
    });
  }, []);

  const createCheckout = (plan = "STARTER") => {
    axios
      .get("/payment/get-checkout-session", { params: { plan: plan } })
      .then((res) => {
        // console.log("success is",res.data )
        window.location.href = res.data.session_url;
      });
  };

  // if (user.expiration_date_passed){
  //   return (
  //     <div>
  //       subscribtion_date_passed
  //     </div>
  //   )
  // }

  if (loggedIn) {
    return (
      <div>
        <Modal
          title="Please subscribe to one of the plans - subscription will renew every 30 days"
          visible={user.expiration_date_passed}
          footer={null}
          centered={true}
          closable={false}
          width="78vw"
          maskStyle={{
            backgroundColor: "rgba(15,15,15,0.85)",
            backdropFilter: "blur(2px)",
          }}
        >
          <div style={{ paddintTop: "2%" }}>
            <Row wrap={false}>
              <Col
                span={5}
                style={{ textAlign: "center", borderRight: "2px solid gray" }}
              >
                <h4>Features</h4>
                <p
                  style={{
                    fontWeight: "400",
                    color: "#344767",
                    lineHeight: "250%",
                  }}
                >
                  Setup Fee
                  <br />
                  Monthly Fixed Cost
                  <br />
                  Fine-Tuned Model <br />
                  Multiple Fine-Tuned Models
                  <br />
                  Industry High Accuracy
                  <br />
                  Store Taxonomy Trees
                  <br />
                  Use Trees or Category Lists
                  <br />
                  Access To All 3 Models
                  <br />
                  Access To Google Taxonomy
                  <br />
                  Extra customization
                  <br />
                  Default Credits Per Month
                  <br />
                  Additional Credits
                  <br />
                </p>
              </Col>
              <Col
                span={5}
                style={{ textAlign: "center", borderRight: "2px solid gray" }}
              >
                <h4>Free</h4>
                <p
                  style={{
                    fontWeight: "600",
                    color: "#344767",
                    lineHeight: "250%",
                  }}
                >
                  $0
                  <br />
                  Starting at $0
                  <br />
                  ❌ <br />
                  ❌<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ❌<br />
                  50
                  <br />
                  $0 per
                  <br />
                </p>
                <Button
                  variant="outline-danger"
                  type="submit"
                  style={{
                    fontSize: "90%",
                    fontWeight: "700",
                    width: "120px",
                    height: "40px",
                  }}
                  onClick={() => {
                    createCheckout("FREE");
                  }}
                >
                  Pay Now
                </Button>
              </Col>
              <Col
                span={5}
                style={{ textAlign: "center", borderRight: "2px solid gray" }}
              >
                <h4>Starter</h4>
                <p
                  style={{
                    fontWeight: "600",
                    color: "#344767",
                    lineHeight: "250%",
                  }}
                >
                  $0
                  <br />
                  Starting at $197
                  <br />
                  ❌ <br />
                  ❌<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ❌<br />
                  1350
                  <br />
                  $.12 per
                  <br />
                </p>
                <Button
                  variant="outline-danger"
                  type="submit"
                  style={{
                    fontSize: "90%",
                    fontWeight: "700",
                    width: "120px",
                    height: "40px",
                  }}
                  onClick={() => {
                    createCheckout();
                  }}
                >
                  Pay Now
                </Button>
              </Col>
              <Col
                span={5}
                style={{ textAlign: "center", borderRight: "2px solid gray" }}
              >
                <h4>Fine-Tuned</h4>
                <p
                  style={{
                    fontWeight: "600",
                    color: "#344767",
                    lineHeight: "250%",
                  }}
                >
                  $3000
                  <br />
                  Starting at $437
                  <br />
                  ✔<br />
                  ❌<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ❌<br />
                  3250
                  <br />
                  $.12 per
                  <br />
                </p>
                <Button
                  variant="outline-danger"
                  type="submit"
                  style={{
                    fontSize: "80%",
                    fontWeight: "700",
                    width: "120px",
                    height: "40px",
                  }}
                  onClick={() => {
                    window.open("https://www.pumice.ai/contact-us", "_blank");
                  }}
                >
                  Schedule a Demo
                </Button>
              </Col>
              <Col span={5} style={{ textAlign: "center" }}>
                <h4>Enterprise</h4>
                <p
                  style={{
                    fontWeight: "600",
                    color: "#344767",
                    lineHeight: "250%",
                  }}
                >
                  $8000
                  <br />
                  Starting at $497
                  <br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  ✔<br />
                  Custom
                  <br />
                  Custom
                  <br />
                </p>
                <Button
                  variant="outline-danger"
                  type="submit"
                  style={{
                    fontSize: "80%",
                    fontWeight: "700",
                    width: "120px",
                    height: "40px",
                  }}
                  onClick={() => {
                    window.open("https://www.pumice.ai/contact-us", "_blank");
                  }}
                >
                  Schedule a Demo
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <Layout
          hasSider
          style={{ backgroundColor: "#F0F2F5", padding: "1.5%" }}
        >
          <Sider
            width="17vw"
            trigger={null}
            collapsible={true}
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            breakpoint="md"
            // collapsedWidth="0"
            style={{
              marginRight: "1%",
              overflow: "hidden",
              // height: '90vh',
              position: "fixed",
              backgroundColor: "#F0F2F5",
              // left: 0,
              // top: 0,
              // bottom: 0,
              // display: 'flex',
              // zIndex: 1000,
            }}
          >
            <NavBar />
          </Sider>
          <Layout style={{ backgroundColor: "#F0F2F5", marginLeft: "18vw" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Breadcrumb
                style={{
                  marginBottom: "1%",
                }}
              >
                {breadcrumb.map((item, index) => {
                  return (
                    <Breadcrumb.Item key={index}>
                      {/* <h5>{item}</h5> */}
                      {item}
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Avatar
                  style={{
                    xs: 24,
                    sm: 32,
                    md: 40,
                    lg: 64,
                    xl: 80,
                    xxl: 100,
                    color: "#FFFFFF",
                    backgroundColor: "#E91F63",
                    marginTop: "-0.3rem",
                    fontWeight: "700",
                    marginRight: "0.5rem",
                  }}
                >
                  {user.name == null ? "" : user.name[0]}
                </Avatar>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          // redirect user to /api/payment/get-session
                          axios
                            .get("/payment/get-customer-portal")
                            .then((res) => {
                              window.location.href = res.data.portal_url;
                            });
                        }}
                      >
                        <ShoppingCartOutlined /> Manage Payments
                      </Menu.Item>
                      <Menu.Item onClick={handleLogout} danger={true}>
                        <LogoutOutlined /> Logout
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <span
                        style={{
                          fontWeight: "500",
                          color: "#4F4F52",
                          fontSize: "0.8rem",
                        }}
                      >
                        {user.name}
                      </span>
                      <DownOutlined
                        style={{ color: "#4F4F52", fontSize: "0.8rem" }}
                      />
                    </Space>
                  </a>
                </Dropdown>
                {/* <DownOutlined style={{ fontSize: "0.8rem", marginLeft: "0.3rem", marginTop: "0.2rem" }} /> */}
              </div>
            </div>
            <Content style={{ paddingTop: "0.5%" }}>
              <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route
                  exact
                  path="/dynamiccategorization"
                  element={<DynamicCategorization />}
                />
                <Route
                  exact
                  path="/staticcategorization"
                  element={<StaticCategorization />}
                />
                <Route
                  exact
                  path="/productsimilarity"
                  element={<ProductSimilarityV2 />}
                />
                <Route exact path="/run-info/:csvId" element={<RunInfo />} />
                <Route
                  exact
                  path="/product-similarity-run-info/:csvId"
                  element={<ProductSimilarityRunInfo />}
                />
                <Route
                  exact
                  path="/image-similarity-run-info/:csvId"
                  element={<ImageSimilarityRunInfo />}
                />
                <Route
                  exact
                  path="/ecommerce-scraper"
                  element={<EcommerceScraper />}
                />
                <Route
                  exact
                  path="/scrape-info/:domainUrl"
                  element={<ScrapeInfo />}
                />
                <Route
                  exact
                  path="/universal-scraper"
                  element={<UniversalScraper />}
                />
                <Route
                  exact
                  path="/universal-scrape-info/:model_id"
                  element={<UniversalScrapeInfo />}
                />
                <Route
                  exact
                  path="/image-similarity"
                  element={<ImageSimilarity />}
                />
              </Routes>
            </Content>
            <Footer style={{ backgroundColor: "#F0F2F5" }}>
              <div className="text-muted">
                {/* <Link to="/">
              Noice footer
              </Link> */}
                <Row>
                  <Col span={16}>
                    <Row>
                      <img
                        src={widthlogo}
                        alt="width logo"
                        style={{ width: "10%" }}
                      />
                    </Row>
                    <Row>© 2022 Width LLC All Rights Reserved</Row>
                  </Col>
                  <Col span={8}>
                    <Row
                      justify="end"
                      gutter={24}
                      align="middle"
                      style={{ marginTop: "6.5%" }}
                    >
                      <Col>
                        <a
                          href="https://www.width.ai/"
                          style={{ color: "#7B809A", fontWeight: "400" }}
                        >
                          Width LLC
                        </a>
                      </Col>
                      <Col>About</Col>
                      <Col>
                        <a
                          href="https://www.width.ai/blog"
                          style={{ color: "#7B809A", fontWeight: "400" }}
                        >
                          Blog
                        </a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  } else {
    if (window.location.pathname === "/signup") {
      return (
        <div>
          <Routes>
            <Route exact path="/signup" element={<SignUP />} />
          </Routes>
        </div>
      );
    } else {
      if (window.location.pathname !== "/login") {
        window.location.replace("/login");
      }
      return (
        <div>
          <Routes>
            <Route exact path="/login" element={<Login />} />
          </Routes>
        </div>
      );
    }
  }
}

export default App;

import React, { useEffect } from "react";

import { Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { updateBreadcrumb } from "../actions/actions";

import StaticUploadArea from "../components/StaticUploadArea";
import SingleRun from "../components/SingleRun";
import PreviousRuns from "../components/PreviousRuns";

const StaticCategorization = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateBreadcrumb(["Product Categorization", "Static Categorization"])
    );
  }, []);
  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <StaticUploadArea image_similarity={false} />
        </Col>
        <Col span={12}>
          <SingleRun />
        </Col>
      </Row>
      <Row style={{ marginTop: "2%" }}>
        <Col span={24}>
          <PreviousRuns />
        </Col>
      </Row>
    </div>
  );
};

export default StaticCategorization;

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from "../auth/Axios";

function Dropzone() {
    var formData = new FormData(); // API request
    const [csvFileUploaded, setCsvFileUploaded] = useState(false); // for CSV file
    const [treeFileUploaded, setTreeFileUploaded] = useState(false);
    const { getRootProps: getRootCsvFileProps, getInputProps: getInputCsvFileProps, isDragActive: isCsvDragActive } = useDropzone({
        onDrop: (acceptedFile) => {
            // do something with the CSV file
            if (acceptedFile[0].path.split('.').pop() !== 'csv') {
                alert('please upload a csv file.');
            } else {
                alert('CSV file uploaded successfully.' + acceptedFile[0].path);
                console.log(acceptedFile[0].path.split('.').pop());
                // upload file
                formData.append('file', acceptedFile[0]);
                setCsvFileUploaded({
                    csvFileUploaded: true
                });
                if (treeFileUploaded === true) {
                    handleSubmit();
                }
            }
        }
    });
    const { getRootProps: getRootTreeFileProps, getInputProps: getInputTreeFileProps, isDragActive: isTreeDragActive } = useDropzone({
        onDrop: (acceptedFile) => {
            // do something with the tree file
            if (acceptedFile[0].path.split('.').pop() !== 'txt') {
                alert('please upload a tree file.');
            } else {
                console.log(acceptedFile[0].path.split('.').pop());
                alert('Tree file uploaded successfully.' + acceptedFile[0].path);
                // upload file
                formData.append('tree_file', acceptedFile[0]);
                formData.append('tree_id', 1);
                setTreeFileUploaded({
                    treeFileUploaded: true
                });
            }
            if (csvFileUploaded === true) {
                handleSubmit();
            }
        }
    });

    const handleSubmit = () => {
        axios.post('/tasks/batch-generate-product-categories-dynamic', formData)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            <div {...getRootTreeFileProps()} style={{
                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",
                height: "50%", backgroundColor: "#F8F9FA", border: "1px solid #C7CCD0", borderRadius: "12px",
            }}>
                <input {...getInputTreeFileProps()} />
                {
                    isTreeDragActive
                        ? <p>Drop the files here ...</p>
                        : <p>Drag 'n' drop a tree file here, or click to select file</p>
                }
            </div>
            <div {...getRootCsvFileProps()} style={{
                marginTop: "2%",
                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",
                height: "50%", backgroundColor: "#F8F9FA", border: "1px solid #C7CCD0", borderRadius: "12px",
            }}>
                <input {...getInputCsvFileProps()} />
                {
                    isCsvDragActive
                        ? <p>Drop the files here ...</p>
                        : <p>Drag 'n' drop a CSV file here, or click to select file</p>
                }
            </div>
        </div>
    )
}

export default Dropzone;


    // const onDrop = useCallback(acceptedFiles => {
    // }, [])
    // const onDrop = (acceptedFiles) => {
    //     console.log(acceptedFiles[0].path.split('.').pop()); // tells whether uploaded file is csv or not
    //     if (acceptedFiles.length > 1) {
    //         alert('You can only upload one file at a time.');
    //     } else if (acceptedFiles[0].path.split('.').pop() === 'txt') {
    //         alert('Tree file uploaded successfully.' + acceptedFiles[0].path);
    //         formData.append('tree_file', acceptedFiles[0]);
    //         if (!oneFileReceived) {
    //             setOneFileReceived({
    //                 oneFileReceived: true
    //             })
    //         } else {
    //             axios.post('/tasks/batch-generate-product-categories-dynamic', formData)
    //                 .then(function (response) {
    //                     console.log(response);
    //                 })
    //                 .catch(function (error) {
    //                     console.log(error);
    //                 });
    //         }
    //     }
    //     else if (acceptedFiles[0].path.split('.').pop() === 'csv') {

    //         alert('CSV file uploaded successfully.' + acceptedFiles[0].path);
    //         formData.append('csv_file', acceptedFiles[0]);
    //         formData.append('tree_id', 1); // passing a hardcoded id

    //         if (!oneFileReceived) {
    //             setOneFileReceived({
    //                 oneFileReceived: true
    //             })
    //         } else {
    //             axios.post('/tasks/batch-generate-product-categories-dynamic', formData)
    //                 .then(function (response) {
    //                     console.log(response);
    //                 })
    //                 .catch(function (error) {
    //                     console.log(error);
    //                 });
    //         }
    //     }
    //     else {
    //         alert('You can only upload a csv file.');
    //     }
    // }
    // const { getRootProps, getInputProps } = useDropzone({ onDrop })
import React from "react";
import { Row, Col } from "antd";

import APICode from "../components/APICode";
import PreviousRuns from "../components/PreviousRuns";
import UsageQuota from "../components/UsageQuota";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBreadcrumb } from "../actions/actions";
import APIKey from "../components/APIKey";

const Dashboard = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateBreadcrumb(['Dashboard']));
    }, []);

    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Row gutter={[, 16]}>
                        <Col span={24}>
                            <UsageQuota />
                        </Col>
                        <Col span={24}>
                            <APIKey />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <APICode />
                </Col>
            </Row>
            <Row style={{ marginTop: '2%' }}>
                <Col span={24}>
                    <PreviousRuns />
                </Col>
            </Row>
        </div>
    );
}

export default Dashboard;
import React, { useEffect, useState } from "react";

import { Card, Tabs, Tab, Button } from "react-bootstrap";
import Prism from "prismjs";

import { useSelector } from "react-redux";

import { testAction } from "../actions/userAction";
// import '../assets/prism.css';



const APICode = () => {
  const [key, setKey] = useState('python');
  const [copied, setCopied] = useState(false);

  const apiKey = useSelector(state => state.user.api_key);


  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const text = {
    
    // using python 
    "python" : `
    import requests
    import json
    url = "https://app.pumice.ai/api/batch-categorize-products"
    payload = json.dumps({ "csv_url": "https://raw.githubusercontent.com/Sirri69/Test/master/truncated_products2.csv", "run_type": "static"})
    headers = { 'KEY': '${apiKey}','Content-Type': 'application/json' }
    response = requests.request("POST", url, headers=headers, data=payload)
    print(response.text)`,


    //  using js 
    "js" : `
    var myHeaders = new Headers();
    myHeaders.append("KEY", "${apiKey}");
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({ "csv_url": "https://raw.githubusercontent.com/Sirri69/Test/master/truncated_products2.csv", "run_type": "static" });
    var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow'};
    fetch("https://app.pumice.ai/api/batch-categorize-products", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`,
    
    
      // using curl
      "curl": `curl --location --request POST 'https://app.pumice.ai/api/batch-categorize-products' \
      --header 'KEY: ${apiKey}' \
      --header 'Content-Type: application/json' \
      --data-raw '{
          "csv_url": "https://raw.githubusercontent.com/Sirri69/Test/master/truncated_products2.csv",
          "run_type": "static"
      }'`
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(text[key]).then(function () {
      console.log('copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });

    setCopied({
      copied: false
    });

  }

  return (
    <div>
      <Card className="api-code-card" style={{ height: "100%"}}>
        <Card.Body>
          <Card.Title style={{ fontWeight: "700", color: "#344767" }}>
            Try Now
          </Card.Title>
          <Card.Subtitle style={{ fontWeight: "400", color: "#7B809A" }} className="mb-2 text-muted">Copy and run the code below to test run the API.</Card.Subtitle>

          <Tabs
            id="api-examples"
            activeKey={key}
            onSelect={k => { setKey(k); }}
            defaultActiveKey="python"
            className="mb-2"
            style={{
              fontWeight: "400", margin: "0", borderBottom: 'none', backgroundColor: '#e5e5e5',
              fontSize: '0.8rem'
            }}
          >
            {/* <div className='api'> */}
            <Tab eventKey="python" title="Python">
              <pre className='code' style={{ backgroundColor: '#f8f9fa', borderRadius: '12px', color: "#344767", fontWeight: "400", fontSize: '11.7px' }}>
                <code className="language-js">
                  {text['python']}
                </code>
              </pre>
            </Tab>
            <Tab eventKey="curl" title="cURL">
              <pre style={{ backgroundColor: '#f8f9fa', borderRadius: '12px', fontSize: '11.7px' }}>
                <code className="language-javascript">
                {text['curl']}
                </code>
              </pre>
            </Tab>
            <Tab eventKey="js" title="JavaScript">
              <pre style={{ backgroundColor: '#f8f9fa', marginTop: '1%', borderRadius: '12px', fontSize: '11px', overflowY: 'scroll' }}>
                <code className="language-javascript">
                {text['js']}
                </code>
              </pre>
            </Tab>
            {/* <span/> */}
          </Tabs>
          <Button variant="outline-danger" style={{
            border: "1px solid #E91F63",
            fontWeight: "700", fontSize: "0.8rem", marginTop: '3%'
          }} onClick={handleCopy}>
            {!copied
              ? 'Copy Code'
              : 'Copy Code'
            }
          </Button>
        </Card.Body>
      </Card>

    </div >

  );
}

export default APICode;
